import MiniQuestionActions                       from "./MiniQuestionActions"
import {Box, Fab, useMediaQuery, useTheme}       from "@mui/material"
import SendMessage                                             from "../prepkit/chat/SendMessage"
import {ArrowBackOutlined, ArrowForwardOutlined, HomeOutlined} from "@mui/icons-material"
import React, {useState}                                       from "react"
import {keyframes}                               from "@emotion/react"


const bw = 0

const MiniQuestionInput = ({currentAnswer, messageInputDisabled, actions, onSend, onHome, onBack, onNext}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const [showNavigationButtons, setShowNavigationButtons] = useState(true)
	const [showActions, setShowActions] = useState(false)


	const handleSend = (msg) => {
		setShowActions(false)
		onSend(msg)
	}

	const fabSize = showNavigationButtons
		? isSmallScreen
			? "40px"
			: "normal"
		: 0

	const fabSx = {
		border: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.default,
		//'&:hover': {
		//	border: `1.5px solid ${theme.palette.secondary.main}`
		//},
		//'&:hover .MuiSvgIcon-root': {
		//	color: theme.palette.secondary.main,
		//},
		'.MuiSvgIcon-root': {
			color: theme.palette.text.primary
		},
		boxShadow: 'none !important',
		width: fabSize,
		height: fabSize,
		opacity: showNavigationButtons ? 1 : 0,
		overflow: "hidden",
		mx: showNavigationButtons ? 0.5 : 0,
		transition: "width 250ms, opacity 250ms, margin 250ms",
	}

	const pulseAnimation = keyframes`
	    0% {
	      background-color: ${theme.palette.secondary.dark};
	    }
	    50% {
	      background-color: ${theme.palette.secondary.light};
	    }
	    100% {
	      background-color: ${theme.palette.secondary.dark};
	    }
	  `
	const fabSxPulse = {
		animation: `${pulseAnimation} 1s infinite`, // 2s duration, infinite loop
		'.MuiSvgIcon-root': {
			color: 'white'
		},
	}

	return <Box sx={{
		position: "fixed",
		bottom: 0,
		left: 0,
		right: 0,
		maxWidth: '100vw',
		border: `${bw}px solid cyan`,
		zIndex: 1200,
		backgroundColor: isSmallScreen ? theme.palette.background.paper : "transparent"
	}}>
		<MiniQuestionActions
			actions={actions}
			onAction={(a) => handleSend(a.prompt || a.label)}
			show={showActions}
		/>
		<Box
			sx={{
				py: isSmallScreen ? 1 : 2,
				width: isSmallScreen ? "auto" : "800px",
				marginX: "auto",
				border: `${bw}px solid dodgerblue`
			}}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Fab sx={fabSx}
			     onClick={onHome}
			>
				<HomeOutlined />
			</Fab>
			<Box display="flex"
			     flex={showNavigationButtons ? "auto" : 1}
			>
				<SendMessage
					onSend={handleSend}
					messageInputDisabled={messageInputDisabled}
					placeholder="Have a question? Enter it here."
					actionsShown={showActions}
					onClickShowActions={()=>setShowActions(!showActions)}
					onFocusChange={(hasFocus) => {
						if(isSmallScreen) {
							setShowNavigationButtons(!hasFocus)
						}
						else {
							setShowNavigationButtons(true)
						}
					}}
				/>
			</Box>
			<Fab sx={[fabSx]}
			     onClick={onBack}
			>
				<ArrowBackOutlined/>
			</Fab>
			<Fab sx={[fabSx, currentAnswer ? fabSxPulse : null]}
			     onClick={onNext}
			>
				<ArrowForwardOutlined sx={{color: currentAnswer ? 'white' : 'inherit'}}/>
			</Fab>
		</Box>
	</Box>
}

export default MiniQuestionInput