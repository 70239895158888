import React, {useEffect, useState}              from 'react'
import {Box, CircularProgress, Fade, Typography} from '@mui/material'

const LoadingWithMessages = ({messages, duration}) => {
	const [currentMessageIndex, setCurrentMessageIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentMessageIndex((prevIndex) => Math.min(++prevIndex, messages.length-1))
		}, duration)

		return () => clearInterval(interval)
	}, [messages, duration])

	return (
		<Box justifyContent="center" py={3} sx={{textAlign: 'center'}}>
			<CircularProgress/>
			{currentMessageIndex}
			<Box mt={2}>
				<Fade in timeout={200}>
					<Typography variant="h6" key={currentMessageIndex}>
						{messages[currentMessageIndex]}
					</Typography>
				</Fade>
			</Box>
		</Box>
	)
}

export default LoadingWithMessages
