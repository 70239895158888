import React, {useEffect, useState} from 'react'
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
	useTheme,
}                                                                                            from '@mui/material'
import {
	Add,
	DeleteOutline,
	Help,
	HelpCenter,
	HelpCenterOutlined, HelpOutline,
	MoreVert,
	Topic, TopicOutlined,
	Videocam,
	VideocamOutlined
} from "@mui/icons-material"
import { styled } from '@mui/material/styles';

function MediaTree({
	                   topics, depth, selectedTopics, selectedQuestions,
	                   hideExcluded,
	                   onClickTopic, onClickQuestion,
	                   attachedBefore, attachedAfter,
	                   onAttachBefore, onAttachAfter, onRemoveAttachmentBefore, onRemoveAttachmentAfter
                   }) {

	depth = depth || 0

	return <List sx={{px: depth === 0 ? 1 : 0}}>{
		topics && topics.map(t => {
			const selected = Boolean(selectedTopics[t.id])
			if (!hideExcluded || selected) {
				return <MediaTreeTopic key={`topic-${t.id}`}
				                       topic={t}
				                       depth={depth}
				                       selectedTopics={selectedTopics}
				                       selectedQuestions={selectedQuestions}
				                       hideExcluded={hideExcluded}
				                       onClick={onClickTopic}
				                       onClickQuestion={onClickQuestion}
				                       attachedBefore={attachedBefore}
				                       attachedAfter={attachedAfter}
				                       onAttachBefore={onAttachBefore}
				                       onAttachAfter={onAttachAfter}
				                       onRemoveAttachmentBefore={onRemoveAttachmentBefore}
				                       onRemoveAttachmentAfter={onRemoveAttachmentAfter}
				/>
			}
		})
	}</List>
}


function MediaTreeTopic({
	                        topic,
	                        depth,
	                        selectedTopics,
	                        selectedQuestions,
	                        hideExcluded,
	                        onClick,
	                        onClickQuestion,
	                        attachedBefore,
	                        attachedAfter,
	                        onAttachBefore,
	                        onAttachAfter,
	                        onRemoveAttachmentBefore,
	                        onRemoveAttachmentAfter
                        }) {

	const theme = useTheme()
	const [anchorEl, setAnchorEl] = useState(null)
	const [menuOpen, setMenuOpen] = useState(false)
	const [waiting, setWaiting] = useState(false)
	const selected = selectedTopics[topic.id]
	const selectedQuestionCount = topic.questions && topic.questions.filter(q => selectedQuestions[q.id]).length
	const comps = []
	const before = attachedBefore[topic.id]
	const after = attachedAfter[topic.id]

	const topicComp = <CustomListItem
		key={`topic-item-${topic.id}`}
		selected={Boolean(selected)}
		onClick={(event) => {
			event.stopPropagation()
			onClick(topic)
		}}
		secondaryAction={
			selected && <IconButton
				disabled={waiting}
				onClick={(event) => {
					event.stopPropagation()
					event.preventDefault()
					setAnchorEl(event.currentTarget)
					setMenuOpen(true)
				}}
				edge="end"
			>
				{
					waiting
						? <CircularProgress size={18}/>
						: <Add/>
				}
			</IconButton>
		}
	>
		<ListItemIcon
			sx={{minWidth: '30px'}}>
			<Checkbox
				edge="start"
				checked={Boolean(selected)}
				tabIndex={-1}
				disableRipple
			/>
		</ListItemIcon>
		<ListItemText sx={{pl: ((depth) * 2)}}>
			<Box display="flex" alignItems="center">
				<TopicOutlined sx={{
						mr: 1,
						color: selected ? theme.palette.warning.main : theme.palette.text.disabled
					}}
				/>
				{topic.name}
				{
					topic.questions &&
					<Chip size="small"
					      sx={{ml: 1}}
					      color={selectedQuestionCount ? 'primary' : 'default'}
					      variant={selectedQuestionCount ? 'outlined' : 'outlined'}
					      label={`${selectedQuestionCount}/${topic.questions.length}`}/>
				}
			</Box>
		</ListItemText>
		<Menu
			open={menuOpen}
			anchorEl={anchorEl}
			onClose={(event) => {
				event.stopPropagation()
				setAnchorEl(null)
				setMenuOpen(false)
			}}
		>
			<MenuItem onClick={async (event) => {
				event.stopPropagation()
				setMenuOpen(false)
				setWaiting(true)
				await onAttachBefore(topic)
				setWaiting(false)
			}}>
				Insert Clip Before
			</MenuItem>
			<MenuItem onClick={async (event) => {
				event.stopPropagation()
				setMenuOpen(false)
				setWaiting(true)
				await onAttachAfter(topic)
				setWaiting(false)
			}}>
				Insert Clip After
			</MenuItem>
		</Menu>
	</CustomListItem>

	if (before) {
		comps.push(<MediaTreeAttachment key={`topic-before-${topic.id}`}
		                                attachment={before}
		                                depth={depth}
		                                selected={selected}
		                                parent={topic}
		                                onRemove={onRemoveAttachmentBefore}
		/>)
	}

	comps.push(topicComp)

	if (after) {
		comps.push(<MediaTreeAttachment key={`topic-after-${topic.id}`}
		                                attachment={after}
		                                depth={depth}
		                                selected={selected}
		                                parent={topic}
		                                onRemove={onRemoveAttachmentAfter}
		                                after={true}
		/>)
	}

	const questionComps = topic.questions && topic.questions.map(q => {
		const selected = Boolean(selectedQuestions[q.id])
		if (!hideExcluded || selected) {
			return <MediaTreeQuestion key={`question-${q.id}`}
			                          question={q}
			                          selected={selected}
			                          onClick={onClickQuestion}
			                          depth={depth + 1}
			                          attachedBefore={attachedBefore}
			                          attachedAfter={attachedAfter}
			                          onAttachBefore={onAttachBefore}
			                          onAttachAfter={onAttachAfter}
			                          onRemoveAttachmentBefore={onRemoveAttachmentBefore}
			                          onRemoveAttachmentAfter={onRemoveAttachmentAfter}
			/>
		}
	})

	if (questionComps) {
		comps.push(...questionComps)
	}

	if (topic.topics) {
		comps.push(<MediaTree key={`topic-topics-${topic.id}`}
			                  topics={topic.topics}
		                      selectedTopics={selectedTopics}
		                      selectedQuestions={selectedQuestions}
		                      depth={depth + 1}
		                      hideExcluded={hideExcluded}
		                      onClickTopic={onClick}
		                      onClickQuestion={onClickQuestion}
		                      attachedBefore={attachedBefore}
		                      attachedAfter={attachedAfter}
		                      onAttachBefore={onAttachBefore}
		                      onAttachAfter={onAttachAfter}
		                      onRemoveAttachmentBefore={onRemoveAttachmentBefore}
		                      onRemoveAttachmentAfter={onRemoveAttachmentAfter}
		/>)
	}

	return comps
}


function MediaTreeQuestion({
	                           question,
	                           selected,
	                           depth,
	                           onClick,
	                           attachedBefore,
	                           attachedAfter,
	                           onAttachBefore,
	                           onAttachAfter,
	                           onRemoveAttachmentBefore,
	                           onRemoveAttachmentAfter
                           }) {

	const theme = useTheme()
	const [anchorEl, setAnchorEl] = useState(null)
	const [menuOpen, setMenuOpen] = useState(false)
	const [waiting, setWaiting] = useState(false)

	const comps = []

	const before = attachedBefore[question.id]
	const after = attachedAfter[question.id]
	const questionComp = <CustomListItem
		key={`question-item-${question.id}`}
		selected={Boolean(selected)}
		onClick={() => onClick(question)}
		sx={{
			mt: before ? 0 : 1,
			mb: after ? 0 : 1,
			borderRadius: '5px'
		}}
		secondaryAction={
			selected && <IconButton
				disabled={waiting}
				onClick={(event) => {
					event.stopPropagation()
					event.preventDefault()
					setAnchorEl(event.currentTarget)
					setMenuOpen(true)
				}}
				edge="end"
			>
				{
					waiting
						? <CircularProgress size={18}/>
						: <Add/>
				}
			</IconButton>
		}
	>
		<ListItemIcon>
			<Checkbox
				edge="start"
				checked={Boolean(selected)}
				tabIndex={-1}
				disableRipple
				onChange={(event) => {
					event.stopPropagation()
					onClick(question)
				}}
			/>
		</ListItemIcon>
		<ListItemText sx={{pl: ((depth) * 2)}}>
			<Box display="flex" alignItems="center">
				<HelpOutline  sx={{
						mr: 1,
						color: selected ? theme.palette.info.main : theme.palette.text.disabled
					}}
				/>
				{question.question}
			</Box>
		</ListItemText>
		<Menu
			open={menuOpen}
			anchorEl={anchorEl}
			onClose={(event) => {
				event.stopPropagation()
				setAnchorEl(null)
				setMenuOpen(false)
			}}
		>
			<MenuItem onClick={async (event) => {
				event.stopPropagation()
				setMenuOpen(false)
				setWaiting(true)
				await onAttachBefore(question)
				setWaiting(false)
			}}>
				Insert Clip Before
			</MenuItem>
			<MenuItem onClick={async (event) => {
				event.stopPropagation()
				setMenuOpen(false)
				setWaiting(true)
				await onAttachAfter(question)
				setWaiting(false)
			}}>
				Insert Clip After
			</MenuItem>
		</Menu>
	</CustomListItem>

	if (before) {
		comps.push(<MediaTreeAttachment key={`question-before-${question.id}`}
		                                attachment={before}
		                                depth={depth}
		                                selected={selected}
		                                parent={question}
		                                onRemove={onRemoveAttachmentBefore}
		/>)
	}

	comps.push(questionComp)

	if (after) {
		comps.push(<MediaTreeAttachment key={`question-after-${question.id}`}
		                                attachment={after}
		                                depth={depth}
		                                selected={selected}
		                                parent={question}
		                                onRemove={onRemoveAttachmentAfter}
		                                after={true}
		/>)
	}

	return comps

}


function MediaTreeAttachment({attachment, parent, selected, depth, after, onRemove}) {

	const theme = useTheme()
	const [label, setLabel] = useState("")

	useEffect(() => {
		const s = attachment.split("/")
			.splice(1)
			.join(" / ")
		setLabel(s)
	}, [attachment])

	if(!selected) {
		return null
	}

	return <ListItem
		sx={{
			py: 0,
		}}
		style={{
			borderTop: after ? 'none' : '4px solid transparent',
			borderBottom: after ? '4px solid transparent' : 'none'
		}}
	>
		<ListItemText sx={{
			m: 0,
			mt: after ? 0 : 1,
			mb: after ? 1 : 0,
			pl: 5 + ((depth) * 2)
		}}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				style={{
					backgroundColor: selected ? theme.palette.background.paper : 'transparent',
					color: selected ? theme.palette.text.primary : theme.palette.action.disabled,
					borderColor: selected ? theme.palette.divider : theme.palette.action.disabled,
					borderTopWidth: after ? 0 : '1px',
					borderLeftWidth: '1px',
					borderBottomWidth: after ? '1px' : 0,
					borderRightWidth: '1px',
					borderStyle: selected ? 'solid' : 'dotted',
					borderTopRightRadius: after ? '0' : '5px',
					borderTopLeftRadius: after ? '0' : '5px',
					borderBottomRightRadius: after ? '5px' : '0',
					borderBottomLeftRadius: after ? '5px' : '0',
					fontStyle: 'italic',
					padding: '5px 15px',
				}}
			>
				<Box display="flex"
				     justifyContent="flex-start"
				     alignItems="center"
				>
					<VideocamOutlined sx={{
						mr: 1,
						color: selected ? theme.palette.error.light : theme.palette.action.disabled
					}}/>
					{label}
				</Box>
				<Tooltip title="Remove clip">
					<IconButton
						size="small"
						onClick={(event) => {
							onRemove(parent)
						}}
					>
						<DeleteOutline fontSize="small"/>
					</IconButton>
				</Tooltip>
			</Box>
		</ListItemText>
	</ListItem>
}

const CustomListItem = styled(ListItem)(({ theme, selected }) => ({
	color: theme.palette.text.disabled, // Default text color
	border: '1px solid transparent',
	cursor: 'pointer',
	'&.Mui-selected': {
		color: theme.palette.text.primary, // Text color when selected
		border: '1px solid ' + theme.palette.divider,
		backgroundColor: theme.palette.background.paper,
		fontWeight: 'bold', // Bold text when selected
		'& .MuiListItemText-primary': {
			xfontWeight: 'bold', // Ensure ListItemText is bold when selected
		},
	},
}));

export default MediaTree
