import React, { useState, useCallback } from 'react'
import {
	TextField,
	Button,
	Container,
	Box,
	Typography,
	useTheme,
	InputAdornment,
	IconButton,
	Alert,
	AlertTitle,
}                                             from '@mui/material'
import {ArrowBack, Visibility, VisibilityOff} from "@mui/icons-material"
import { getSupabase }                        from "../../util/supabase-utils"

const supabase = getSupabase()

const UpdatePassword = ({switchView}) => {
	const theme = useTheme()
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleUpdatePassword = useCallback(async () => {
		if (newPassword !== confirmPassword) {
			setError('Passwords do not match.')
			return
		}

		if (newPassword.length < 6) {
			setError('Password should be at least 6 characters long.')
			return
		}

		try {
			setLoading(true)
			setError('')

			const { error } = await supabase.auth.updateUser({ password: newPassword })

			if (error) throw error

			setSuccess(true)
		} catch (error) {
			console.error('Update password error:', error.message)
			setError(error.message || "An error occurred. Please try again later.")
		} finally {
			setLoading(false)
		}
	}, [newPassword, confirmPassword])

	const toggleShowPassword = () => setShowPassword(!showPassword)

	if (success) {
		return (
			<Container>
				<Box mt={3}>
					<Alert severity="success">
						<AlertTitle>Success</AlertTitle>
						Your password has been successfully updated.
						<Button variant={"outlined"}
						        size={"small"}
						        onClick={()=>switchView('')}
						        sx={{mt: 1}}
						>
							OK
						</Button>
					</Alert>
				</Box>
			</Container>
		)
	}

	return (
		<Container>
			<Box component="form" mt={3} mb={2}>
				<Typography variant="h6"
				            sx={{cursor: 'pointer'}}
				            onClick={()=>switchView('')}
				            display={"flex"}
				            alignItems={"center"}
				>
					<ArrowBack sx={{mr: 0.5}}/> Update Password
				</Typography>

				{error && <Typography color="error" mb={2}>{error}</Typography>}
				<TextField
					label="New Password"
					type={showPassword ? 'text' : 'password'}
					fullWidth
					margin="normal"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					disabled={loading}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={toggleShowPassword} edge="end">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					label="Confirm New Password"
					type={showPassword ? 'text' : 'password'}
					fullWidth
					margin="normal"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					disabled={loading}
				/>
				<Button
					variant="outlined"
					color="primary"
					fullWidth
					size="large"
					onClick={handleUpdatePassword}
					disabled={loading}
					sx={{ mt: 2, py: 1.5 }}
				>
					{loading ? "Updating..." : "Update Password"}
				</Button>
			</Box>
		</Container>
	)
}

export default UpdatePassword