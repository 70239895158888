import {createClient} from "@supabase/supabase-js"

export const SUPABASE_PROJECT_URL = process.env.REACT_APP_SUPABASE_PROJECT_URL
export const SUPABASE_ANON_PUBLIC = process.env.REACT_APP_SUPABASE_ANON_PUBLIC

const client = createClient(SUPABASE_PROJECT_URL, SUPABASE_ANON_PUBLIC);

export const getSupabase = () => client;

export default getSupabase;
