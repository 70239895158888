import React, {useEffect, useState}                 from 'react'
import {
	Box,
	Button,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Typography,
	useTheme
}                                                   from '@mui/material'
import {Cancel, Check, DeleteOutline, EditOutlined} from '@mui/icons-material'
import getSupabase                                  from '../../util/supabase-utils'
import LeftRight                                    from "../LeftRight"

// Initialize Supabase client
const supabase = getSupabase()

const RegExpTemplates = ({onClick, onClose}) => {
	const theme = useTheme()
	const [templates, setTemplates] = useState([])
	const [editingId, setEditingId] = useState(null)
	const [editPattern, setEditPattern] = useState('')
	const [editDescription, setEditDescription] = useState('')
	const [patternError, setPatternError] = useState('')
	const [isNewRow, setIsNewRow] = useState(false)

	useEffect(() => {
		fetchTemplates()
	}, [])

	const fetchTemplates = async () => {
		const { data, error } = await supabase
			.from('regexp_template')
			.select('*')
			.order('created_at', { ascending: false })

		if (error) {
			console.error('Error fetching templates:', error.message)
		} else {
			setTemplates(data)
		}
	}

	const handleAddClick = () => {
		const newTemplate = { id: null, pattern: '', description: '' }
		setTemplates([newTemplate, ...templates])
		setEditingId(newTemplate.id)
		setEditPattern('')
		setEditDescription('')
		setIsNewRow(true)
	}

	const handleEditClick = (template) => {
		setEditingId(template.id)
		setEditPattern(template.pattern)
		setEditDescription(template.description || '')
		setPatternError('')
		setIsNewRow(false)
	}

	const handleDeleteClick = async (id) => {
		const confirmed = window.confirm("Are you sure you want to delete this template?")
		if (confirmed) {
			const { error } = await supabase
				.from('regexp_template')
				.delete()
				.eq('id', id)

			if (error) {
				console.error('Error deleting template:', error.message)
			} else {
				fetchTemplates() // Refresh the table after deletion
			}
		}
	}

	const handlePatternChange = (e) => {
		const value = e.target.value
		setEditPattern(value)

		try {
			new RegExp(value)
			setPatternError('')
		} catch (err) {
			setPatternError('Invalid regular expression syntax')
		}
	}

	const handleDescriptionChange = (e) => {
		setEditDescription(e.target.value)
	}

	const handleSaveClick = async () => {
		if (patternError) return

		if (isNewRow) {
			const { data, error } = await supabase
				.from('regexp_template')
				.insert([{ pattern: editPattern, description: editDescription }])
				.select()

			if (error) {
				console.error('Error adding template:', error.message)
			} else {
				setTemplates([data[0], ...templates.slice(1)])
				setIsNewRow(false)
			}
		} else {
			const { error } = await supabase
				.from('regexp_template')
				.update({ pattern: editPattern, description: editDescription })
				.eq('id', editingId)

			if (error) {
				console.error('Error updating template:', error.message)
			} else {
				fetchTemplates()
			}
		}

		setEditingId(null)
	}

	const handleCancelClick = () => {
		if (isNewRow) {
			setTemplates(templates.slice(1))
			setIsNewRow(false)
		}
		setEditingId(null)
	}

	return (
		<Box sx={{border: `1px solid ${theme.palette.divider}`, borderRadius: '10px', padding: '15px'}}>
			<Box>
				<LeftRight
					left={<Typography variant="h6">RegExp Templates</Typography>}
					right={
						<Box>
							<Button onClick={handleAddClick} color="primary" style={{ marginBottom: '10px' }}>
								Add
							</Button>
							<Button onClick={onClose} color="primary" style={{ marginBottom: '10px' }}>
								Close
							</Button>
						</Box>
					}
				/>
			</Box>
			<TableContainer sx={{border: `1px solid ${theme.palette.divider}`}}>
				<Table>
					<TableBody>
						{templates.map((template, index) => (
							<TableRow key={index}>
								<TableCell sx={{whiteSpace: "nowrap"}}>
									{editingId === template.id ? (
										<TextField
											fullWidth
											size="small"
											placeholder="RegExp Pattern"
											value={editPattern}
											onChange={handlePatternChange}
											error={!!patternError}
											helperText={patternError}
										/>
									) : (
										<Link sx={{cursor: 'pointer'}} onClick={()=>onClick(template)}>{template.pattern}</Link>
									)}
								</TableCell>
								<TableCell>
									{editingId === template.id ? (
										<TextField
											fullWidth
											size="small"
											placeholder="Description"
											value={editDescription}
											onChange={handleDescriptionChange}
										/>
									) : (
										<Link sx={{cursor: 'pointer'}} onClick={()=>onClick(template)}>{template.description}</Link>
									)}
								</TableCell>
								<TableCell>
									<Box textAlign="right" whiteSpace="nowrap">
										{editingId === template.id ? (
											<>
												<IconButton onClick={handleSaveClick} color="success" disabled={!!patternError}>
													<Check />
												</IconButton>
												<IconButton onClick={handleCancelClick} color="secondary">
													<Cancel />
												</IconButton>
											</>
										) : (
											<>
												<IconButton onClick={() => handleEditClick(template)} color="primary">
													<EditOutlined />
												</IconButton>
												<IconButton onClick={() => handleDeleteClick(template.id)} color="error">
													<DeleteOutline />
												</IconButton>
											</>
										)}
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default RegExpTemplates
