import React, {useEffect, useState}                                 from "react"
import {useNavigate, useParams}                                     from "react-router-dom"
import {Container, useTheme, InputBase, IconButton, Fab, Box, Link} from "@mui/material"
import Grid                                                         from "@mui/material/Unstable_Grid2"
import SearchIcon                                                   from '@mui/icons-material/Search'
import { logger }                                                   from "../../util/log-utils"
import "../../stylesheets/MiniQuestionChat.css"
import {ArrowForward, ChevronRight, Close, Send, SendOutlined}      from "@mui/icons-material"
import {doGet}                                                      from "../../util/do-fetch"
import PurchasePackage                                              from "./PurchasePackage"
import {useUserStore}                                               from "../../state"
import QuestionCode                                                 from "./QuestionCode"

const log = logger("MiniCodeView", 1)

function MiniCodeView({ toggleTheme }) {
	log.trace("Component render")

	const { user } = useUserStore()
	const theme = useTheme()
	const navigate = useNavigate()
	const { code: defaultCode } = useParams()
	const [packages, setPackages] = useState(null)
	const [prepKit, setPrepKit] = useState(null)
	const [code, setCode] = useState(defaultCode || '')
	const [error, setError] = useState(null)

	if(packages && packages.length) {
		return <PurchasePackage packages={packages} prepKit={prepKit} title={"Buy stuff"}/>
	}

	return (
		<Container style={{ maxWidth: '800px', marginTop: "-80px", textAlign: 'center', height: '100vh', display: 'flex'  }}>
			<Grid container justifyContent="center" sx={{height: "100%", border: "0px solid purple"}}>
				<Box height={'100%'}
				     display={"flex"}
				     flexDirection={"column"}
				     justifyContent={"center"}
				     alignItems={"center"}
				>
					<h2 style={{marginRight: 1, fontWeight: "normal", color: theme.palette.text.disabled}}>
						Enter Question Code
					</h2>
					<QuestionCode
						defaultCode={defaultCode}
						onSubmit={(code)=>{
							navigate(`/q/${code}`)
						}}
					/>
					<Box>
						<Box textAlign="center" color={theme.palette.error.light} minHeight={25}>
							{error}
						</Box>
						<Box mt={9} color={theme.palette.text.secondary}>
							No question code?  <Link href="/explore">Explore Birdsy &rarr;</Link>
						</Box>
					</Box>
				</Box>
			</Grid>
		</Container>
	)
}

export default React.memo(MiniCodeView)
