import React, {useEffect, useState} from 'react'
import BirdsyMiniLogo               from "../BirdsyMiniLogo"
import {
	Box,
	Chip,
	Divider,
	IconButton, List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	useTheme
}                                                                                          from "@mui/material"
import {
	AccountCircleOutlined,
	ArrowBack,
	AutoAwesome,
	Check,
	CheckCircle, ChevronRight, Close,
	CloseOutlined,
	Logout, More, UnfoldMore
} from "@mui/icons-material"
import AccountCircle  from "@mui/icons-material/AccountCircle"
import ThemeToggle    from "../ThemeToggle"
import {useUserStore} from "../../state"
import {useNavigate}    from "react-router-dom"
import ScoreAndProgress from "./ScoreAndProgress"
import LeftRight        from "../LeftRight"

const TopicToc = ({topics, answers, selectedQuestion, onTopicClick, onQuestionClick}) => {

	const theme = useTheme()
	const [scoredTopics, setScoredTopics] = useState(null)
	const [topic, setTopic] = useState(null)

	useEffect(()=>{
		if(topics && answers) {
			for (let i = 0; i < topics.length; i++) {
				scoreTopic(topics[i], answers)
			}
			setScoredTopics(topics)
		}
	}, [topics, answers])

	const handleTopicClick = (t) => {
		onTopicClick(t)
	}

	const handleQuestionClick = (q) => {
		onQuestionClick(q)
	}

	const scoreTopic = (t, as) => {
		console.log(`scoreTopic ${t.id}`)
		let answeredCount = 0
		let correctCount = 0
		let totalQuestions = 0

		if (t.topics) {
			for (let i = 0; i < t.topics.length; i++) {
				scoreTopic(t.topics[i], as)
			}

			// Calculate average percentComplete and percentCorrect for parent topics
			const totalChildTopics = t.topics.length
			t.percentComplete = t.topics.reduce((acc, t) => acc + (t.percentComplete || 0), 0) / totalChildTopics
			t.percentCorrect = t.topics.reduce((acc, t) => acc + (t.percentCorrect || 0), 0) / totalChildTopics
		}
		else if (t.questions) {
			totalQuestions = t.questions.length

			for (let x = 0; x < t.questions.length; x++) {
				const q = t.questions[x]
				const a = as.find(o => o.question_id === q.id)
				if (a) {
					q.userAnswer = a
					answeredCount++
					if (a.is_correct) {
						correctCount++
					}
				}
			}

			t.percentComplete = totalQuestions > 0 ? (answeredCount / totalQuestions) * 100 : 0
			t.percentCorrect = totalQuestions > 0 ? (correctCount / totalQuestions) * 100 : 0
		}
	}

	const renderTopics = (ts, depth) => {
		return <List>
			{
				ts.map(t => {
					return [
						<ListItem
							key={t.id}
							selected={selectedQuestion && selectedQuestion.topic && selectedQuestion.topic.id === t.id}
							sx={{
								pl: (2 + (depth * 4)),
								cursor: t.is_leaf ? 'pointer' : 'default',
							}}
							onClick={() => handleTopicClick(t)}
							secondaryAction={
								t.questions &&
									<IconButton edge="end" onClick={(e) => {
										e.preventDefault()
										e.stopPropagation()
										t.is_leaf && setTopic(t)
									}}>
										<UnfoldMore />
									</IconButton>
							}
						>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="flex-start"
								alignItems="center"
								sx={{
									color: t.is_leaf ? theme.palette.primary.main : theme.palette.text.primary
								}}
							>
								<ScoreAndProgress percentComplete={t.percentComplete} percentCorrect={t.percentCorrect} />
								<Box>{t.name}</Box>
							</Box>
						</ListItem>, t.topics && t.topics.length > 0 && renderTopics(t.topics, depth + 1)]
				})
			}
		</List>
	}

	const renderQuestions = (topic) => {
		return <Box pt={1}>
			<LeftRight
				sx={{ml: 1.5}}
				justifyContent="flex-start"
				left={
					<IconButton onClick={()=>setTopic(null)}>
						<ArrowBack/>
					</IconButton>
				}
				right={
					<Box ml={1}>
						<Box sx={{opacity: 0.5}}>
							<TopicPath topic={topic}/>
						</Box>
						<Box>
							{topic.name}
						</Box>
					</Box>
				}
			/>
			<List sx={{p:0, mt: 2}}>
				{
					topic.questions && topic.questions.map((q,i)=> {
						let borderColor
						let bgColor
						let color
						if(q.userAnswer) {
							if(q.userAnswer.is_correct) {
								borderColor = theme.palette.success.main
								bgColor = theme.palette.success.main
								color = theme.palette.success.contrastText
							}
							else {
								borderColor = theme.palette.error.main
								bgColor = theme.palette.error.main
								color = theme.palette.error.contrastText
							}
						}
						else {
							borderColor = theme.palette.primary.main
							bgColor = "transparent"
							color = theme.palette.text.primary
						}

						return <ListItem
							key={q.id}
							selected={selectedQuestion && selectedQuestion.id === q.id}
							sx={{p: 0}}
						>
							<Box
								sx={{
									p: 2,
									cursor: 'pointer',
									backgroundColor: 'transparent',
									'&:hover': {
										backgroundColor: theme.palette.action.hover,
									},
								}}
							     onClick={()=>handleQuestionClick(q)}
									flex={1}
							     display="flex"
							     justifyContent="flex-start"
							     alignItems="flex-start"
							>
								<Box sx={{
										border: '1px solid ' + borderColor,
										backgroundColor: bgColor,
										color: color,
										minWidth: '32px',
										height: '32px',
										borderRadius: '32px',
										mr: 1.5
									}}
								     display="flex"
								     justifyContent="center"
								     alignItems="center"
								>
									{i+1}
								</Box>
								<Box sx={{pt: .6}}>
									{q.question}
									{
										q.userAnswer &&
											<Chip
												sx={{ml: 1}}
												color={q.userAnswer.is_correct ? "success" : "error"}
												label={q.userAnswer.is_correct ? "Correct" : "Incorrect"}
												variant="outlined"
												size="small"
												icon={
													q.userAnswer.is_correct
														? <Check/>
														: <Close/>
												}
											/>
									}

								</Box>
							</Box>
							<Divider/>
						</ListItem>
					})
				}
			</List>
		</Box>

	}

	return <Box>
		{ topic
			? renderQuestions(topic)
			: renderTopics(scoredTopics || [], 0)
		}
	</Box>
}

const TopicPath = ({topic}) => {

	return topic &&
		topic.path &&
		<div><small>{topic.path.split("/").slice(0, -1).filter(p => p && p!=='').join(" · ")}</small></div>
}

export default TopicToc;
