const OFF = -1
const TRACE = 0
const DEBUG = 1
const INFO = 2
const WARN = 3
const ERROR = 4

const _loggers = {}
const _doLog = (name, fn, msg) => {
	if(typeof(msg) === 'undefined') msg = ''
	if(Array.isArray(msg)) msg = msg.join(" ")
	if(typeof(msg) === 'object') {
		fn(`[${name}]`)
		fn(msg)
	}
	else {
		fn(`[${name}] ${msg}`)
	}
}
const _devnull = () => {}
const _createLogger = (name, logger) => {
	return (msg) => {
		_doLog(name, logger, msg)
	}
}

const logger = (name, level) => {
	if(level !== 0 && !level) level = DEBUG
	let log = _loggers[name]
	if(!log) {
		log = {
			trace: level <= TRACE ? _createLogger(name, console.log) : _devnull,
			debug:  level <= DEBUG ? _createLogger(name, console.log) : _devnull,
			info:  level <= INFO ? _createLogger(name, console.info) : _devnull,
			warn:  level <= WARN ? _createLogger(name, console.warn) : _devnull,
			error: level <= ERROR ? _createLogger(name, console.error) : _devnull
		}
		_loggers[name] = log
	}
	return log
}

export {logger, TRACE, DEBUG, INFO, WARN, ERROR}
