// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {

}

.auth-form {
	width: 500px;
	height: 700px;
	margin: auto;
}
@media (max-width: 1024px) {
    .login-form {
        width: 75%
    }
}

@media (max-width: 900px) {
    .login-form {
        width: 90%
    }
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/Auth.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,YAAY;AACb;AACA;IACI;QACI;IACJ;AACJ;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".auth-container {\n\n}\n\n.auth-form {\n\twidth: 500px;\n\theight: 700px;\n\tmargin: auto;\n}\n@media (max-width: 1024px) {\n    .login-form {\n        width: 75%\n    }\n}\n\n@media (max-width: 900px) {\n    .login-form {\n        width: 90%\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
