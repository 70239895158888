import React, {useEffect, useState}                                    from 'react'
import {Box, Button, Chip, InputAdornment, Stack, TextField, useTheme} from '@mui/material'
import {
	Add, AddCircle,
	ArrowCircleUp,
	ArrowCircleUpOutlined,
	ArrowUpward, ArrowUpwardOutlined,
	AutoAwesome, AutoAwesomeMosaic, AutoAwesomeMotion, AutoAwesomeSharp,
	Close, CloseOutlined,
	KeyboardArrowDown,
	Send
}                                                                      from '@mui/icons-material'
import {logger}      from "../../../util/log-utils"
import useMediaQuery from "@mui/material/useMediaQuery"

const log = logger("SendMessage", 1)

const SendMessage = ({onSend, placeholder, actionsShown, onClickShowActions, onFocusChange}) => {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const [body, setBody] = useState("")
	const [hasFocus, setHasFocus] = useState(false)

	useEffect(()=> {
		onFocusChange && onFocusChange(hasFocus)
	}, [hasFocus])

	const handleSend = () => {
		const s = body
		setBody("")
		onSend(s)
	}

	const startAdornment = <InputAdornment
			sx={{cursor: "pointer", ml: -0.5}}
			onClick={onClickShowActions}
			position="start">
			<Add
				sx={{
					color: actionsShown ? theme.palette.error.main : theme.palette.text.primary,
					transform: actionsShown ? 'rotate(-45deg)' : 'rotate(-0deg)',
					transition: 'color 250ms, transform 250ms'
				}}
			/>
		</InputAdornment>


	return (
		<Box display="flex"
		     flex={1}
		     flexDirection="column"
		     border={"0px solid orange"}
		     mx={0.5}
		>
			<Box display="flex" justifyContent={"center"}>
				<TextField
					autoComplete="off"
					name="send-message"
					fullWidth={true}
					variant="outlined"
					size={isSmallScreen ? "small" : ""}
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: '30px',
							paddingX: isSmallScreen ? '2' : null,
							backgroundColor: theme.palette.translucent,
							border: '1px solid ' + theme.palette.divider,
							backdropFilter: "blur(10px)",
							WebkitBackdropFilter: "blur(10px)",
							'& fieldset': {
								border: '1px solid ' + theme.palette.divider, // Default border color
							},
							'&:hover fieldset': {
								border: '1px solid ' + theme.palette.divider, // Border color on hover
							},
							//'&.Mui-focused fieldset': {
							//	border: '1px solid ' + theme.palette.secondary.main, // Border color when focused
							//},
						},
						marginX: "auto" }}
					placeholder={placeholder || "Ask me something"}
					value={body}
					onChange={(event) => {
						setBody(event.target.value)
					}}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							handleSend()
						}
					}}
					onFocus={()=>setHasFocus(true)}
					onBlur={()=>setHasFocus(false)}
					InputProps={{
						startAdornment: startAdornment,
						endAdornment: hasFocus && <InputAdornment
							style={{cursor: "pointer"}}
							onClick={handleSend}
							position="end"
						>
							<ArrowUpward />
						</InputAdornment>,
					}}
				/>
			</Box>
		</Box>
	)
}

export default SendMessage
