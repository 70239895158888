import { useEffect, useState } from 'react'
import getSupabase             from "../util/supabase-utils"
import {jwtDecode}             from "jwt-decode"

const useUserRoles = (roleNames) => {
	const [hasRole, setHasRole] = useState(false)

	useEffect(() => {
		const checkUserRoles = async () => {
			const token = (await getSupabase().auth.getSession()).data.session?.access_token

			if (token) {

				const userInfo = jwtDecode(token)
				const userRoles = userInfo.user_role || []
				// Check if the user has at least one of the specified roles
				const hasRequiredRole = userRoles.some(role => roleNames.includes(role))
				setHasRole(hasRequiredRole)
			}
		}

		checkUserRoles()
	}, [roleNames])

	return hasRole
}

export default useUserRoles
