import React                     from 'react';
import {Chip, Typography}        from '@mui/material';
import {getContrastingTextColor} from "../../util/utils"

/**
 * CategoryLabel Component
 *
 * Renders a chip with a background color, border color, and text color based on the given category.
 *
 * Props:
 * - category (object): An object containing the category information (name, color, color2).
 * - getContrastText (function): A function to determine the text color based on the background color.
 * - ...props: Other props that can be passed into the Chip component.
 */
const CategoryLabel = ({ category, ...props }) => {
	if (!category) return null; // Handle case where category is not provided

	//const textColor = getContrastingTextColor(category.color);

	return (
		<Typography
			color={category.color}
			{...props}
		>
			{category.name}
		</Typography>
	)
}


export default CategoryLabel
