import React, {useState}                                                                           from 'react'
import {Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Typography, useTheme,} from '@mui/material'
import moment                                                                                      from 'moment'
import {Alert}                                                               from "@mui/material"
import {logger}                                                              from "../../util/log-utils"

const log = logger("TopicJobCard", 1)

function TopicJobCard({topicJob, productName, onRetry}) {
	const theme = useTheme()
	const [retrying, setRetrying] = useState(false)
	const stalled = topicJob && moment(topicJob.created_at).isBefore(moment().subtract(5, 'minutes'))

	const handleRetry = async () => {
		if (onRetry) {
			setRetrying(true)
			try {
				await onRetry(topicJob)
			}
			finally {
				setRetrying(false)
			}
		}
	}

	const fmt = (dt) => {
		return dt && moment(dt).calendar()
			//.format('M/d h:mm a')
	}

	const clr = (status, stalled) => {
		if(stalled) {
			return 'warning'
		}
		switch (status) {
			case ('processing'):
				return 'primary'
				break
			case('failed'):
				return 'error'
				break
			case('completed'):
				return 'success'
				break
			default:
				return 'warning'
		}
	}

	return (
		topicJob &&
		<Card key={topicJob.topic_id} sx={{my: 2}}>
			<CardContent>
				<Box sx={{pb: 1}}>
					<small style={{color: theme.palette.text.disabled}}>
						{productName}
						{topicJob.topic.path.split('/').slice(0, -1).join(' / ')}
					</small>
				</Box>
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h6" component="div">
						{topicJob.topic.name}
					</Typography>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="flex-start"
						alignItems="flex-end"
					>
						<Chip
							size="small"
							style={{textTransform: "capitalize"}}
							label={stalled ? 'Stalled' : topicJob.status}
							color={clr(topicJob.status, stalled)}
							icon={topicJob.status === 'processing' && <CircularProgress size={14} sx={{mr: 1}}/>}
						/>
					</Box>
				</Box>
				<Box>
					<small>
						<span style={{color: stalled
								? theme.palette.error.main
								: theme.palette.text.primary
						}}>Created: {fmt(topicJob.created_at)}</span>
						{topicJob.updated_at && <span>, Updated: {fmt(topicJob.updated_at)}</span>}
						&nbsp;[{topicJob.topic_id}]
					</small>
				</Box>
				{
					topicJob.error_message &&
					<Alert severity="error">{topicJob.error_message}</Alert>
				}
				{
					(topicJob.status === 'failed' || stalled) &&
					<Box sx={{mt: 1, textAlign: "center"}}>
						<Button size="small" disabled={retrying} onClick={() => handleRetry(topicJob)}>
							Retry
							{retrying && <CircularProgress size={18}/>}
						</Button>
					</Box>
				}
			</CardContent>
		</Card>
	)
}

export default TopicJobCard
