// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body > #root > div {
  --height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  font-family: Poppins, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	/* prevent text selection */
	xxx-webkit-user-select: none; /* Safari */
	xxx-moz-user-select: none; /* Firefox */
	xxx-ms-user-select: none; /* Internet Explorer/Edge */
	xxxuser-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,SAAS;EACT,uCAAuC;EACvC,mCAAmC;EACnC,kCAAkC;;CAEnC,2BAA2B;CAC3B,4BAA4B,EAAE,WAAW;CACzC,yBAAyB,EAAE,YAAY;CACvC,wBAAwB,EAAE,2BAA2B;CACrD,oBAAoB,EAAE,yEAAyE;AAChG;;AAEA;EACE;aACW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');\n\nbody > #root > div {\n  --height: 100vh;\n  width: 100%;\n}\n\nbody {\n  margin: 0;\n  font-family: Poppins, Arial, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n\t/* prevent text selection */\n\txxx-webkit-user-select: none; /* Safari */\n\txxx-moz-user-select: none; /* Firefox */\n\txxx-ms-user-select: none; /* Internet Explorer/Edge */\n\txxxuser-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
