import React      from 'react';
import { Outlet } from 'react-router-dom';
import Navbar     from "../Navbar"

const MainLayout = ({ toggleTheme }) => {
	return (
		<div>
			<Navbar toggleTheme={toggleTheme} />
			<div>
				<Outlet /> {/* This will render the matched child route */}
			</div>
		</div>
	);
};

export default MainLayout;
