import React, { useEffect, useState }                                                       from 'react'
import {
	Button,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
	Box,
	Container,
	Typography,
	Divider, IconButton
}                                          from '@mui/material'
import CategoryModal                       from './CategoryModal'
import getSupabase                         from "../../util/supabase-utils"
import LeftRight                           from "../LeftRight"
import {ArrowBack, Circle, CircleOutlined} from "@mui/icons-material"
import GradientColorBox                    from "../GradientColorBox"
import LeftRightCenter                     from "../LeftRightCenter"
import {useNavigate}                       from "react-router-dom"

const Categories = () => {
	const navigate = useNavigate()
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)

	useEffect(() => {
		fetchCategories()
	}, [])

	const fetchCategories = async () => {
		setLoading(true)
		const { data, error } = await getSupabase()
			.from('category')
			.select('*')
			.order('name', { ascending: true })

		if (error) {
			console.error('Error fetching categories:', error.message)
		} else {
			setCategories(data)
		}
		setLoading(false)
	}

	const handleCategoryClick = (category) => {
		setSelectedCategory(category)
		setModalOpen(true)
	}

	const handleAddCategory = () => {
		setSelectedCategory(null)
		setModalOpen(true)
	}

	const handleModalClose = () => {
		setModalOpen(false)
	}

	const handleCategorySaved = () => {
		fetchCategories()
		setModalOpen(false)
	}

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" mt={4}>
				<CircularProgress />
			</Box>
		)
	}

	return (
		<Container maxWidth="sm">
			<Box mt={4}>
				<LeftRight
					left={
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<IconButton onClick={() => navigate(`/admin`)}>
								<ArrowBack/>
							</IconButton>
							<Typography variant={"h5"}>
								Categories
							</Typography>
						</Box>
					}
					right={
						<Button variant="contained" color="primary" onClick={handleAddCategory}>
							Add Category
						</Button>
					}
				/>
				<Divider sx={{my: 2}}/>
				<List>
					{categories.map((category) => (
						<ListItem
							key={category.id}
							button
							onClick={() => handleCategoryClick(category)}
						>
							<ListItemText
								primary={category.name}
								secondary={`Token: ${category.token}`}
							/>
							<Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
								<Circle sx={{color: category.color}}/>
								<GradientColorBox width={60} height={20} borderRadius={10} color={category.color} color2={category.color2} />
								<Circle sx={{color: category.color2}}/>
							</Box>
						</ListItem>
					))}
				</List>
				{modalOpen && (
					<CategoryModal
						category={selectedCategory}
						onClose={handleModalClose}
						onSaved={handleCategorySaved}
					/>
				)}
			</Box>
		</Container>
	)
}

export default Categories
