import React, {useEffect, useState} from 'react'
import {
	Box,
	Breadcrumbs,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, Divider,
	FormControl, IconButton,
	InputAdornment,
	Link,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material'
import moment              from 'moment'
import Grid                from "@mui/material/Unstable_Grid2"
import {DatePicker}           from "@mui/x-date-pickers"
import {Alert} from "@mui/material"
import {LoadingButton} from "@mui/lab"
import {ArrowBack, Search}    from "@mui/icons-material"
import {useNavigate}                    from "react-router-dom"
import {doDelete, doGet, doPost, doPut} from "../../util/do-fetch"
import {logger}                         from "../../util/log-utils"
import LicenseTable        from "./LicenseTable.js"

const log = logger("Licenses", 1)

function Licenses() {
	const navigate = useNavigate()
	const [licenses, setLicenses] = useState([])
	const [filtered, setFiltered] = useState([])
	const [saving, setSaving] = useState(false)
	const [userId, setUserId] = useState("")
	const [productId, setProductId] = useState("")
	const [expiresOn, setExpiresOn] = useState("")
	const [users, setUsers] = useState([])
	const [products, setProducts] = useState([])
	const [addLicenseOpen, setAddLicenseOpen] = useState(false)
	const [filterText, setFilterText] = useState('')
	const [error, setError] = useState(null)

	useEffect(() => {
		// Fetch licenses, users, and products and set their respective state.
		// You can use async functions within the useEffect for fetching.
		fetchLicenses()
			.then(() => {
				fetchUsers()
				fetchProducts()
			})
	}, [])

	useEffect(()=>{
		if(filterText && filterText.length > 0) {
			const filterLower = filterText.toLowerCase()
			setFiltered(licenses.filter(l => {
				const matchStr = [l.product.name, l.user.email, l.expires_at, l.id].join().toLowerCase()
				return matchStr.indexOf(filterLower) !== -1
			}))
		}
		else {
			setFiltered(licenses)
		}

	}, [licenses, filterText])


	const fetchLicenses = async () => {
		const things = await fetchThing("/admin/licenses")
		setLicenses(things)
	}
	const fetchUsers = async () => {
		const things = await fetchThing("/admin/users")
		setUsers(things)
	}
	const fetchProducts = async () => {
		const things = await fetchThing("/admin/products")
		setProducts(things)
	}

	const fetchThing = async (relativeUrl) => {
		log.debug("fetching " + relativeUrl)
		try {
			const response = await doGet(relativeUrl)
			const data = await response.json()
			return data
		}
		catch (err) {
			log.error(err)
			throw err
		}
	}

	const createLicense = async () => {
		setSaving(true)
		try {
			const response = await doPut(`/admin/licenses`, {
				body: JSON.stringify({
					userId: userId,
					productId: productId,
					expiresOn: expiresOn
				})
			})

			log.debug("response.ok? " + response.ok)
			log.debug("response.status? " + response.status)
			if (response.ok) {
				const product = await response.json()
				setProducts((prevProducts) => [...prevProducts, product])
			}
			else if (response.status === 400) {
				const errorData = await response.json()
				// setAddProductErrors(errorData.errors) // Assuming errorData contains messages array
			}
			else {
				// Handle other error cases
				console.error('An error occurred:', response.statusText)
				// setAddProductErrors('An error occurred while saving the product.')
			}
			setAddLicenseOpen(false)
		}
		catch (err) {
			log.error(err)
			//setAddProductErrors(err)
		}
		finally {
			//setAddProductComplete(true)
			setSaving(false)
		}
	}

	const handleAddLicenseOpen = () => setAddLicenseOpen(true)

	const handleCloseDialog = () => setAddLicenseOpen(false)

	const handleFilterChange = (event) => {
		setFilterText(event.target.value)
	}

	const handleDeleteLicense = async (licenseToDelete) => {
		const response = await doDelete(`/admin/licenses/${licenseToDelete}`)

		if(response.ok) {
			setLicenses(prev => {
				return prev.filter(p=> p.id !== licenseToDelete)
			})
		}
		else {
			try{
				const err = await response.json()
				if(err.details || err.message) {
					setError(err.details || err.message)
				}
				else {
					setError("Error deleting license. " + JSON.stringify(err, null, 2))
				}
			}
			catch(e) {
				setError("Error deleting license.")
			}
		}
	}

	const handleUpdateExpiresAt = async (licenseToUpdate, newExpiresAt) => {
		const response = await doPost(`/admin/licenses/${licenseToUpdate}`, {
			body: JSON.stringify({
				id: licenseToUpdate,
				expires_at: newExpiresAt
			})
		})

		if(response.ok) {
			const updatedLicense = await response.json()
			setLicenses(prev => {
				return prev.map(p=> p.id === updatedLicense.id ? updatedLicense : p)
			})
		}
		else {
			alert("Error updating license.")
		}
	}

	return (
		<Container maxWidth="lg">
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack/>
					</IconButton>
					<Typography variant={"h5"}>
						Licenses
					</Typography>
				</Box>
				<Stack direction={"row"} spacing={1}>
					<Button variant={"outlined"} size="small" onClick={handleAddLicenseOpen}>Add License</Button>
					<FormControl>
						<TextField
							label="Filter"
							size="small"
							value={filterText}
							onChange={handleFilterChange}
							InputProps={{
								endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
							}}
						/>
					</FormControl>
				</Stack>
			</Stack>
			<Divider sx={{mt: 2}}/>
			{
				error && <Alert color="error" onClose={()=>setError(null)}>
					{error}
				</Alert>
			}
			<LicenseTable licenses={filtered}
			              onDeleteLicense={handleDeleteLicense}
			              onUpdateExpiresAt={handleUpdateExpiresAt}
			/>

			<Dialog open={addLicenseOpen} onClose={handleCloseDialog}>
				<DialogTitle>Add License</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} p={1}>
						<Grid xs={12}>
							<TextField
								select
								fullWidth
								variant="outlined"
								id="license-user"
								label="User"
								value={userId}
								onChange={(e) => setUserId(e.target.value)}
							>
								{users && users.map(user => (
									<MenuItem key={user.id} value={user.id}>{user.email}</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid xs={12}>
							<TextField
								select
								fullWidth
								variant="outlined"
								id="license-product"
								label="Product"
								value={productId}
								onChange={(e) => setProductId(e.target.value)}
							>
								{products && products.map(product => (
									<MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid xs={12}>
							<DatePicker
								fullWidth
								slotProps={{
									textField: {
										error: false,
									},
								}}
								variant="outlined"
								label="Expires On"
								value={expiresOn ?? ""}
								onChange={(value) => setExpiresOn(value)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button disabled={saving} onClick={handleCloseDialog}>Cancel</Button>
					<LoadingButton
						loading={saving}
						loadingIndicator="Saving..."
						onClick={createLicense}>
						Save
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Container>
	)
}

export default Licenses
