import React, { useState } from 'react'
import {
	Modal,
	Box,
	TextField,
	Button,
	Typography,
	IconButton, FormControl, InputLabel,
}                       from '@mui/material'
import CloseIcon        from '@mui/icons-material/Close'
import getSupabase      from "../../util/supabase-utils"
import LeftRight        from "../LeftRight"
import CustomColorInput from "../CustomColorInput"
import GradientColorBox from "../GradientColorBox"
import LabeledBox       from "../LabeledBox"

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
}

const CategoryModal = ({ category, onClose, onSaved }) => {
	const [name, setName] = useState(category ? category.name : '')
	const [token, setToken] = useState(category ? category.token : '')
	const [color, setColor] = useState(category ? category.color : '#000000')
	const [color2, setColor2] = useState(category ? category.color2 : '#000000')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)

	const validateToken = (value) => {
		const regex = /^[a-z0-9-]+$/
		return regex.test(value)
	}

	const handleSave = async () => {
		setError('')

		if (!name.trim()) {
			setError('Name is required.')
			return
		}

		if (!token.trim()) {
			setError('Token is required.')
			return
		}

		if (!validateToken(token)) {
			setError('Token can only contain lowercase letters, numbers, and "-"')
			return
		}

		const categoryData = {
			name,
			token,
			color,
			color2,
		}

		setLoading(true)

		let response

		const supabase = getSupabase()
		if (category) {
			// Update existing category
			response = await supabase
				.from('category')
				.update(categoryData)
				.eq('id', category.id)
		} else {
			// Insert new category
			response = await supabase.from('category').insert([categoryData])
		}

		const { error } = response

		setLoading(false)

		if (error) {
			setError(error.message)
		} else {
			onSaved()
		}
	}

	return (
		<Modal open onClose={onClose}>
			<Box sx={modalStyle}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">
						{category ? 'Edit Category' : 'Add Category'}
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box mt={2}>
					<TextField
						label="Name"
						value={name}
						fullWidth
						onChange={(e) => setName(e.target.value)}
						margin="normal"
						required
					/>
					<TextField
						label="Token"
						value={token}
						fullWidth
						onChange={(e) => setToken(e.target.value)}
						margin="normal"
						required
						error={!!error && error.includes('Token')}
					/>

					<LabeledBox label={"Colors"} mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
						<Box display={"flex"} flex={1}>
							<CustomColorInput color={color} setColor={setColor} label={"Color"} />
						</Box>
						<Box display={"flex"} flex={2} mx={1}>
							<GradientColorBox color={color} color2={color2} />
						</Box>
						<Box display={"flex"} flex={1}>
							<CustomColorInput color={color2} setColor={setColor2}  label={"Color 2"} />
						</Box>
					</LabeledBox>

					{error && (
						<Typography color="error" variant="body2" mt={2}>
							{error}
						</Typography>
					)}
				</Box>
				<Box mt={4} display="flex" justifyContent="flex-end">
					<Button onClick={onClose} style={{ marginRight: 8 }}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
						disabled={loading}
					>
						{category ? 'Update' : 'Create'}
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

export default CategoryModal
