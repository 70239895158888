import React, {useEffect, useMemo, useState} from 'react'
import getSupabase                           from "../../util/supabase-utils"
import {
	Backdrop,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Container,
	Dialog, DialogActions,
	DialogContent,
	Divider,
	Link,
	List, ListItem,
	ListItemButton,
	Typography,
	Alert, Skeleton,
	useTheme
} from "@mui/material"
import styled            from "@emotion/styled"
import BirdsyMiniLogo                         from "../BirdsyMiniLogo"
import moment                                        from "moment"
import {ArrowBack, ArrowForward, AutoAwesome, Close} from "@mui/icons-material"
import {doGet, doPost}           from "../../util/do-fetch"
import PackageListItem           from "./PackageListItem"
import {fmt2Dec}                 from "../../util/prepkit-utils"
import LeftRight                 from "../LeftRight"
import Grid                      from "@mui/material/Unstable_Grid2"
import MiniCheckout              from "./MiniCheckout"
import {Elements}                from "@stripe/react-stripe-js"
import {loadStripe}              from "@stripe/stripe-js"
import {useNavigate}             from "react-router-dom"
import DialogWithBlurredBackdrop from "../DialogBlurred"
import LeftRightCenter           from "../LeftRightCenter"
import MiniSkeleton              from "./MiniSkeleton"
import SelectPackageList         from "./SelectPackageList"

const TryPackage = ({packages, onTryPackage, onCancel}) => {

	const theme = useTheme()

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<MiniSkeleton />
			<Dialog
				open={true}
			>
				<Box pt={4}>
					<LeftRightCenter
						left={
							<Box px={2}>
								<Close sx={{color: "transparent"}}/>
							</Box>
						}
						center={
							<Box display="flex" justifyContent="center" pb={2}>
								<BirdsyMiniLogo width={125}/>
							</Box>
						}
						right={
							<Box pb={4}>
								<Button size={"small"} onClick={onCancel}>
									<Close color={"error"}/>
								</Button>
							</Box>
						}
					/>
				</Box>
				<DialogContent sx={{width: 500, px: 5, pb: 4}}>
					<Box display={"flex"}
					     justifyContent={"center"}
					     flexDirection={"column"}
					     pb={2}
					>
						<Typography sx={{fontWeight: 500}}>
							Choose a PrepKit to start your
							<span style={{color: theme.palette.secondary.main}}> complimentary </span>
							study session.
						</Typography>
					</Box>
					<SelectPackageList relatedPackages={packages}
					                   onSelectPackage={onTryPackage}
					/>
				</DialogContent>
			</Dialog>

		</Container>
	)
}

export default TryPackage
