import React, {useEffect, useMemo, useState}                                             from 'react'
import {
	Box,
	Button,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Link,
	Switch,
	TextField,
	Tooltip
}                                  from '@mui/material'
import LeftRight                                           from "../LeftRight"
import {ArrowLeft, ChevronLeft, Clear, Help, HighlightOff} from "@mui/icons-material"
import RegExpTemplates                                     from "./RegExpTemplates"
import Grid                        from "@mui/material/Unstable_Grid2"
import ConfirmDialog               from "../ConfirmDialog"

function MediaJobItemFilter({filteredItemCount, onChange, onReplaceAll}) {
	const [onlyModified, setOnlyModified] = useState(false)
	const [showTemplates, setShowTemplates] = useState(false)
	const [searchTerm, setSearchTerm] = useState("")
	const [replaceWith, setReplaceWith] = useState("")
	const [confirmReplace, setConfirmReplace] = useState(false)

	const disableReplace = useMemo(()=>{
		return !filteredItemCount || !searchTerm || !searchTerm.length
	}, [searchTerm, filteredItemCount])


	useEffect(() => {
		onChange({onlyModified, searchTerm})
	}, [onlyModified, searchTerm]);


	return (
		<Box pb={2}>
			<Box display="flex"
			     flexDirection="row"
			     justifyContent="space-between"
			     alignContent="center"
			>
				<TextField
					label="Filter on text or regular expression"
					variant="outlined"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					fullWidth
					margin="normal"
					sx={{mr: 1}}
					InputProps={{
						endAdornment: (
							searchTerm &&
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchTerm('')}
										edge="end"
									>
										<HighlightOff />
									</IconButton>
								</InputAdornment>
						)
					}}
				/>
				<IconButton onClick={() => setSearchTerm(replaceWith)}>
					<ChevronLeft/>
				</IconButton>
				<TextField
					label="Replace"
					variant="outlined"
					value={replaceWith}
					disabled={disableReplace}
					onChange={(e) => setReplaceWith(e.target.value)}
					fullWidth
					margin="normal"
					sx={{ml: 1}}
				/>
			</Box>
			<LeftRight
				left={
					<Box>
						<small>
							Search is case sensitive.
							For case-insensitive search use
							<Link sx={{ml: 0.5}} sx={{cursor: 'pointer'}} onClick={()=>setShowTemplates(true)}>regular expressions</Link>.
						</small>
					</Box>
				}
				right={
					<Box display="flex"
					     flexDirection="row"
					     justifyContent="flex-end"
					     alignItems="center"
					>
						<FormControlLabel
							control={<Switch color="secondary" checked={onlyModified} onChange={(e) => setOnlyModified(e.target.checked)} />}
							label="Show Modified Only"
							sx={{ whiteSpace: 'nowrap', ml: 2 }}
						/>
						<Button disabled={disableReplace}
						        variant="contained"
						        onClick={()=>setConfirmReplace(true)}
						>
							Replace All
						</Button>
						<ConfirmDialog
							open={confirmReplace}
							title="Confirm Replace All"
							content={<span>
								Are you sure you want to replace <b><u>{filteredItemCount}</u></b> item(s)?
								<br/><br/>
								<code><b>{searchTerm} -> {replaceWith || "[EMPTY]"}</b></code>
							</span>}
							onConfirm={() => {
								setConfirmReplace(false)
								onReplaceAll(replaceWith || "")
							}}
							onCancel={() => setConfirmReplace(false)}
						/>

					</Box>
				}
			/>
			{
				showTemplates &&
					<Grid xs={6} sx={{pr: 1}}>
						<RegExpTemplates
							onClose={()=>setShowTemplates(false)}
							onClick={(regex) => {
								setSearchTerm(regex.pattern)
								setShowTemplates(false)
							}} />
					</Grid>
			}
		</Box>
	);
}

export default MediaJobItemFilter;
