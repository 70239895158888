import React from 'react';
import { Box } from '@mui/material';

const GradientColorBox = ({ color, color2, ...props }) => {
	return (
		<Box
			{...props}
			height={props.height || "40px"}
			width={props.width || "100%"}
			sx={{
				background: `linear-gradient(to right, ${color}, ${color2})`,
			}}
		/>
	);
};

export default GradientColorBox;
