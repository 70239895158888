import React, {useEffect, useState}                                                          from 'react'
import {
	Alert, AlertTitle,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip, CircularProgress,
	Container, Divider,
	IconButton, Menu, MenuItem,
	Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Tabs, Tooltip,
	Typography, useTheme
} from '@mui/material'
import {doGet, doPost}                         from "../../util/do-fetch"
import LeftRight                                        from "../LeftRight"
import {getStatusByPackage}                             from "../../util/package-statuses"
import {useNavigate}                                    from "react-router-dom"
import LeftRightCenter                                  from "../LeftRightCenter"
import Grid
                                                        from "@mui/material/Unstable_Grid2"
import PackageCard                                      from "./PackageCard"
import {Archive, ArrowBack, Check, MoreVert, Unarchive} from "@mui/icons-material"
import HoverTableRow                                    from "../HoverTableRow"
import moment                                           from "moment"
import BirdsyMiniMark                                   from "../BirdsyMiniMark"
import PackageTableRow                                  from "./PackageTableRow"
import ClickLink                                        from "../ClickLink"

const sorter = {
	sort: (items, getter) => {
		if (!items || items.length === 0) return items;

		return items.sort((a, b) => {
			const valA = getter(a)?.toLowerCase() || "";
			const valB = getter(b)?.toLowerCase() || "";
			console.log(`comparing [${valA}] to [${valB}]`)
			return valA.localeCompare(valB);
		});
	},
	category: (items) => sorter.sort(items, (i) => { return i.category?.name }),
	name: (items) => sorter.sort(items, (i) => { return i.name }),
	status: (items) => sorter.sort(items, (i) => { return  }),
};


const Packages = () => {

	const theme = useTheme()
	const navigate = useNavigate()
	const [packages, setPackages] = useState([])
	const [filtered, setFiltered] = useState([])
	const [sortBy, setSortBy] = useState("name")
	const [tab, setTab] = useState("published")
	const [updatingStatus, setUpdatingStatus] = useState(false)
	const [duplicatedPackage, setDuplicatedPackage] = useState(null)

	useEffect(() => {
		fetchPackages()
	}, [])

	useEffect(() => {
		let items
		if (tab === 'draft') {
			items = packages.filter(p => !p.archived_at && !p.published_at)
		}
		else if (tab === 'published') {
			items = packages.filter(p => !p.archived_at && p.published_at)
		}
		else if (tab === 'archived') {
			items = packages.filter(p => p.archived_at)
		}
		else {
			items = [...packages]
		}

		if(sortBy === "category") {
			setFiltered(items.sort((a, b) => {
				const valA = (a.category?.name?.toLowerCase() || "") + a.name;
				const valB = (b.category?.name?.toLowerCase() || "") + b.name;
				return valA.localeCompare(valB);
			}))
		}
		else if(sortBy === "status") {
			setFiltered(items.sort((a, b) => {
				const valA = (getStatusByPackage(a)?.label?.toLowerCase() || "") + a.name;
				const valB = (getStatusByPackage(b)?.label?.toLowerCase() || "") + b.name;
				return valA.localeCompare(valB);
			}))
		}
		else {
			// sort by name
			setFiltered(items.sort((a, b) => {
				const valA = a.name?.toLowerCase() || "";
				const valB = b.name?.toLowerCase() || "";
				return valA.localeCompare(valB);
			}))
		}

	}, [packages, tab, sortBy])

	const fetchPackages = async () => {
		try {
			const response = await doGet(`/admin/packages`)
			const data = await response.json()
			setPackages(data)
		}
		catch (err) {
			console.error(err)
		}
	}

	const handleArchive = async (pkg) => {
		setUpdatingStatus(true)
		try {
			const response = await doPost(`/admin/packages/${pkg.id}/archive`)
			const data = await response.json()
			setPackages(prev => {
				return [...prev].map(p => p.id === data.id ? data : p)
			})
		}
		catch (err) {
			console.error(err)
		}
		finally {
			setUpdatingStatus(false)
		}
	}

	const handleUnarchive = async (pkg) => {
		setUpdatingStatus(true)
		try {
			const response = await doPost(`/admin/packages/${pkg.id}/unarchive`)
			const data = await response.json()
			setPackages(prev => {
				return [...prev].map(p => p.id === data.id ? data : p)
			})
		}
		catch (err) {
			console.error(err)
		}
		finally {
			setUpdatingStatus(false)
		}
	}

	const handlePublish = async (pkg) => {
		setUpdatingStatus(true)
		try {
			const response = await doPost(`/admin/packages/${pkg.id}/publish`)
			const data = await response.json()
			setPackages(prev => {
				return [...prev].map(p => p.id === data.id ? data : p)
			})
		}
		catch (err) {
			console.error(err)
		}
		finally {
			setUpdatingStatus(false)
		}
	}

	const handleUnpublish = async (pkg) => {
		setUpdatingStatus(true)
		try {
			const response = await doPost(`/admin/packages/${pkg.id}/unpublish`)
				const data = await response.json()
				setPackages(prev => {
					return [...prev].map(p => p.id === data.id ? data : p)
				})
			}
		catch (err) {
			console.error(err)
		}
		finally {
			setUpdatingStatus(false)
		}
	}

	const handleDuplicate = async (pkg) => {
		try {
			const response = await doPost(`/admin/packages/${pkg.id}/duplicate`)
			const data = await response.json()
			navigate(`${data.id}/edit`)
		}
		catch (err) {
			console.error(err)
		}
	}

	return (
		<Container maxWidth={"lg"}>
			<Box>
				<LeftRightCenter
					left={
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<IconButton onClick={() => navigate(`/admin`)}>
								<ArrowBack/>
							</IconButton>
							<Typography variant={"h5"}>
								Packages
							</Typography>
						</Box>
					}
					center={
						<Tabs
							color="primary"
							value={tab}
							exclusive="true"
							onChange={(event, newTab) => setTab(newTab)}
						>
							<Tab value="all" label="All"/>
							<Tab value="draft" label="Draft"/>
							<Tab value="published" label="Published"/>
							<Tab value="archived" label="Archived"/>
						</Tabs>
					}
					right={
						<Button sx={{ml: 4}} variant="contained" onClick={() => navigate('/admin/packages/new')}>
							Add Package
						</Button>
					}
				/>
			</Box>

			<TableContainer style={{border: "1px solid #ccc", borderRadius: '5px', marginTop: "10px", marginBottom: '125px'}}>
				<Table sx={{minWidth: 650, backgroundColor: theme.palette.background.paper}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell component="th" onClick={()=>setSortBy("name")}
							           sx={[sortBy==="name" ? {textDecoration: "underline"} : null, {cursor: 'pointer'}]}
							>
								Package
							</TableCell>
							<TableCell component="th" onClick={()=>setSortBy("status")} align="center"
							           sx={[sortBy==="status" ? {textDecoration: "underline"} : null, {cursor: 'pointer'}]}
							>
								Status
							</TableCell>
							<TableCell component="th" onClick={()=>setSortBy("category")}
							           sx={[sortBy==="category" ? {textDecoration: "underline"} : null, {cursor: 'pointer'}]}
							>
								Category
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							filtered && filtered.map((packageData) => {
								const publishStatus = getStatusByPackage(packageData)
								return (<PackageTableRow key={'pkg-card-' + packageData.id}
								                         packageData={packageData}
								                         publishStatus={publishStatus}
								                         onDuplicate={handleDuplicate}
								                         onUnpublish={handleUnpublish}
								                         onPublish={handlePublish}
								                         onUnarchive={handleUnarchive}
								                         onArchive={handleArchive}
								/>)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>



		</Container>
	)
}

export default Packages
