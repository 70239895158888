import React, {useContext, useEffect, useMemo, useState} from 'react'
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader, Chip, CircularProgress,
	Divider, FormControlLabel,
	IconButton, Link,
	List, Switch,
	TextField,
	useTheme,
}                                                        from '@mui/material'
import {logger}                                                             from "../../util/log-utils"
import {Check, Close, Compare, CompareArrows, Edit, ExpandLess, ExpandMore} from "@mui/icons-material"
import {doPut}                                                              from "../../util/do-fetch"
import MediaJobItemFilter                                                   from "./MediaJobItemFilter"
import AudioPlayer                                                          from "./AudioPlayer"
import AudioPreviewPlayer                                                   from "./AudioPreviewPlayer"

const log = logger("ContentReview", 1)


// stateless
const _matchFilter = (mediaJobItem, searchTerm, regex, caseSensitive) => {

	// Check if the search term is part of the item content
	const content = caseSensitive
		? mediaJobItem.content
		: mediaJobItem.content.toLowerCase()

	return searchTerm
		? regex
			? regex.test(content)
			: content.indexOf(searchTerm) !== -1
		: true
}


function ContentReview({product, mediaJob, audioJobItems, onSaveMediaJobItem}) {

	const theme = useTheme()
	const [expandTrigger, setExpandTrigger] = useState(true)
	const [filter, setFilter] = useState(null)
	const [filteredItems, setFilteredItems] = useState(audioJobItems)
	const [filteredIds, setFilteredIds] = useState(null)

	const searchTerm = useMemo(()=>{
		return filter
			? filter.caseSensitive
				? filter.searchTerm
				: filter.searchTerm.toLowerCase()
			: null
	}, [filter])

	const regex = useMemo(()=>{
		if (searchTerm && searchTerm.startsWith('/') && searchTerm.endsWith('/')) {
			const pattern = searchTerm.slice(1, -1); // Remove the starting and ending slashes
			return new RegExp(pattern, 'i'); // 'i' for case-insensitive search
		}
		else {
			return null
		}

	}, [filter])


	useEffect(() => {
		if(filter) {
			const filteredIds = {}
			const items = audioJobItems.filter(i => {

				// Check if the item needs to be only modified
				const isModified = filter.onlyModified
					? i.original_content !== null
					: true

				// Check if the search term is part of the item content
				const matchesSearchTerm = _matchFilter(i, searchTerm, regex, filter.caseSensitive)

				if(matchesSearchTerm) {
					filteredIds[`t${i.topic_id}`] = true
					if(i.question_id) {
						filteredIds[`q${i.question_id}`] = true
					}
				}

				// Only return true if all conditions are met
				return isModified && matchesSearchTerm
			})

			setFilteredIds(filteredIds)
			setFilteredItems(items)
		}
		else {
			setFilteredItems(audioJobItems)
		}

	}, [audioJobItems, filter])


	const handleExpandAll = () => {
		setExpandTrigger(true)
	}

	const handleCollapseAll = () => {
		setExpandTrigger(false)
	}

	const handleReplaceAll = (searchFor, replaceWith, caseSensitive) => {


		// you were about to do replace all by calling the server for each media_job_item
		// and you were going to do the match checking on the client using the
		// _matchFilter function above...
		// the issue I'm having right now is I think im going to have to gray out topics that are not
		// a match because we need to visually differentiate between topics that are displayed because
		// they match the filter, and those that are there for function only

		//onSaveMediaJobItem(mediaJobItem, editedValue)
	}

	return <Box>
		<Box>
			<MediaJobItemFilter onChange={setFilter} onReplaceAll={handleReplaceAll}/>
		</Box>
		<Card elevation={0}  sx={{
						border: "1px solid " + theme.palette.divider,
						backgroundColor: theme.palette.background.paper,
						borderRadius: '5px'
					}}
		>
			<CardHeader
				title="Review TTS Content"
				action={[
					<Button key="expand-all" onClick={handleExpandAll}>Expand All</Button>,
					<Button key="collapse-all" onClick={handleCollapseAll}>Collapse All</Button>
				]}
			/>
			<Divider/>
			<CardContent sx={{p: 0}}>
			{
				product.topics && product.topics.map(t => {
					return <ContentReviewTopic key={t.id + '-' + t.path}
					                           topic={t}
					                           depth={0}
					                           mediaJob={mediaJob}
					                           audioJobItems={audioJobItems}
					                           filteredItems={filteredItems}
					                           filteredIds={filteredIds}
					                           expandTrigger={expandTrigger}
					                           filter={filter}
					                           onSave={onSaveMediaJobItem}
					/>
				})
			}
			</CardContent>
		</Card>
	</Box>
}

function ContentReviewTopic({mediaJob, topic, depth, audioJobItems, filteredIds, filteredItems, expandTrigger, filter, forceShowOriginal, onSave}) {

	const [expanded, setExpanded] = useState(expandTrigger)
	const mediaJobItem = findMediaJobItems(topic, audioJobItems)[0] // should only be one
	const filtered = useMemo(() => {

	}, [filteredIds])

		/*
		 *  HERE IS WHERE I GOT TIRED.  MAKE IT SO THE FILTERED IDS GET PASS DOWN THE CHAIN SO EACH COMPONENT CAN
		 *  NOW WHETHER IT IS SELECTED OR NOT AND IF IT IS IT NEEDS TO RENDER DIFFERENTLY
		 */

	useEffect(() => {
		setExpanded(expandTrigger)
	}, [expandTrigger])

	return mediaJobItem && <Box>
		<ContentReviewItem mediaJob={mediaJob}
		                   mediaJobItem={mediaJobItem}
		                   bold={true}
		                   editable={true}
		                   depth={depth+1}
		                   onSave={onSave}
		                   expandable={topic.questions}
		                   expanded={expanded}
		                   showDivider={true}
		                   onToggleExpand={() => setExpanded(!expanded)}
		                   forceShowOriginal={forceShowOriginal}
			/>
		{
			topic.topics && topic.topics.map(t => {
				return <ContentReviewTopic key={t.id + '-' + t.path}
				                           mediaJob={mediaJob}
				                           topic={t}
				                           depth={depth+1}
				                           audioJobItems={audioJobItems}
				                           filteredItems={filteredItems}
				                           expandTrigger={expandTrigger}
				                           onSave={onSave}
				                           forceShowOriginal={forceShowOriginal}
				/>
			})
		}
		{
			expanded && topic.questions && topic.questions.map((q,i) => {
				return <ContentReviewQuestion key={'question-' + q.id}
				                              index={i}
				                              mediaJob={mediaJob}
				                              question={q}
				                              depth={depth+1}
				                              filteredItems={filteredItems}
				                              onSave={onSave}
				                              forceShowOriginal={forceShowOriginal}
				/>
			})
		}
	</Box>
}

function ContentReviewQuestion({mediaJob, question, index, depth, filteredItems, filter, forceShowOriginal, onSave}) {

	const [questionItems, setQuestionItems] = useState()

	useEffect(() => {
		setQuestionItems(findMediaJobItems(question, filteredItems))
	}, [filteredItems])

	const getPrefixComp = (mediaJobItem) => {

		const variant="contained"
		switch(mediaJobItem.job_type) {
			case 'option-a':
				return <Chip size="small" variant={variant} label="A" color="primary" sx={{mr:1}}/>
			case 'option-b':
				return <Chip size="small" variant={variant} label="B" color="primary" sx={{mr:1}}/>
			case 'option-c':
				return <Chip size="small" variant={variant} label="C" color="primary" sx={{mr:1}}/>
			case 'option-d':
				return <Chip size="small" variant={variant} label="D" color="primary" sx={{mr:1}}/>
			case 'answer':
				return <Chip size="small" variant={variant} label="Answer" color="success" sx={{mr:1}}/>
		}
		return null
	}
	return <Box>
		{
			questionItems
			&& questionItems.map(i => <ContentReviewItem key={'media-item-' + i.id}
			                                             index={i.job_type === 'question' && index}
			                                             mediaJob={mediaJob}
			                                             mediaJobItem={i}
			                                             editable={true}
			                                             prefix={getPrefixComp(i)}
			                                             depth={depth + (i.job_type === 'question' ? 1 : 2 )}
			                                             onSave={onSave}
			                                             showDivider={i.job_type === 'explanation'}
			                                             forceShowOriginal={forceShowOriginal}
										/>)
		}
	</Box>
}

function ContentReviewItem({mediaJob, mediaJobItem, index, bold, depth, editable, onSave, expandable, expanded,
	                           prefix, onToggleExpand, showDivider, filter, forceShowOriginal}) {
	const theme = useTheme()
	const [editing, setEditing] = useState(false)
	const [editValue, setEditValue] = useState(mediaJobItem && mediaJobItem.content)
	const [showOriginal, setShowOriginal] = useState(false)
	const [saving, setSaving] = useState(false)

	const sx = editable &&
		(
			editing
			? {
				backgroundColor: theme.palette.action.hover,
		      }
			: {
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				}
			  }
		)

	return (
	<Box sx={sx}>
		<Box display="flex"
		     flexDirection="row"
		     justifyContent="flex-start"
		     alignItems="center"
		>
			<Box style={{minWidth: '40px'}}>
			{
				expandable &&
					<IconButton onClick={()=>onToggleExpand()}>
						{
							expanded
								? <ExpandLess />
								: <ExpandMore />
						}
					</IconButton>
			}
			</Box>
			<Box style={{
					marginLeft: `${depth*20}px`,
					width: "100%"
				 }}
			     display="flex"
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			>
				{prefix}
				{editable && editing
					? <Box display="flex" flex="1" sx={{p: 1}}>
						<TextField
							size="small"
							fullWidth={true}
							value={editValue}
							multiline={true}
							onChange={(e) => setEditValue(e.target.value)}
							autoFocus
							sx={{
								backgroundColor: theme.palette.background.paper
							}}
						/>
					</Box>
					: <Box display="flex"
					       flex="1"
					       flexDirection="column"
					       sx={{
							   cursor: editable || expandable ? "pointer" : "default",
						       p: 2
						   }}
					       onClick={(e) => {
							   if(editable) {
								   setEditing(true)
							   }
							   if(expandable) {
								   onToggleExpand()
							   }
							}}
					  >
						    <Box style={{
								fontWeight: bold ? 'bold' : 'normal',
							    color: mediaJobItem.original_content
								    ? theme.palette.secondary.main
								    : 'inherit'
							}}>
							    {
								    mediaJobItem.original_content
								    && (forceShowOriginal || showOriginal) && <Chip sx={{my: 1}} color="secondary" variant="outlined" size="small" label="Modified"/>
							    }
							    {mediaJobItem && <div>{mediaJobItem.content}</div>}
							</Box>
							{
								mediaJobItem.original_content
								&& (forceShowOriginal || showOriginal)
									? <div>
											<Box>
												<Chip sx={{mb: 1, mt: 2}} variant="outlined" size="small" label="Original"/>
												<div>{mediaJobItem.original_content}</div>
											</Box>
											<IconButton onClick={(e)=> {
												e.stopPropagation()
												setShowOriginal(false)
											}}>
												<CompareArrows color="secondary"/>
											</IconButton>
									  </div>
									: mediaJobItem.original_content &&
										<span>
											<IconButton onClick={(e)=>{
												e.stopPropagation()
												setShowOriginal(true)
											  }}>
												<CompareArrows color="secondary"/>
										    </IconButton>
										</span>
							}
					  </Box>
				}
				<Box>
					{editing
						&& <Box sx={{p: 1}}>
							<IconButton edge="end"
							            sx={{mr: 1}}
							            onClick={async (e) => {
											e.stopPropagation()
								            try {
									            setSaving(true)
												await onSave(mediaJobItem, editValue)
									            setEditing(false)
								            }
											catch(exc) {
												alert("Error saving: " + exc)
											}
											finally {
									            setSaving(false)
								            }
										}}
							>
								{
									saving
										? <CircularProgress size={18} />
										: <Check color="success"/>
								}
							</IconButton>
							<IconButton edge="end"
							            sx={{mr: 1}}
							            disabled={saving}
							            onClick={(e) => {
											e.stopPropagation()
											setEditing(false)
											setEditValue(mediaJobItem && mediaJobItem.content)
										}}
							>
								<Close color={saving ? "disabled" : "error"}/>
							</IconButton>
						</Box>
					}
				</Box>
			</Box>
			<Box style={{minWidth: '50px'}}>
				<AudioPreviewPlayer tts={editValue} model={mediaJob.tts_model} voiceId={mediaJob.voice_id} />
			</Box>
		</Box>
		{showDivider && <Divider/>}
	</Box>
	)
}

const orderBy = ['question', 'option-a', 'option-b', 'option-c', 'option-d', 'answer', 'explanation']
const findMediaJobItems = (topicOrQuestion, mediaJobItems) => {

	if (topicOrQuestion.topic_id) {
		// this is a question
		const q = topicOrQuestion
		const items = mediaJobItems && mediaJobItems.filter(i => i.question_id === q.id)
		// put in order of question, option-a, option-b, option-c, option-d, answer, explanation
		return items.sort((a, b) => {
			return orderBy.indexOf(a.job_type) - orderBy.indexOf(b.job_type)
		})
	}
	else {
		// this is a topic
		const t = topicOrQuestion
		return mediaJobItems.filter(i => i.question_id === null && i.topic_id === t.id)
	}

}

export default ContentReview
