import getSupabase            from "./supabase-utils"
import {DEBUG, logger, TRACE} from "./log-utils"

const log = logger("do-fetch", DEBUG)

const getApiBaseUrl = () => {
	return process.env.REACT_APP_API_URL
}

const getSupabaseSession = async () => {
	const supabase = getSupabase()
	const {data} = await supabase.auth.getSession()
	return data.session
}

/**
 *
 * @param relativeUrl
 * @param options
 * @returns {Promise<Response>}
 */
const doGet = async (relativeUrl, options) => {
	if(!options) options = {}
	options.method = "GET"
	return doFetch(relativeUrl, options)
}


/**
 *
 * @param relativeUrl
 * @param options
 * @returns {Promise<Response>}
 */
const doPost = async (relativeUrl, options) => {
	if(!options) options = {}
	options.method = "POST"
	return doFetch(relativeUrl, options)
}


/**
 *
 * @param relativeUrl
 * @param options
 * @returns {Promise<Response>}
 */
const doPut = async (relativeUrl, options) => {
	if(!options) options = {}
	options.method = "PUT"
	return doFetch(relativeUrl, options)
}


/**
 *
 * @param relativeUrl
 * @param options
 * @returns {Promise<Response>}
 */
const doDelete = async (relativeUrl, options) => {
	if(!options) options = {}
	options.method = "DELETE"
	return doFetch(relativeUrl, options)
}


/**
 *
 * @param relativeUrl
 * @param options
 * @param onData
 * @param onComplete
 * @returns {Promise<void>}
 */
const doStreamPost = async (relativeUrl, options, onData, onComplete) => {
	if(!options) options = {}
	options.method = "POST"
	return doStreamFetch(relativeUrl, options, onData, onComplete)
}

/**
 *
 * @param relativeUrl
 * @param options
 * @param onData
 * @param onComplete
 * @returns {Promise<void>}
 */
const doStreamFetch = async (relativeUrl, options, onData, onComplete) => {
	options = await defaultOptions(options)
	
	const baseUrl = getApiBaseUrl()
	const url = `${baseUrl}${relativeUrl}`
	const response = await fetch(url, options)

	if (!response.body) {
		throw new Error('ReadableStream not yet supported in this browser.')
	}

	const reader = response.body.getReader()
	let complete = ''
	
	const processStream = async () => {
		const streamData = await reader.read()
		while (!streamData.done) {
			const value = new TextDecoder('utf-8').decode(streamData.value)
			complete += value
			onData(value)
			return processStream()  // Recursively call itself to handle the next chunk
		}
	}

	await processStream()
	onComplete(complete)
}


/**
 *
 * @param relativeUrl everything after the https://domain
 * @param options Same options as you'd normally pass to fetch(), except:
 * 1. Content-Type header will default to "application/json"
 * 2. Authorization header will default to supabase.getSession().auth_token
 * @returns {Promise<Response>}
 */
const doFetch = async (relativeUrl, options) => {
	options = await defaultOptions(options)
	const url = getUrl(relativeUrl)

	// todo: I would change REACT_APP_CACHE_FETCH in .env.local and restarted and it would not update properly
	const doCache = false //process.env.REACT_APP_CACHE_FETCH && options.method === "GET"

	doCache
		? log.warn(`[DOCACHE=TRUE] ${url}`)
		: log.debug(`${url}`)

	// Check for cached data for GET requests
	if (doCache) {
		let json = localStorage.getItem(url)
		if (json) {
			log.trace({
				cachedFetch: true,
				url: url,
				json: json
			})
			return _mockResponse(json) // Mimic response object
		}
		else {
			// did not find it locally, so get get it
			let response = await fetch(url, options)
			json = await response.json()

			if(json) {
				// save it locally
				log.trace("WRITING RESPONSE TO CACHE ------------------------------------------------------")
				log.trace({json})
				json = JSON.stringify(json)
				log.trace(json)
				localStorage.setItem(url, json)
			}

			return _mockResponse(json)
		}
	}
	else {
		return await fetch(url, options)
	}
}

const getUrl = (relativeUrl) => {
	const baseUrl = getApiBaseUrl()
	return `${baseUrl}${relativeUrl}`
}

const _mockResponse = (json) => {
	log.trace(`_mockResponse(${json})`)
	return {
		ok: true,
			status: 200,
		json: async () => JSON.parse(json)
	}
}

const defaultOptions = async (options) => {
	// Default headers if not already set
	if(!options) options = {}
	if(!options.headers) options.headers = {}

	if(!options.headers["Content-Type"]) {
		options.headers["Content-Type"] = "application/json"
	}
	if(!options.headers["Authorization"]) {
		const session = await getSupabaseSession()
		if(session) {
			options.headers["Authorization"] = session.access_token
		}
	}
	return options
}

export { getUrl, doFetch, doGet, doPost, doPut, doDelete, doStreamFetch, doStreamPost }