import Grid                         from "@mui/material/Unstable_Grid2"
import {
	Alert, AlertTitle,
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
	useTheme
}                                   from "@mui/material"
import CircleIndicator              from "../CircleIndicator"
import {ChevronRight, Refresh}      from "@mui/icons-material"
import PrettyJson                   from "../PrettyJson"
import React, {useEffect, useState} from "react"
import {doGet, doPost}              from "../../util/do-fetch"
import {logger}                     from "../../util/log-utils"
import TopicsTree                   from "./TopicsTree"
import {debounce}                   from "lodash"
import {useNavigate}                from "react-router-dom"
import {fetchLLMModels}             from "../../util/admin"
import SelectCategory               from "./SelectCategory"

const log = logger("ProductGenerator", 1)

function ProductGenerator() {

	const theme = useTheme()
	const navigate = useNavigate()
	const [productName, setProductName] = useState("")
	const [category, setCategory] = useState("")
	const [productShortName, setProductShortName] = useState("")
	const [productToken, setProductToken] = useState("")
	const [productTokenErr, setProductTokenErr] = useState("")
	const [prompt, setPrompt] = useState("")
	const [model, setModel] = useState("")
	const [topicsJson, setTopicsJson] = useState("")
	const [topicsJsonErr, setTopicsJsonErr] = useState(null)
	const [topics, setTopics] = useState(null)
	const [generating, setGenerating] = useState(false)
	const [fetching, setFetching] = useState(false)
	const [saving, setSaving] = useState(false)
	const [product, setProduct] = useState(null) // set when product is saved
	const [productError, setProductError] = useState("")
	const [models, setModels] = useState("")

	useEffect(() => {
		const fn = async () => {
			const llmModels = await fetchLLMModels()
			setModels(llmModels)
		}
		fn()
	}, [])

	useEffect(() => {
		debouncedFetchProductTopicsPrompt()
		return () => debouncedFetchProductTopicsPrompt.cancel()
	}, [productName])

	useEffect(() => {
		const validateToken = debounce(async (token) => {

			if (!token) {
				return // we will validate the "required" condition when they click Save
			}

			// Convert token to lowercase
			const lowercaseToken = token.toLowerCase()

			// Check if token contains invalid characters
			const invalidCharacters = /[^\w-]/
			if (invalidCharacters.test(token)) {
				setProductTokenErr("Token contains invalid characters")
				return
			}

			// Call server to check if token is unique
			const isTokenUnique = await checkTokenUniqueness(token)
			if (!isTokenUnique) {
				setProductTokenErr("Token is not unique")
				return
			}

			// Token is valid
			setProductTokenErr("")
			setProductToken(lowercaseToken)

		}, 300) // Debounce time in milliseconds

		// Call validateToken when productToken changes
		validateToken(productToken)

		// Cleanup debounce function
		return () => {
			validateToken.cancel()
		}
	}, [productToken])

	useEffect(() => {
		if (!topicsJson) {
			setTopics(null)
			setTopicsJsonErr(null)
			return
		}

		try {
			const ary = JSON.parse(topicsJson)
			if (!Array.isArray(ary)) throw new Error("JSON must start with [ and end with ]")
			if (ary.length === 0) throw new Error("JSON Topics are empty")

			const validatedTopics = _validateAndAddDefaults(ary, setTopicsJsonErr)
			if (validatedTopics) {
				setTopics(validatedTopics)
				setTopicsJsonErr(null)
			}
		}
		catch (error) {
			console.error(error)
			setTopics(topics)
			setTopicsJsonErr(error.message || String(error))
		}
	}, [topicsJson])

	const _fetchProductTopicsPrompt = async () => {
		setFetching(true)
		try {
			const response = await doGet(`/admin/products/product-topics-prompt?productName=${productName}`)
			const data = await response.json()
			setPrompt(data && data.prompt)
		}
		catch (err) {
			log.error(err)
		}
		finally {
			setFetching(false)
		}
	}
	const debouncedFetchProductTopicsPrompt = debounce(_fetchProductTopicsPrompt, 500)

	// called from within useEffect
	const _validateAndAddDefaults = (topics, setTopicsJsonErrFn) => {
		console.log(`_validateAndAddDefaults`)
		let firstLeafDepth = null

		const validate = (topics, depth = 0, parentName = '') => {
			topics.forEach((topic, index) => {
				if (typeof topic.name !== 'string') {
					throw new Error(`Topic "${parentName}" (${index + 1}) does not have a valid name.`)
				}

				if (Array.isArray(topic.topics) && topic.topics.length > 0) {
					validate(topic.topics, depth + 1, topic.name)
				}
				else {
					if (topic.target_question_count === undefined) {
						topic.target_question_count = 15
					}
					else if (topic.target_question_count < 1 || topic.target_question_count > 100) {
						throw new Error(`Topic "${topic.name}" must have a target_question_count between 1 and 100.`)
					}

					if (firstLeafDepth === null) {
						firstLeafDepth = depth
					}
					else if (depth !== firstLeafDepth) {
						throw new Error(`Inconsistent depth: Leaf node "${topic.name}" at depth ${depth} does not match the first leaf node's depth of ${firstLeafDepth}.`)
					}
				}
			})
		}

		try {
			validate(topics)
		}
		catch (error) {
			setTopicsJsonErrFn(error.message || String(error))
			return null // Indicates validation failed
		}

		return topics // Validation succeeded
	}

	// Function to simulate server call for checking token uniqueness
	const checkTokenUniqueness = async (token) => {
		try {
			const response = await doGet(`/admin/products/exists/${token}`)
			const data = await response.json()
			return data && data.exists === false
		}
		catch (err) {
			return false
		}
	}

	const handleGenerateTopicsJson = async () => {
		setTopics(null)
		setTopicsJson("")
		setGenerating(true)
		try {
			if (!productName) {
				alert("Please enter value for product name.")
				return
			}
			if (!model) {
				alert("Please select a model.")
				return
			}
			const options = {
				body: JSON.stringify({productName, model})
			}
			const response = await doPost(`/admin/products/generate-topics-json`, options)
			const data = await response.json()
			setTopicsJson(JSON.stringify(data, null, 2))
		}
		catch (err) {
			log.error(err)
		}
		finally {
			setGenerating(false)
		}
	}

	const generateTokenFromProductName = (onlyIfEmpty) => {
		if (onlyIfEmpty && productToken && productToken.length > 0) return
		const token = productName.trim()
			.toLowerCase()
			.replace(/[^a-z0-9]+/g, '-')
		setProductToken(token)
	}

	const handleSaveProduct = async () => {
		setSaving(true)
		try {
			if (!productName) {
				alert("Please enter value for product name.")
				return
			}
			if (!category) {
				alert("Please select a category.")
				return
			}
			if (!productShortName) {
				alert("Please enter value for product short name.")
				return
			}
			if (!productToken) {
				alert("Please enter value for product token.")
				return
			}
			if (!model) {
				alert("Please select a model.")
				return
			}
			if (!topics || topics.length === 0) {
				alert("Please create topics.")
				return
			}

			const body = {
				name: productName,
				shortName: productShortName,
				token: productToken,
				llmModel: model,
				topics,
				category
			}
			const options = {
				body: JSON.stringify(body)
			}

			const response = await doPost(`/admin/products`, options)
			const data = await response.json()

			if (data.name === productName) {
				setProduct(data)
			}
			else {
				setProductError(data.error)
			}

		}
		catch (err) {
			log.error(err)
		}
		finally {
			setSaving(false)
		}
	}

	let sampleJsonData = [
		{
			"name": "Real Property Law",
			"topics": [
				{
					"name": "Ownership and Transfer",
					"target_question_count": 8
				},
				{
					"name": "Encumbrances and Liens",
					"target_question_count": 5
				},
				{
					"name": "Land Use Regulations and Controls",
					"target_question_count": 4
				}
			]
		},
		{
			"name": "Property Valuation and Financial Analysis",
			"topics": [
				{
					"name": "Appraisal and Market Analysis",
					"target_question_count": 7
				},
				{
					"name": "Financing and Economics",
					"target_question_count": 6
				}
			]
		}
	]

	sampleJsonData = [
		{
			"name": "Main Topic Name A",
			"topics": [
				{
					"name": "Secondary Topic Name",
					"topics": [
						{
							"name": "Prep Topic 1",
							"target_question_count": 15
						},
						{
							"name": "Prep Topic 2",
							"target_question_count": 15
						},
						{
							"name": "Prep Topic 3",
							"target_question_count": 15
						},
					]
				}
			]
		}
	]

	if (product) {
		return <Grid xs={4} xsOffset={4} container>
			<Alert severity="success">
				<AlertTitle>Success</AlertTitle>
				<h3>
					Congratulations! {product.name} was created successfully.
				</h3>
				<Box sx={{pt: 1}}>
					<Button size="small"
					        variant="outlined"
					        color="success"
					        onClick={() => navigate('/admin/products/' + product.id)}
					>
						Continue <ChevronRight/>
					</Button>
				</Box>
			</Alert>
		</Grid>
	}

	return <Grid xs={12} container>
		<Grid xs={12} sx={{mb: 2}}>
			<Box display="flex" flexDirection="row" justifyContent="space-between" sx={{p: 2}}>
				<Typography variant="h4" component="h1" sx={{lineHeight: 1.2}}>
					Create PrepKit Product
				</Typography>
				<Button
					size="large"
					variant="contained"
					onClick={handleSaveProduct}
					disabled={saving}
				>
					Save Product
					{saving && <CircularProgress size={18}/>}
				</Button>
			</Box>
			<Divider/>
		</Grid>
		<Grid xs={4} sx={{px: 3}}>
			<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
				<CircleIndicator character={1} size={32} backgroundColor={theme.palette.score.success}/>
				<h3 style={{marginLeft: "10px"}}>Configure Product and create Topics</h3>
			</Box>

			<div style={{
				border: "1px solid " + theme.palette.divider,
				padding: "25px",
				borderRadius: "5px",
				marginBottom: "25px"
			}}>
				<div><b>Product</b></div>
				<TextField
					value={productName}
					onChange={(e) => setProductName(e.target.value)}
					onBlur={() => generateTokenFromProductName(true)}
					label="Name"
					placeholder="The product name the customer sees"
					fullWidth
					sx={{mt: 2}}
				/>

				<SelectCategory sx={{mt: 1}} category={category} onChangeCategory={(c)=>setCategory(c.id)}/>

				<TextField
					value={productShortName}
					onChange={(e) => setProductShortName(e.target.value)}
					label="Short Name"
					placeholder="The name on the tab in the PrepKit"
					fullWidth
					sx={{mt: 2}}
				/>

				<TextField
					value={productToken}
					onChange={(e) => setProductToken(e.target.value)}
					error={Boolean(productTokenErr && productTokenErr.length > 0)}
					helperText={productTokenErr}
					label="Unique Token"
					placeholder="The unique token used in the product URL"
					fullWidth
					sx={{mt: 2}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip title="Generate from Product Name">
									<IconButton onClick={() => generateTokenFromProductName(false)}>
										<Refresh/>
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
				<Select
					value={model}
					onChange={e => setModel(e.target.value)}
					displayEmpty
					fullWidth
					placeholder={models ? 'Select AI Model' : 'Loading AI Models...'}
					sx={{mt: 2}}
				>

					<MenuItem>
						<Typography component="span" style={{opacity: 0.5}} >
							Select an AI Model
						</Typography>
					</MenuItem>
					{
						models
							? models.map(m => {
								return  <MenuItem key={m.name} value={m.name}>
									<Typography component="span" >
										{m.name}
									</Typography>
									<Typography component="span" sx={{ fontSize: 'small', ml: 2 }}>
										{' '}
										Input: ${Number(m.input_cost*1000).toFixed(4)},
										Output: ${Number(m.input_cost*1000).toFixed(4)} per 1000 {m.unit}s
									</Typography>
								</MenuItem>
							})
							:  (<MenuItem value="" disabled>Loading AI Models...</MenuItem>)
					}
				</Select>
			</div>

			<div style={{
				border: "1px solid " + theme.palette.divider,
				padding: "25px",
				borderRadius: "5px",
				marginBottom: "25px"
			}}>
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<div><b>Topics</b></div>
					<Button
						sx={{mt: 1}}
						onClick={handleGenerateTopicsJson}
						disabled={generating}
						variant="contained"
					>
						Generate Topics {generating ? <CircularProgress sx={{ml: 1}} size={18}/> : <ChevronRight/>}
					</Button>
				</Box>
				{
					prompt &&
					<>
						<Box sx={{
							mt: 2, p: 2, borderRadius: "5px",
							border: "1px solid " + theme.palette.divider,
						}}>
							<div><b>AI Prompt to Generate Topics JSON</b></div>
							<code style={{
								color: theme.palette.secondary.main,
								fontSize: "12px"
							}}>
								{prompt}
							</code>
						</Box>
					</>
				}
			</div>
		</Grid>

		<Grid xs={4} sx={{px: 1}}>
			<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
				<CircleIndicator character={2} size={32} backgroundColor={theme.palette.score.success}/>
				<h3 style={{marginLeft: "10px"}}>Edit Topics here</h3>
			</Box>
			<TextField
				value={topicsJson}
				onChange={(e) => setTopicsJson(e.target.value)}
				label="Paste JSON Here"
				multiline
				minRows={4}
				maxRows={60}
				fullWidth
				InputProps={{
					style: {
						fontFamily: "monospace",
						height: 'auto',
						fontSize: '12px'
					}
				}}
			/>

			<p>It should look like this...</p>
			<div style={{marginTop: "20px", border: "1px solid " + theme.palette.divider, padding: "10px"}}>
				<PrettyJson data={sampleJsonData} fontSize={12}/>
			</div>


		</Grid>
		<Grid xs={4} sx={{px: 3}}>
			<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
				<CircleIndicator character={3} size={32} backgroundColor={theme.palette.score.success}/>
				<h3 style={{marginLeft: "10px"}}>Review and save</h3>
			</Box>
			{topicsJsonErr
				? <Alert severity="error">
					<AlertTitle>JSON Error</AlertTitle>
					{topicsJsonErr}
				</Alert>
				: <div style={{border: "1px solid " + theme.palette.divider}}>
					<div>
						<Typography variant="h4" component="h1" sx={{m: 2, mb: 0, lineHeight: 1.2}}>
							{productName}
						</Typography>
						<Typography variant="subtitle1" component="div" sx={{lineHeight: 1.2, ml: 2.5, mb: 2}}>
							<small>{productToken}</small>
						</Typography>
					</div>
					<Divider/>
					<TopicsTree topics={topics}/>
				</div>
			}
		</Grid>
	</Grid>
}

export default ProductGenerator
