import {Button, useTheme} from "@mui/material"
import React, {useState}  from "react"
import useMediaQuery      from "@mui/material/useMediaQuery"


const MiniQuestionActions = ({show, actions, onAction}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()

	return <div style={{
			transition: 'height 200ms',
			height: show ? (actions.length * 55) + 'px' : 0,
			width: '100%',
			maxWidth: '800px',
			margin: '0 auto',
			flex: 1,
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.translucent,
			backdropFilter: "blur(10px)",
			WebkitBackdropFilter: "blur(10px)",
			border: '0px solid red',
			borderTop: isSmallScreen && show ? `1px solid ${theme.palette.divider}` : `none`
		}}
	>
		{
			actions && actions.length && actions.map((a,i) => {
				return <Button
					key={'action-' + a.label}
					onClick={() => onAction(a)}
					fullWidth={true}
					size="large"
					sx={{
						textTransform: 'none',
						justifyContent: "flex-start",
						border: "0px solid red",
						py: 1.5,
						pl: 2
					}}
				>
					{a.label}
				</Button>
			})
		}
	</div>

}

export default MiniQuestionActions

