import React, {useEffect, useState}                                  from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, useTheme,} from '@mui/material'
import Grid                                                          from '@mui/material/Unstable_Grid2' // Grid version 2
import {logger}                                                      from "../../util/log-utils"
import {microCurrency}                                               from "../../util/utils"
import {fmtModels, fmtPath}                                          from "../../util/prepkit-utils"
import {useOutletContext}                                            from "react-router-dom"


const log = logger("ProductCost", 1)

function ProductCost() {
	const { product } = useOutletContext()
	const theme = useTheme()
	const [generatingCost, setGeneratingCost] = useState(null)

	useEffect(() => {
		// update costs
		if(product && product.costs) {
			const totalCost = product.costs.reduce((acc, cv) => {
				return acc + cv.grand_total_cost;
			}, 0);
			setGeneratingCost(totalCost)
		}
		else {
			setGeneratingCost(null)
		}
	}, [product])


	return (
		<Grid xs={12} container>
			<Grid xs={12}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{fontWeight: "bold"}} >Topic</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right"># Questions</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">AI Proc Duration</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">Prompt Tokens</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">Completion Tokens</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">Total Tokens</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">Total Cost</TableCell>
							<TableCell style={{fontWeight: "bold"}} align="right">Model</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							product.costs && product.costs.map(tc => {
								return <TableRow>
									<TableCell>
										{tc.name}<br/>
										<small style={{color: theme.palette.text.disabled}}>
											{fmtPath(tc.path, true, true)}
										</small>
									</TableCell>
									<TableCell align="right">
										{tc.total_question_count}
									</TableCell>
									<TableCell align="right">
										{tc.total_duration_millis}ms
									</TableCell>
									<TableCell align="right">
										{tc.total_prompt_tokens}
									</TableCell>
									<TableCell align="right">
										{tc.total_completion_tokens}
									</TableCell>
									<TableCell align="right">
										{tc.total_total_tokens}
									</TableCell>
									<TableCell align="right">
										{microCurrency(tc.grand_total_cost)}
									</TableCell>
									<TableCell align="right">
										{fmtModels(tc.models)}
									</TableCell>
								</TableRow>
							})
						}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	)
}

export default ProductCost
