import React, {useState, useEffect, useMemo} from 'react'
import {Box, Tooltip, Typography, useTheme} from '@mui/material'
import {MoreHoriz} from "@mui/icons-material"
import {getColorForScore} from "../../util/utils"
import {fmtPct} from "../../util/prepkit-utils"

const easeOut = (t) => 1 - Math.pow(1 - t, 3)

const Score = ({variant, topic, shadow = 2, size, animate = false, ...props}) => {

	const theme = useTheme()
	const score = topic ? topic.percentCorrect : 0
	const [animatedScore, setAnimatedScore] = useState(0)
	const backgroundColor1 = getColorForScore(Math.min(score + (score * 0.1), 1))
	const backgroundColor2 = getColorForScore(Math.max(score - (score * 0.1), 0.5))
	const color = theme.palette.background.paper
	const textColor = getColorForScore(score)
	const contained = variant === "contained"
	const outlined = variant === "outlined"
	const inline = variant === "inline"
	const isComplete = useMemo(() => {
		return topic && topic.percentAnswered === 1
	}, [topic])

	useEffect(() => {
		if (animate) {
			let start = 0
			const duration = 3500 // x seconds duration for the animation
			const startTime = performance.now()

			const animateScore = () => {
				const currentTime = performance.now()
				const elapsed = currentTime - startTime
				const progress = Math.min(elapsed / duration, 1) // Keep it between 0 and 1

				const easedProgress = easeOut(progress)
				setAnimatedScore(easedProgress * score)

				if (progress < 1) {
					requestAnimationFrame(animateScore)
				}
			}

			requestAnimationFrame(animateScore)
		} else {
			setAnimatedScore(score)
		}
	}, [animate, score])

	if (isNaN(score)) return null

	if (inline) {

		// inline

		return <Tooltip
			title={<>
				You scored {fmtPct(score)}<br/>
				{topic.questionCount} questions<br/>
				{topic.correctCount} correct, {topic.incorrectCount} incorrect
			</>}
		>
			<Typography variant={"body2"}
			            sx={[
				            {
					            color: isComplete
						            ? `white`
						            : theme.palette.text.primary,
					            background: isComplete
						            ? `linear-gradient(to bottom left, ${backgroundColor1}, ${backgroundColor2})`
						            : topic.answeredCount > 0
							            ? `linear-gradient(to bottom left, ${theme.palette.background.default}, ${theme.palette.divider})`
							            : `transparent`,
					            border: isComplete
						            ? `none`
						            : `0px solid ${theme.palette.divider}`,
					            padding: '3px 7px 1px 9px',
					            borderRadius: '15px',
				            },
				            props.sx || {}
			            ]}
			>
				{
					isComplete
						? fmtPct(animatedScore)
						: 'In Progress'
				}
			</Typography>
		</Tooltip>
	}
	else {

		// contained or outlined

		if (isComplete === 0) return null

		return isComplete < 1
			? <Tooltip title={"In progress"} placement={"top"}>
				<Box
					border={`1px solid ${theme.palette.divider}`}
					borderRadius={1}
					px={1}
				>
					<MoreHoriz sx={{color: theme.palette.divider}}/>
				</Box>
			</Tooltip>
			: <Box
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Box
					justifyContent={"center"}
					alignItems={"center"}
					display={"flex"}
					width={`${size * 2.25}px`}
					height={`${size * 2.25}px`}
					borderRadius={`${size / 1.5}px`}
					padding={`${size * 0.15}px`}
					boxShadow={shadow}
					sx={{
						background: contained
							? `linear-gradient(to bottom left, ${backgroundColor1}, ${backgroundColor2})`
							: 'transparent',
						borderWidth: 1,
						color: contained ? color : backgroundColor1,
						overflow: 'hidden', // This ensures the gradient doesn't spill outside the border radius
					}}
				>
					<Box
						justifyContent={"center"}
						alignItems={"center"}
						display={"flex"}
						width={`100%`}
						height={`100%`}
						borderRadius={`${(size / 1.5) - (size * 0.15)}px`}
						sx={{
							backgroundColor: outlined ? theme.palette.background.paper : "transparent"
						}}
					>
						<Typography sx={{
							color: contained
								? color
								: outlined
									? textColor
									: 'inherit',
							fontSize: size || 'inherit',
							fontWeight: 500,
							letterSpacing: `-${size * .05}px`,
							pl: `${size * .15}px`,
							pt: `${size * .1}px`
						}}>
							{Math.round(animatedScore * 100)}<sup style={{fontSize: ".5em"}}>%</sup>
						</Typography>
					</Box>
				</Box>
			</Box>
	}
}

export default Score
