import React, {useEffect, useState} from 'react'
import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Container, Divider,
	FormControlLabel,
	IconButton, Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip, Typography,
	useTheme,
} from '@mui/material'
import {ArrowBack, Refresh, VideoCameraBack} from "@mui/icons-material"
import {useNavigate}                         from "react-router-dom"
import {doGet, doPost}       from "../../util/do-fetch"
import {logger}              from "../../util/log-utils"
import HoverTableRow         from "../HoverTableRow"
import VideoJobDialog        from "./VideoJobDialog"
import {fmtDttm}             from "../../util/prepkit-utils"
import {getSupabase}         from "../../util/supabase-utils"
import {fetchSignedMediaUrl} from "../../util/admin"

const log = logger("Videos", 1)

const statusColors = {
	completed: "success",
	processing: "info",
	queued: "warning",
	failed: "error",
}

function Videos() {
	const navigate = useNavigate()
	const theme = useTheme()
	const [videoJobs, setVideoJobs] = useState([])
	const [filtered, setFiltered] = useState([])
	const [showVideoDialog, setShowVideoDialog] = useState(false)
	const [showQueued, setShowQueued] = useState(true)
	const [showProcessing, setShowProcessing] = useState(true)
	const [showCompleted, setShowCompleted] = useState(true)
	const [showFailed, setShowFailed] = useState(true)

	useEffect(() => {
		fetchVideoJobs()
		const subscription = subscribeToVideoJobsChannel()
		return () => {
			console.log(`unsubscribing from video-jobs-channel`)
			subscription && subscription.unsubscribe()
		}
	}, [])

	useEffect(() => {
		if(!videoJobs || videoJobs.length === 0) {
			setFiltered([])
		}
		else {
			setFiltered(videoJobs.filter(vj =>
				(vj.status === 'queued' && showQueued) ||
				(vj.status === 'processing' && showProcessing) ||
				(vj.status === 'completed' && showCompleted) ||
				(vj.status === 'failed' && showFailed)
			))
		}

	}, [videoJobs, showQueued, showProcessing, showCompleted, showFailed])

	const fetchVideoJobs = async () => {
		try {
			const response = await doGet(`/admin/videos/jobs`)
			const data = await response.json()
			if (Array.isArray(data)) {
				data.sort((a, b) => b.id - a.id)
			}
			setVideoJobs(data)
		}
		catch (err) {
			log.error(err)
		}
	}

	const subscribeToVideoJobsChannel = (job) => {
		const channelName = `channel-video-jobs`
		console.log(`subscribing to ${channelName}`)
		const supabase = getSupabase()
		const ch = supabase
			.channel(channelName)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "video_job"
				},
				(event) => {
					log.debug(`UPDATE video_job ${event.new.id}`)
					if (event.eventType === "UPDATE") {
						setVideoJobs(prev => {
							return prev.map(vj => {
								if (vj.id === event.new.id) {
									return event.new
								}
								else {
									return vj
								}
							})
						})
					}
					else if (event.eventType === "INSERT") {
						setVideoJobs(prev => {
							// sort by id descending (newest at top)
							return [event.new, ...prev].sort((a, b) => b.id - a.id)
						})
					}
				}
			)
			.subscribe()
		return ch
	}

	const handleSaved = (newVideoJob) => {
		// new video job will be added automatically via realtime subscription
		setShowVideoDialog(false)
	}

	const handleRetry = async (videoJob) => {
		await doPost(`/admin/videos/jobs/${videoJob.id}/retry`)
	}

	return (
		<Container maxWidth="xl">
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack/>
					</IconButton>
					<Typography variant={"h5"}>
						Videos
					</Typography>
				</Box>
				<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">

					<FormControlLabel label="Queued"
					                  control={
										  <Checkbox checked={showQueued}
										            onClick={() => setShowQueued(!showQueued)}
										  />
									  }
					/>
					<FormControlLabel label="Processing"
					                  control={
						                  <Checkbox checked={showProcessing}
						                            onClick={() => setShowProcessing(!showProcessing)}
						                  />
					                  }
					/>
					<FormControlLabel label="Completed"
					                  control={
										  <Checkbox checked={showCompleted}
										            onClick={() => setShowCompleted(!showCompleted)}
										  />
									  }
					/>
					<FormControlLabel label="Failed"
					                  control={
										  <Checkbox checked={showFailed}
										            onClick={() => setShowFailed(!showFailed)}
										  />
									  }
					/>

					<Button variant={"contained"}
					        onClick={() => {
						        setShowVideoDialog(true)
					        }}
					>
						Create Video
					</Button>
				</Box>

				{
					// conditionally render component so state is reset after close/re-open
					showVideoDialog &&
					<VideoJobDialog
						open={showVideoDialog}
						onCancel={() => setShowVideoDialog(false)}
						onSaved={handleSaved}
					/>
				}
			</Stack>
			<Divider sx={{mt: 2}}/>
			<TableContainer style={{ borderRadius: '5px', marginBottom: '125px'}}>
				<Table sx={{minWidth: 650, backgroundColor: theme.palette.background.paper}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{fontWeight: "bold"}} component="th">Title</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th" align="center">Status</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th">Started</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th">Completed</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th">Key</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th" align="center">Rescued</TableCell>
							<TableCell sx={{fontWeight: "bold"}} component="th">Message</TableCell>
							<TableCell component="th"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filtered && filtered.map((row) => {
							return <VideoJobTableRow
								key={row.id}
								videoJob={row}
								onRetry={handleRetry}
							/>
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	)
}

function VideoJobTableRow({videoJob, onRetry}) {

	const theme = useTheme()
	const navigate = useNavigate()
	const [working, setWorking] = useState(false)

	if (!videoJob) return null

	const downloadVideoJobFile = async () => {
		const url = `/admin/videos/jobs/${videoJob.id}/video-url/${videoJob.file_name}`
		const response = await doGet(url)
		const signedUrl = await response.text()
		const link = document.createElement('a')
		link.href = signedUrl
		link.download = videoJob.file_name
		link.target = "_video-job-download"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return <HoverTableRow
		key={videoJob.id}
		onClick={() => navigate(`/admin/videos/jobs/${videoJob.id}`)}
		sx={{
			'&:last-child td, &:last-child th': {border: 0},
			cursor: 'pointer',
		}}
	>
		<TableCell component="th" scope="row">
			<b>{videoJob.title}</b>
			{
				videoJob.process_id && <div>
					<small>{videoJob.process_id}</small>
				</div>
			}
		</TableCell>
		<TableCell align="center">
			<Chip color={statusColors[videoJob.status]} label={videoJob.status}/>
		</TableCell>
		<TableCell>
			{fmtDttm(videoJob.started_at)}
		</TableCell>
		<TableCell>
			{
				videoJob.failed_at
					? <Box sx={{color: theme.palette.error.main}}>Failed {fmtDttm(videoJob.failed_at)}</Box>
					: fmtDttm(videoJob.completed_at)
			}
		</TableCell>
		<TableCell>
			{
				videoJob.status === 'completed'
					? <Link onClick={(event) => {
								event.stopPropagation()
								event.preventDefault()
						        downloadVideoJobFile()
					        }}
					>
						{videoJob.file_name}
					</Link>
					: videoJob.file_name
			}
		</TableCell>
		<TableCell align="center">
			{videoJob.rescue_count}
		</TableCell>
		<TableCell>
			{videoJob.message}
		</TableCell>
		<TableCell>
			<Box display="flex" justifyContent="flex-end" alignItems="center">
				{
					(working || videoJob.status === "processing")
					&& <CircularProgress size={18}/>
				}
				{
					videoJob.status !== "queued"
					&& <Tooltip title="Regenerate video">
						<IconButton
							disabled={working}
							onClick={async (event) => {
								event.stopPropagation()
								event.preventDefault()
								setWorking(true)
								await onRetry(videoJob)
								setWorking(false)
							}}
						>
							<Refresh/>
						</IconButton>
					</Tooltip>
				}
			</Box>

		</TableCell>

	</HoverTableRow>
}

export default Videos
