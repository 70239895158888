import React, {useEffect, useRef, useState} from 'react'
import {
	Box,
	Button, ButtonGroup, Chip,
	CircularProgress,
	Container, Divider,
	Fab, IconButton,
	ListItemButton,
	Step,
	StepIcon,
	StepLabel,
	Stepper, Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import Grid                                 from '@mui/material/Unstable_Grid2' // Grid version 2
import {logger}                             from "../../util/log-utils"
import {
	useNavigate,
	useOutletContext,
	useParams
}                                           from "react-router-dom"
import {
	doGet,
	doPost,
	doPut
}                                           from "../../util/do-fetch"
import {
	CheckCircle, ChevronLeft,
	ChevronRight,
	Circle, Close,
	Edit,
	Error, FastForward,
	MenuOpen,
	Pause,
	PlayArrow,
	Replay, SkipNext
} from "@mui/icons-material"
import MediaJobProcessing                   from "./MediaJobProcessing"
import {
	fmtCal,
	fmtDttm
}                                           from "../../util/prepkit-utils"
import {
	ASSET_REVIEW,
	COMPLETED,
	CONTENT_REVIEW,
	MediaJobStatus,
	RENDERING_ASSETS,
	RENDERING_CLIPS,
}                          from "../../util/media-job-status"
import {Alert, AlertTitle} from "@mui/material"
import ContentReview       from "./ContentReview"
import {getSupabase}       from "../../util/supabase-utils"
import ContentReviewTable  from "./ContentReviewTable"

const log = logger("ProductMedia", 1)

function ProductMedia() {
	const theme = useTheme()
	const navigate = useNavigate()
	const {id} = useParams()
	const {product} = useOutletContext()

	const [mediaJob, setMediaJob] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showAddDialog, setShowAddDialog] = useState(false)
	const [showDrawer, setShowDrawer] = useState(false)
	const [working, setWorking] = useState(false)
	const [updatedMediaJobItems, setUpdatedMediaJobItems] = useState([])
	const unsubscribeRef = useRef(null);

	useEffect(() => {
		if (id) {
			setShowDrawer(false)
			fetchMediaJobAndSubscribeToUpdates(id)
		}
		else {
			setMediaJob(null)
			setShowDrawer(true)
		}

		return () => {
			if (unsubscribeRef.current) {
				unsubscribeRef.current()
				unsubscribeRef.current = null
			}
		}
	}, [id])

	const fetchMediaJobAndSubscribeToUpdates = async (mediaJobId) => {

		try {
			const response = await doGet(`/admin/products/${product.id}/media-jobs/${mediaJobId}`)
			const data = await response.json()

			setMediaJob(data)
			const mediaJobSubscription = subscribeToMediaJobChannel(data)
			const mediaJobItemsSubscription = subscribeToMediaJobItemChannel(data)

			unsubscribeRef.current = () => {
				console.log(`unsubscribing from channels`)
				mediaJobSubscription && mediaJobSubscription.unsubscribe()
				mediaJobItemsSubscription && mediaJobItemsSubscription.unsubscribe()
			}
		}
		catch (err) {
			log.error(err)
		}
	}

	const subscribeToMediaJobChannel = (job) => {
		const channelName = `channel-media-job-${job.id}`
		console.log(`subscribing to ${channelName}`)
		const supabase = getSupabase()
		const ch = supabase
			.channel(channelName)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "media_job",
					filter: `id=eq.${job.id}`,
				},
				(event) => {
					log.debug(`UPDATE media_job ${event.new.id}`)
					if (event.eventType === "UPDATE") {

						setMediaJob(prev => {
							const updated = {
								status: event.new.status,
								process_id: event.new.process_id,
								started_at: event.new.started_at,
								message: event.new.message,
								paused_at: event.new.paused_at,
								failed_at: event.new.failed_at
							}
							return {...prev, ...updated}
						})
					}
				}
			)
			.subscribe()
		return ch
	}

	const subscribeToMediaJobItemChannel = (job) => {
		const channelName = `channel-media-job-item-${job.id}`
		console.log(`subscribing to ${channelName}`)
		const supabase = getSupabase()
		const ch = supabase
			.channel(channelName)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "media_job_item",
					filter: `media_job_id=eq.${job.id}`,
				},
				(event) => {
					log.debug(`UPDATE media_job_item ${event.new.status} ${event.new.file_name}`)
					if (event.eventType === "UPDATE") {
						setMediaJob(prev => {
							const items = [...prev.media_job_item].map(i => {
								if(i.id===event.new.id) {
									return event.new
								}
								else {
									return i
								}
							})

							const updated = {
								media_job_item: items
							}
							return {...prev, ...updated}
						})
					}
				}
			)
			.subscribe()
		return ch
	}

	const handleRenderAssets = async () => {
		_updateMediaJobStatus(RENDERING_ASSETS)
	}

	const handleRenderClip = async () => {
		_updateMediaJobStatus(RENDERING_CLIPS)
	}

	const _updateMediaJobStatus = async (toStatus) => {
		setWorking(true)
		try {
			const mediaJobStatus = MediaJobStatus[toStatus]
			if(!mediaJobStatus) throw new Error(`Unrecognized status ${toStatus}`)
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/${toStatus}`)
			if (response.ok) {
				setMediaJob(prev => {
					const updated = {...prev}
					updated.status = toStatus
					return updated
				})
			}
			else {
				throw new Error('Failed to update status. ' + response.statusMessage);
			}
		}
		catch(e) {
			alert(`Error transitioning to ${toStatus}: ${e}`)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handleRetryRenderingAssets = async () => {
		try {
			setWorking(true)
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/${RENDERING_ASSETS}/retry`)
			if (!response.ok) {
				throw new Error('Failed to retry mediaJob. ' + response.statusMessage);
			}
		}
		catch(e) {
			alert("Error retrying Rendering Assets: " + e)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handleRetryRenderingClips = async () => {
		try {
			setWorking(true)
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/${RENDERING_CLIPS}/retry`)
			if (!response.ok) {
				throw new Error('Failed to retry mediaJob. ' + response.statusMessage);
			}
		}
		catch(e) {
			alert("Error retrying Rendering Clips: " + e)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handlePause = async () => {
		try {
			setWorking(true)

			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/pause`)
			if (response.ok) {
				setMediaJob(prev => {
					const updated = {...prev}
					updated.paused_at = new Date()
					return updated
				})
			}
			else {
				throw new Error('Failed to pause mediaJob. ' + response.statusMessage);
			}
		}
		catch(e) {
			alert("Error attempting Pause: " + e)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handleUnpause = async () => {
		try {
			setWorking(true)
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/unpause`)
			if (response.ok) {
				setMediaJob(prev => {
					const updated = {...prev}
					updated.paused_at = null
					return updated
				})
			}
			else {
				throw new Error('Failed to unpause mediaJob. ' + response.statusMessage);
			}
		}
		catch(e) {
			alert("Error attempting Unpause: " + e)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handleReviewContent = async () => {
		try {
			setWorking(true)
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${id}/review-content`)
			if (!response.ok) {
				throw response.statusMessage
			}
		}
		catch(e) {
			alert("Error moving mediaJob to Content Review: " + e)
			throw e
		}
		finally {
			setWorking(false)
		}
	}

	const handleSaveMediaJobItem = async (mediaJobItem, editedValue) => {
		try {
			const body= { content: editedValue }

			const options = {
				body: JSON.stringify(body)
			}
			const response = await doPut(
				`/admin/products/${product.id}/media-jobs/${mediaJobItem.media_job_id}/item/${mediaJobItem.id}`,
				options
			)

			if(response.status === 200) {
				// update local state with content
				const updatedJobItems = [...mediaJob.media_job_item]
				const index = updatedJobItems.findIndex(i => i.id === mediaJobItem.id)
				updatedJobItems[index] = await response.json()

				setMediaJob(prev => {
					const updated = {...prev}
					updated.media_job_item = updatedJobItems
					return updated
				})
			}
			else {
				throw "Error updating product: " + response.statusMessage
			}
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleReRender = async (mediaJobItemIds) => {
		const options = {
			body: JSON.stringify({mediaJobItemIds})
		}
		setWorking(true)
		const response = await doPost(`/admin/products/${product.id}/media-jobs/${mediaJob.id}/${ASSET_REVIEW}/retry`, options)
		const data = await response.json()
		setMediaJob(data)
		setWorking(false)
	}

	const steps = [
		{
			status: MediaJobStatus[CONTENT_REVIEW],
			skip: mediaJob && mediaJob.skip_content_review
		},
		{
			status: MediaJobStatus[RENDERING_ASSETS]
		},
		{
			status: MediaJobStatus[ASSET_REVIEW],
			skip: mediaJob && mediaJob.skip_asset_review
		},
		{
			status: MediaJobStatus[RENDERING_CLIPS]
		},
		{
			status: MediaJobStatus[COMPLETED]
		},
	]

	// calculate step
	const activeStep = steps.findIndex(s => mediaJob && mediaJob.status === s.status.value)

	// determine workflow actions
	const actions = []
	const actionSize = "large" // "medium"
	const actionVariant = "outlined"
	let addReviewContent = false
	if(mediaJob){

		const reviewContent = <Button key="action-review"
		                              xcolor="secondary"
		                              onClick={handleReviewContent}
		                              disabled={working}
		                              startIcon={working
			                              ? <CircularProgress size={20} sx={{ color: theme.palette.secondary.contrastText }}/>
			                              : <ChevronLeft xcolor="secondary"/>
			                              }
		>
			Review Content
		</Button>

		if(mediaJob.paused_at && !mediaJob.failed_at) {
			addReviewContent = true
			actions.push(<Button key="action-resume"
			                     onClick={handleUnpause}
			                     disabled={working}
			                     startIcon={working
				                     ? <CircularProgress size={20} sx={{ color: theme.palette.warning.contrastText }}/>
				                     : <PlayArrow />}
			>
				Resume
			</Button>)
		}

		switch (mediaJob.status) {
			case CONTENT_REVIEW:
				actions.push(<Button key="action-render-assets"
				                     onClick={handleRenderAssets}
				                     endIcon={working
					                     ? <CircularProgress size={20} sx={{ color: theme.palette.primary.contrastText }}/>
					                     : <ChevronRight />}
				>
					Render Assets
				</Button>)
				break;

			case RENDERING_ASSETS:
				if(mediaJob.failed_at) {
					addReviewContent = true
					actions.push(<Button key="action-retry"
					                     xcolor="error"
					                     onClick={handleRetryRenderingAssets}
					                     startIcon={working
						                     ? <CircularProgress size={20} sx={{ color: theme.palette.error.contrastText }}/>
						                     : <Replay />}
					>
						Retry Failed
					</Button>)
				}
				else {
					actions.push(<Button key="action-pause"
					                     xcolor="warning"
					                     onClick={handlePause}
					                     startIcon={working
						                     ? <CircularProgress size={20} sx={{ color: theme.palette.warning.contrastText }}/>
						                     : <Pause />}
					>
						Pause
					</Button>)
				}
				break;

			case ASSET_REVIEW:
				addReviewContent = true
				actions.push(<Button key="action-render-assets-again"
				                     xcolor="primary"
				                     onClick={handleRenderClip}
				                     endIcon={working
					                     ? <CircularProgress size={20} sx={{ color: theme.palette.primary.contrastText }}/>
					                     : <ChevronRight />}
				>
					Render Clips
				</Button>)
				break;

			case RENDERING_CLIPS:
				if(mediaJob.failed_at) {
					addReviewContent = true
					actions.push(<Button key="action-retry"
					                     xcolor="error"
					                     onClick={handleRetryRenderingClips}
					                     startIcon={working
						                     ? <CircularProgress size={20} sx={{ color: theme.palette.error.contrastText }}/>
						                     : <Replay />}
					>
						Retry Failed
					</Button>)
				}
				else if(!mediaJob.paused_at) {
					actions.push(<Button key="action-pause"
					                     xcolor="warning"
					                     onClick={handlePause}
					                     startIcon={working
						                     ? <CircularProgress size={20} sx={{ color: theme.palette.warning.contrastText }}/>
						                     : <Pause />}
					>
						Pause
					</Button>)
				}
				break;

			default:
				addReviewContent = true
				break;
		}

		if(addReviewContent) {
			actions.unshift(reviewContent)
		}
	}

	actions.push(
		<Tooltip key="action-tooltip-close" title="Close and view Media Jobs">
			<Button
				size="large"
				onClick={()=>{
					navigate(`/admin/products/${product.id}/media`)
				}}
			>
				<Close color="error" />
			</Button>
		</Tooltip>
	)

	let comp = null
	if(mediaJob) {
		if(mediaJob.status === CONTENT_REVIEW) {
			comp = <Grid >
					<ContentReviewTable
						mediaJob={mediaJob}
						audioJobItems={mediaJob
							&& mediaJob.media_job_item
							&& mediaJob.media_job_item.filter(i => i.media_type === 'audio')}
						product={product}
						onSaveMediaJobItem={handleSaveMediaJobItem}
					/>
				</Grid>
		}
		else {
			comp = <MediaJobProcessing
				product={product}
				mediaJob={mediaJob}
				mediaJobItems={mediaJob && mediaJob.media_job_item}
				mode={mediaJob && mediaJob.status === CONTENT_REVIEW && mediaJob.status }
				onReRender={handleReRender}
			/>
		}
	}

	if(!mediaJob) return null

	return <>
		<Box sx={{
			position: 'fixed',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1100,
			backgroundColor: theme.palette.background.default,
			px: 5,
			overflowY: 'scroll'
		}}>
			<Box sx={{border: '0px solid red'}}>
				<Box display="flex"
				     flexDirection="column"
				     justifyContent="center"
				     alignItems="center"
				     sx={{
					     backgroundColor: theme.palette.background.paper,
						 border: '0px solid ' + theme.palette.divider,
					     borderTop: 'none',
					     px: 5, py: 3, mb: 0,
					     borderBottomLeftRadius: '10px',
					     borderBottomRightRadius: '10px',
					     boxShadow: 1
					 }}

				>
					<Box sx={{border: '0px solid red', width: '100%'}}
					     display="flex"
					     flex={1}
					     flexDirection="row"
					     justifyContent="space-between"
					     alignItems="center"
					>
						<Box display="flex"
						     flexDirection="column"
						     justifyContent="flex-start"
						     alignItems="flex-start"
						     sx={{width: '50%'}}
						>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Typography component="span" sx={{color: mediaJob.category.color}}>
									{mediaJob.category.name} / {mediaJob.product.name}
								</Typography>
								<h2 style={{padding: 0, margin: 0}}>
									Model {mediaJob.tts_model} / {mediaJob.voice_id}
								</h2>
								<span>{fmtCal(mediaJob.created_at)}</span>
							</Box>
						</Box>
						<Box display="flex"
						     flexDirection="row"
						     justifyContent="flex-end"
						     alignItems="center"
						     sx={{width: '50%'}}
						>
							<ButtonGroup size={actionSize} variant={actionVariant}>
								{actions}
							</ButtonGroup>
						</Box>
					</Box>
					<Box sx={{width: '100%', pt: 4}}>
						<Stepper activeStep={activeStep} alternativeLabel>
							{
								steps.map((step, i) => {
										const isActive = i === activeStep
										const customIcon = isActive && mediaJob.failed_at
											? <Error key={"step-err-" + i} fontSize="large" color="error"/>
											: i < activeStep
												? <CheckCircle fontSize="large" color="success" />
												: activeStep === steps.length - 1
													? <CheckCircle fontSize="large" color="success" />
													: null

										return <Step key={step.status.label + ":" + i}
										             active={isActive}
										             completed={activeStep > i}
										>
											<StepLabel
												StepIconComponent={(props) => (
													<StepIcon {...props}
													          icon={customIcon || props.icon}
													          sx={{ fontSize: '2rem' }}
													/>
												)}
												error={Boolean(isActive && mediaJob.failed_at)}
												sx={{
													'& .Mui-active': {
														color: isActive && mediaJob.failed_at
															? theme.palette.error.main
															: theme.palette.primary.main
													},
													'& .Mui-completed': {
														color: theme.palette.success.main,
													}
												}}
											>
												<div style={{fontSize: '14px'}}>
													{step.status.label}{isActive && mediaJob.failed_at && ' - FAILED'}
												</div>
												{
													step.skip &&
														<div>
															<Chip size="small" label="auto-skip" />
														</div>
												}
											</StepLabel>

										</Step>
									}
								)}
						</Stepper>
					</Box>
				</Box>
				<Box  sx={{py: 5, border: "0px solid red"}}>

					{
						mediaJob.message &&
						<Box sx={{p: 4}}>
							<Alert severity="error">
								<AlertTitle>Media Job Failed: {fmtDttm(mediaJob.failed_at)}</AlertTitle>
								{mediaJob.message}
							</Alert>
						</Box>
					}
				</Box>
				<Box>
					{comp || <div>
						{
							loading || id
								? <h2 style={{textAlign: "center"}}>Loading Media Job {id} <CircularProgress size={20}/></h2>
								: <h2>Select a Media Job</h2>
						}

					</div>
					}
				</Box>

			</Box>
		</Box>
	</>
}


function ProductMediaJob({product, mediaJob, selected, onClick}) {

	const theme = useTheme()

	// pending, review, processing, rendering, completed // failed?

	if(!mediaJob) return null

	return mediaJob && <ListItemButton
		onClick={() => {
			onClick(mediaJob)
		}}
		selected={selected}
		sx={{
			p: 3,
			borderBottom: '1px solid ' + theme.palette.divider,
		}}
	>
		<Box display="flex"
		     flex={1}
		     flexDirection="column"
		     justifyContent="flex-start"
		     alignItems="flex-start"
		>
			<Box display="flex"
			     flex={1}
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			     sx={{ width: '100%'}}
			>
					<Box sx={{mr: 6}} >
						<b>Model {mediaJob.tts_model} / {mediaJob.voice_id}</b>
					</Box>
					<small style={{color: theme.palette.info.main}}>
						{fmtCal(mediaJob.created_at)}
					</small>
			</Box>

			<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
				<small style={{color:mediaJob.category.color }}>
					{mediaJob.category.name}
				</small>
			</Box>

			<Box display="flex"
			     flex={1}
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			     sx={{ width: '100%'}}
			>
				<small style={{color: theme.palette.text.secondary, textTransform: 'capitalize'}}>
					{mediaJob.status.split('-').join(' ')}
				</small>
			</Box>
		</Box>

	</ListItemButton>
}

export default ProductMedia
