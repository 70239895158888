import React, {useMemo}  from 'react';
import {Box, Typography} from '@mui/material';
import {getContrastingTextColor} from "../util/utils"

const CustomColorInput = ({ color, label, setColor }) => {

	const labelColor = useMemo(()=>{
		const textColor = getContrastingTextColor(color)

		console.log(`${label}=${color}, text=${textColor}`)

		return textColor
	}, [color])


	return (
		<Box
			position="relative"
			width="100%"
			height="40px"
			sx={{
				display: 'inline-block',
				// Optional: Add any additional styling to the parent Box here
			}}
		>
			{/* Color Input */}
			<Box
				component="input"
				type="color"
				value={color}
				onChange={(e) => setColor(e.target.value)}
				sx={{
					width: '100%',
					height: '100%',
					border: 'none',
					padding: 0,
					margin: 0,
					boxShadow: 'none',
					background: 'none',
					cursor: 'pointer',
					// Remove default appearance
					appearance: 'none',
					WebkitAppearance: 'none',
					MozAppearance: 'none',
					// Target pseudo-elements to remove inner borders and padding
					'&::-webkit-color-swatch-wrapper': {
						padding: 0,
					},
					'&::-webkit-color-swatch': {
						border: 'none',
						borderRadius: 0,
					},
					'&::-moz-color-swatch': {
						border: 'none',
						borderRadius: 0,
					},
					'&:focus': {
						outline: 'none', // Remove focus outline
					},
				}}
			/>

			{/* Label Overlay */}
			{label && (
				<Typography
					variant="caption"
					sx={{
						position: 'absolute',
						bottom: 0,
						left: '5px',
						color: labelColor,
						fontSize: '0.75rem',
						pointerEvents: 'none', // Allow clicks to pass through to the input
					}}
				>
					{label}
				</Typography>
			)}
		</Box>
	)
}

export default CustomColorInput
