import React, {useState}                                     from 'react'
import {Box, Button, Container, Link, TextField, Typography} from '@mui/material'
import {getSupabase}                                         from "../../util/supabase-utils"
import {Alert, AlertTitle}                             from "@mui/material"
import {useParams}                                     from "react-router-dom"
import useFragment                                     from "../../hooks/useFragment"

const supabase = getSupabase()

const UpdatePassword = ({switchView}) => {

	const {error_description} = useFragment()
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState(error_description || '')
	const [success, setSuccess] = useState(false)

	const handleUpdatePassword = async () => {
		if (password !== confirmPassword) {
			setError('Passwords do not match')
			return
		}

		const {error} = await supabase.auth.updateUser({
			password
		})

		if (error) {
			setError(error.message)
		}
		else {
			setSuccess(true)
		}
	}

	return (
		<Container>
			<Box component="form" sx={{maxWidth: "600px", margin: "0 auto"}}>
				<Typography variant="h6">Update Password</Typography>
				{
					success
						? <Box>
							<Alert>
								<AlertTitle>Success</AlertTitle>
								Your password was updated successfully.
							</Alert>
						</Box>
						: <Box>
							<TextField
								label="New Password"
								type="password"
								fullWidth
								margin="normal"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<TextField
								label="Confirm Password"
								type="password"
								fullWidth
								margin="normal"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							{error && <Typography color="error">{error}</Typography>}
							<Button
								variant="contained"
								size="large"
								color="primary"
								fullWidth
								style={{marginTop: 16, marginBottom: 16}}
								onClick={handleUpdatePassword}
							>
								Update Password
							</Button>

							{
								switchView && <Box mt={2}>
									<Link sx={{cursor: 'pointer'}} fontSize="small" onClick={() => switchView('forgotPassword')}>&larr; Forgot Password</Link>
								</Box>
							}
						</Box>
				}
			</Box>
		</Container>
	)
}

export default UpdatePassword
