// LabeledBox.jsx
import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

/**
 * LabeledBox Component
 *
 * A generic wrapper component that adds a border and a floating label around any child component.
 *
 * Props:
 * - label (string): The text to display as the label.
 * - children (ReactNode): The child component to wrap.
 * - error (boolean): Whether the input is in an error state.
 * - helperText (string): Additional text to display below the input.
 * - ...props: Any additional props to pass to the root Box component.
 */
const LabeledBox = ({ label, children, backgroundColor="background.paper", error, helperText, ...props }) => {

	const isFocused = true
	const hasValue = true

	return (
		<Box {...props} position="relative" mb={helperText ? 2 : 0}>
			{/* Floating Label */}
			<Typography
				variant="caption"
				sx={{
					position: 'absolute',
					top: isFocused || hasValue ? '-8px' : '50%',
					left: '12px',
					transform: isFocused || hasValue ? 'translateY(0)' : 'translateY(-50%)',
					fontSize: isFocused || hasValue ? '0.75rem' : '0.875rem',
					color: error ? 'error.main' : 'text.secondary',
					backgroundColor: backgroundColor,
					px: 0.5,
					transition: 'all 0.2s ease-out',
					pointerEvents: 'none',
				}}
			>
				{label}
			</Typography>

			{/* Child Component */}
			<Box
				sx={{
					border: error ? '1px solid' : '1px solid',
					borderColor: error ? 'error.main' : 'grey.400',
					borderRadius: '4px',
					padding: '12px',
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					backgroundColor: backgroundColor,
					'&:hover': {
						borderColor: error ? 'error.dark' : 'grey.600',
					},
				}}
			>
				{children}
			</Box>

			{/* Helper Text */}
			{helperText && (
				<Typography variant="caption" color="error.main" mt={0.5}>
					{helperText}
				</Typography>
			)}
		</Box>
	)
}

export default LabeledBox
