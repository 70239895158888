import React, {useState} from 'react'
import {Container}       from "@mui/material"
import UpdatePassword    from "./UpdatePassword"
import UpdateEmail       from "./UpdateEmail"
import UpdateUserProfile from "./UpdateUserProfile"

function UserProfile({view}) {

	const [currentView, setCurrentView] = useState('')

	const switchView = (view) => {
		setCurrentView(view)
	}

	return (
		<Container style={{maxWidth: '800px'}}>
			{currentView === '' && <UpdateUserProfile switchView={switchView}/>}
			{currentView === 'password' && <UpdatePassword switchView={switchView}/>}
			{currentView === 'email' && <UpdateEmail switchView={switchView}/>}
		</Container>
	)
}

export default UserProfile
