import React, { useEffect, useState }           from 'react'
import {
	Box,
	IconButton,
	CircularProgress,
}                                     from '@mui/material'
import PlayArrowIcon                  from '@mui/icons-material/PlayArrow'
import PauseIcon                               from '@mui/icons-material/Pause'
import {PlayArrow, PlayArrowOutlined, Warning} from "@mui/icons-material"
import {fetchSignedMediaUrl}                   from "../../util/admin"

const AudioPreviewPlayer = ({ tts, model, voiceId }) => {
	const [isPlaying, setIsPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const [audioUrl, setAudioUrl] = useState(null)
	const [render, setRender] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const audioRef = React.useRef(null)

	useEffect(() => {
		setRender(false)
		setError(false)
		if(!tts) {
			setAudioUrl(null)
		}
		else {

			const ttsEnc = encodeURIComponent(tts)
			setAudioUrl(`${process.env.REACT_APP_API_URL}/admin/audio-preview/${model}/${voiceId}?tts=${ttsEnc}`)
		}

	}, [tts])

	useEffect(() => {
		const updateProgress = () => {
			if (audioRef.current) {
				const currentTime = audioRef.current.currentTime
				const duration = audioRef.current.duration
				const progressPercentage = (currentTime / duration) * 100
				setProgress(progressPercentage)
			}
		}

		if (isPlaying) {
			const intervalId = setInterval(updateProgress, 100)
			return () => clearInterval(intervalId)
		}
	}, [isPlaying])

	const togglePlay = async (event) => {
		event.stopPropagation()
		if(!render) {
			// first time user clicked play, set flag to render <audio> elem which will load the audioUrl
			// which will then autoplay
			setRender(true)
			setIsPlaying(true)
		}
		else {
			if (isPlaying) {
				audioRef.current.pause()
			}
			else {
				audioRef.current.play()
			}
			setIsPlaying(!isPlaying)
		}
	}

	return (
		<Box display="flex" alignItems="center">
			{ render &&
				<audio ref={audioRef}
				       src={audioUrl}
				       onLoadStart={() => {
						   setLoading(true)
					   }}
				       onAbort={() => {
							setError(true)
					   }}
				       onCanPlay={(event) => {
					       setLoading(false)
					       event.target.play()
					   }}
				       onEnded={() => {
					            console.log("onEnded")
								setIsPlaying(false)
								setProgress(100)
								setTimeout(() => {
									setProgress(0)
								}, 1000)
							}}
				/>
			}
			<Box position="relative" display="inline-flex">
				{
					loading
					? <CircularProgress />
					: error
					  ? <Warning/>
					  : <>
							<CircularProgress variant="determinate" value={progress} />
							<IconButton onClick={togglePlay} sx={{ position: 'absolute', top: 0, left: 0 }}>
								{
									isPlaying ? <PauseIcon /> : <PlayArrow />
								}
							</IconButton>
						</>
				}

			</Box>
		</Box>
	)
}

export default AudioPreviewPlayer
