import React                from 'react'
import {Tooltip, Link, Box} from '@mui/material'

const QuestionTranslator = ({ onTranslate }) => {
	const userLanguage = navigator.language || navigator.userLanguage
	const languageCode = userLanguage.split('-')[0] // Get the base language code
	const languageMap = {
		en: { name: 'English', emoji: '🇬🇧' },
		fr: { name: 'French', emoji: '🇫🇷' },
		es: { name: 'Spanish', emoji: '🇪🇸' },
		de: { name: 'German', emoji: '🇩🇪' },
		it: { name: 'Italian', emoji: '🇮🇹' },
		pt: { name: 'Portuguese', emoji: '🇧🇷' },
		nl: { name: 'Dutch', emoji: '🇳🇱' },
		ru: { name: 'Russian', emoji: '🇷🇺' },
		ja: { name: 'Japanese', emoji: '🇯🇵' },
		ko: { name: 'Korean', emoji: '🇰🇷' },
		zh: { name: 'Chinese', emoji: '🇨🇳' },
		ar: { name: 'Arabic', emoji: '🇸🇦' },
		he: { name: 'Hebrew', emoji: '🇮🇱'}
		// Add more languages and corresponding flags as necessary
	}

	// Check if the language is not English
	if (languageCode !== 'en' && languageMap[languageCode]) {
		const { name, emoji } = languageMap[languageCode]

		return (
			<Box display={"flex"} justifyContent={"flex-end"} mr={2}>
				<Tooltip title={`Show question in ${name}`}>
					<Link
						style={{ cursor: 'pointer', fontSize: '1.5rem', textDecoration: "none" }}
						onClick={() => onTranslate(name, languageCode)}
					>
						{emoji}
					</Link>
				</Tooltip>
			</Box>
		)
	}

	return null // Return null if the language is English or not in the map
}

export default QuestionTranslator
