import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'

const LabeledSelect = ({ label, value, children, sx, fullWidth = true, ...selectProps }) => {
	// Dynamically determine the label text based on the value
	const dynamicLabel = value
		? label // Show the label if a value is selected
		: ''    // Hide the label if no value is selected

	return (
		<FormControl fullWidth={fullWidth} variant="outlined" sx={sx}>
			<InputLabel shrink={!!value}>
				{dynamicLabel}
			</InputLabel>
			<Select
				value={value}
				label={dynamicLabel}
				{...selectProps}
			>
				{children}
			</Select>
		</FormControl>
	)
}

export default LabeledSelect
