import React      from 'react';
import {useTheme} from "@mui/material"

const CircleIndicator = ({ size, character, backgroundColor, color }) => {

	const theme = useTheme()

	const circleStyle = {
		width: size + "px",
		height: size + "px",
		backgroundColor: backgroundColor || theme.palette.primary.main,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		color: color || theme.palette.primary.contrastText,
		fontSize: `${size * .6}px`, // Adjust font size based on size of the circle
		fontWeight: `bold`
	};

	return (
		<div style={circleStyle}>
			{character}
		</div>
	);
};

export default CircleIndicator;
