import React, { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import {
	List,
	ListItem,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button, Box, IconButton, Typography, Divider, Container,
}                    from '@mui/material'
import getSupabase   from "../../util/supabase-utils"
import LeftRight     from "../LeftRight"
import {ArrowBack}   from "@mui/icons-material"
import {useNavigate} from "react-router-dom"
import {doPost}      from "../../util/do-fetch"

const supabase = getSupabase()

function AiPersonalityManager() {
	const navigate = useNavigate()
	const [personalities, setPersonalities] = useState([])
	const [selectedPersonality, setSelectedPersonality] = useState(null)
	const [openDialog, setOpenDialog] = useState(false)

	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [prompt, setPrompt] = useState('')

	// Fetch data from the ai_personality table
	useEffect(() => {
		fetchPersonalities()
	}, [])

	const fetchPersonalities = async () => {
		const { data, error } = 
			await supabase
				.from('ai_personality')
				.select('*')
				.order('id')
		
		if (error) {
			console.error('Error fetching personalities:', error)
		} else {
			setPersonalities(data)
		}
	}

	const resetAIPersonalityCache = async () => {
		doPost('/admin/ai-personalities/reload')
	}

	// Handle list item click to open the dialog
	const handleItemClick = (personality) => {
		setSelectedPersonality(personality)
		setName(personality.name)
		setDescription(personality.description)
		setPrompt(personality.prompt)
		setOpenDialog(true)
	}

	// Close the dialog
	const handleDialogClose = () => {
		setOpenDialog(false)
		setSelectedPersonality(null)
	}

	// Save the updated personality
	const handleSave = async () => {
		const { error } = await supabase
			.from('ai_personality')
			.update({
				name,
				description,
				prompt,
			})
			.eq('id', selectedPersonality.id)

		if (error) {
			console.error('Error updating personality:', error)
		} else {
			resetAIPersonalityCache()
			fetchPersonalities()
			handleDialogClose()
		}
	}

	return (
		<Container maxWidth="sm">
			<Box mt={4}>
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack/>
					</IconButton>
					<Typography variant={"h5"}>
						AI Personalities
					</Typography>
				</Box>
				<Divider sx={{my: 2}}/>
				<List>
					{personalities.map((personality) => (
						<ListItem
							button
							key={personality.id}
							onClick={() => handleItemClick(personality)}
						>
							<ListItemText
								primary={personality.name}
								secondary={personality.description}
							/>
						</ListItem>
					))}
				</List>
			</Box>

			<Dialog
				open={openDialog}
				onClose={handleDialogClose}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>Edit AI Personality</DialogTitle>
				<DialogContent>
					<TextField
						label="Name"
						fullWidth
						margin="normal"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						label="Description"
						fullWidth
						margin="normal"
						multiline
						rows={3}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
					<TextField
						label="Prompt"
						fullWidth
						margin="normal"
						multiline
						rows={30}
						value={prompt}
						onChange={(e) => setPrompt(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>Cancel</Button>
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	)
}

export default AiPersonalityManager
