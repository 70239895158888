import '../stylesheets/App.css'
import {Grid, Button, Container, Box, Card, Link, CircularProgress, Avatar, Typography, useTheme} from '@mui/material'
import React, {useEffect, useMemo, useState}                                                      from "react"
import {useNavigate}   from "react-router-dom"
import {doGet, doPost} from "../util/do-fetch"
import {useUserStore}  from "../state"
import {logger}        from "../util/log-utils"
import getSupabase     from "../util/supabase-utils"
import useUserRoles    from "../hooks/useUserRoles"
import GradientCard    from "./GradientCard"
import {isExpired}     from "../util/prepkit-utils"
import LeftRight       from "./LeftRight"
import QuestionCode    from "./mini/QuestionCode"
import useMediaQuery                                                    from "@mui/material/useMediaQuery"
import {ArrowForward, AutoAwesome, NotInterested, Shop, Shop2, YouTube} from "@mui/icons-material"

const log = logger("Home", 1)

function Home(props) {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const { user } = useUserStore()
	const isAdmin = useUserRoles(['admin'])
	const navigate = useNavigate()
	const [prepKits, setPrepKits] = useState(null)

	const filteredPrepKits = useMemo(()=>{

		if(prepKits) {
			// show newest at the top
			const sorted = prepKits.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

			return  sorted.length > 2
				?  sorted.slice(0, 2)
				: sorted
		}
		else {
			return null
		}


	}, [prepKits])

	const showMore = useMemo(()=>{
		return prepKits &&
			prepKits.length > 2
	}, [prepKits])

	useEffect(() => {
		// see if there is an intendedUrl in local storage
		const intendedUrl = localStorage.getItem('intendedUrl')
		if(intendedUrl) {
			localStorage.removeItem('intendedUrl')
			navigate(intendedUrl)
		}
	}, [])

	useEffect(() => {
		if(user) {
			const fetchPrepKits = async () => {
				const response = await doGet(`/mini/prepkit`)
				const data = await response.json()
				setPrepKits(data)
			}
			fetchPrepKits()
		}
	}, [user])

	return (
		<Container maxWidth="lg" sx={{mt: {xs: 3, sm: 3, md: 10}}}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4} sx={{ order: { xs: 3, md: 1 }, textAlign: 'center' }}>
					<GradientCard
                        title={"Shop"}
                        subtitle={"All PrepKits"}
                        caption={"Discover"}
                        color={"white"}
                        backgroundColor1={"rgb(63,141,151)"}
                        backgroundColor2={"rgb(149,218,165)"}
                        onClick={() => navigate("/shop")}
					>
						<Box display={"flex"}
						     flex={1}
						     mt={isSmallScreen ? 0 : 8}
						     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center" }}
						     alignItems={"center"}
						>
							<AutoAwesome sx={{fontSize: "48px"}}/>
						</Box>
					</GradientCard>
					{/*
						<Card
							sx={{
								width: '100%',
								borderRadius: 3,
								// offset-x offset-y blur-radius spread-radius color;
								//boxShadow: `0px 24px 40px -14px ${shadowColor}`,
								backgroundImage: `url(/shopping-cart-bg.png)`,
								backgroundSize: `cover`,
								overflow: 'hidden',
								padding: 2,
								aspectRatio: '1 / 1', // Maintain a square aspect ratio
								minWidth: 0,          // Ensures it doesn’t exceed container constraints
							}}
						>
							Shop All
						</Card>
					*/}
				</Grid>
				<Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 2 }, textAlign: 'center' }}>
					{
						user
							? <GradientCard
								title={"Access Your"}
								subtitle={`PrepKit${prepKits && prepKits.length > 1 ? 's' : ''}`}
								caption={"Study"}
								color={"white"}
								backgroundColor1={"#8031FF"} // rgb(95,46,42)
								backgroundColor2={"#eac0d9"} // rgb(191,150,102)
							  >
								<Box pt={3} textAlign={"left"}>
									{
										!filteredPrepKits &&
											<Box display={"flex"} justifyContent={"center"}>
												<CircularProgress sx={{color:"white"}}/>
											</Box>
									}
									{
										filteredPrepKits &&
										filteredPrepKits.map(pk =>
											<GlassButton key={`pk-${pk.id}`}
				                                        onClick={()=>navigate(`/prepkit/${pk.id}`)}
				                                        label={pk.name}/>
										)
									}
									{
										showMore &&
											<GlassButton onClick={()=>navigate(`/prepkit`)}
											             label={"Show All"}/>
									}
									{
										filteredPrepKits &&
										filteredPrepKits.length === 0 &&
										<Box display={"flex"}
										     flex={1}
										     mt={isSmallScreen ? 0 : 5}
										     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center" }}
										     alignItems={"center"}
										>
											<GlassButton onClick={()=>navigate(`/shop`)}
											             bullet={false}
											             label={"Shop PrepKits"}/>
										</Box>
									}
								</Box>
							  </GradientCard>
							: <GradientCard
								title={"Access"}
								subtitle={"or Sign Up"}
								caption={"Access"}
								color={"white"}
								backgroundColor1={"#8031FF"} // rgb(95,46,42)
								backgroundColor2={"#eac0d9"} // rgb(191,150,102)
								onClick={() => navigate("/login")}
							>
								<Box display={"flex"}
								     flex={1}
								     mt={isSmallScreen ? 0 : 8}
								     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center" }}
								     alignItems={"center"}
								>
									<GlassButton onClick={()=>navigate(`/login`)}
									             bullet={false}
									             label={"Login or Sign Up"}/>
								</Box>
							</GradientCard>

					}
				</Grid>
				<Grid item xs={12} md={4} sx={{ order: { xs: 1, md: 3 }, textAlign: 'center' }}>
					<GradientCard
						title={"Enter"}
						subtitle={"Question Code"}
						caption={"Get Started"}
						color={"white"}
						backgroundColor1={"#5F5F5F"} // rgb(95,46,42)
						backgroundColor2={"#CACACA"} // rgb(191,150,102)
					>
						<Box display={"flex"}
						     flex={1}
						     mt={isSmallScreen ? 0 : 6}
						     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center" }}
						     alignItems={"center"}
						>
							<QuestionCode size={"small"}
							              autoFocus={false}
							              onSubmit={(code)=>navigate(`/q/${code}`)}
							/>
						</Box>

					</GradientCard>

				</Grid>
				{/*
				<Grid item xs={12} md={4} sx={{ order: { xs: 1, md: 3 }, textAlign: 'center' }}>
					<GradientCard
						title={user.first_name}
						subtitle={user.email}
						caption={"Profile"}
						color={"white"}
						square={false}
						glow={false}
						backgroundColor1={ theme.palette.mode === 'dark' ? "#454545" : "#ccc" }
						backgroundColor2={ theme.palette.mode === 'dark' ? "#454545" : "#ccc" }
						onClick={()=>navigate('/account/profile')}
					/>
				</Grid>
				*/}
			</Grid>
		</Container>
	)
}

const GlassButton = ({label, bullet=true, onClick}) => {
	return <Box
		px={2}
		py={1}
		mx={-1.5}
		sx={{
			backgroundColor: `rgba(255,255,255,.0)`,
			borderRadius: 1,
			color: 'inherit',
			cursor: 'pointer',
			fontWeight: 600,
			'&:hover': {
				backgroundColor: `rgba(255,255,255,.2)`,
			}
		}}
		display={"flex"}
		flexDirection={"row"}
		onClick={onClick}
	>
		{
			bullet && <Box mr={1}>· </Box>
		}
		<Box>{label}</Box>
	</Box>
}

export default Home