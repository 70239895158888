import React, {useEffect, useState}                                         from 'react'
import {
	Box,
	Button,
	CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
	Drawer,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Switch, Tooltip,
	useTheme
}                                                                                  from '@mui/material'
import Grid                                                                        from "@mui/material/Unstable_Grid2"
import {doGet, doPost}                                                             from "../../util/do-fetch"
import {Alert, AlertTitle}                                                         from "@mui/material"
import ProductMediaTree                                                            from "./ProductMediaTree"
import EditableLabel                                                               from "../EditableLabel"
import {Add, AddCircle, ChevronLeft, ChevronRight, Close, Folder, Title, Videocam} from "@mui/icons-material"
import {validateDate}                                                              from "@mui/x-date-pickers/internals"
import {isBlankOrEmpty}                                                            from "../../util/utils"

const VideoJobDialog = ({open, onCancel, onSaved}) => {

	const CUSTOM_CLIPS_DIR = ".custom-clips"
	const CUSTOM_CLIPS_PATH = CUSTOM_CLIPS_DIR + "/"
	const theme = useTheme()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(false)
	const [showMediaJobs, setShowMediaJobs] = useState(false)
	const [showCustomClips, setShowCustomClips] = useState(false)
	const [resolveSelectClip, setResolveSelectClip] = useState(null)
	const [customClipsPath, setCustomClipsPath] = useState([CUSTOM_CLIPS_PATH])
	const [products, setProducts] = useState(null)
	const [customClips, setCustomClips] = useState(null)
	const [filteredClips, setFilteredClips] = useState(null)
	const [mediaJobs, setMediaJobs] = useState(null)
	const [selectedProduct, setSelectedProduct] = useState(null)
	const [title, setTitle] = useState(null)
	const [description, setDescription] = useState(null)
	const [hideExcluded, setHideExcluded] = useState(false)
	const [errors, setErrors] = useState(null)
	const [productMediaJobs, setProductMediaJobs] = useState()
	const [productMediaJobValues, setProductMediaJobValues] = useState([])



	useEffect(() => {
		fetchProducts()
		fetchCustomClips()
	}, [])

	useEffect(() => {
		if (selectedProduct) {
			fetchMediaJobs()
		}
		else {
			setMediaJobs(null)
		}
	}, [selectedProduct])

	useEffect(() => {
		if (customClips) {
			const currentPath = customClipsPath[customClipsPath.length - 1]
			setFilteredClips(customClips.filter(c => {
				const parts = c.key.split("/")
					.filter(Boolean)
				parts.pop() // remove name from path
				const path = parts.join("/") + "/"
				return path === currentPath && c.key !== currentPath
			}))
		}
		else {
			setFilteredClips(null)
		}
	}, [customClips, customClipsPath])

	const fetchProducts = async () => {
		try {
			const response = await doGet(`/admin/products`)
			const data = await response.json()
			setProducts(data)
		}
		catch (err) {
			console.error(err)
		}
	}

	const fetchCustomClips = async () => {
		try {
			const response = await doGet(`/admin/videos/custom-clips`)
			const data = await response.json()
			setCustomClips(data)
		}
		catch (err) {
			console.error(err)
		}
	}

	const fetchMediaJobs = async () => {
		setLoading(true)
		try {
			const response = await doGet(`/admin/products/${selectedProduct.id}/media-jobs`)
			setMediaJobs(await response.json())
		}
		catch (err) {
			console.error(err)
		}
		finally {
			setLoading(false)
		}
	}

	const addMediaJob = async (mediaJob) => {
		if (!mediaJob) {
			alert("Select a product and media job.")
		}
		try {
			const prodResp = await doGet(`/admin/products/${mediaJob.product_id}`)
			const prod = await prodResp.json()
			const mjResp = await doGet(`/admin/products/${mediaJob.product_id}/media-jobs/${mediaJob.id}`)
			const mj = await mjResp.json()

			setProductMediaJobs(prev => {
				const updated = [...prev || []]
				updated.push({
					product: prod,
					mediaJob: mj
				})
				return updated
			})

			setShowMediaJobs(false)
		}
		catch (err) {
			console.error(err)
		}
	}

	const validate = () => {

		const errs = []
		if(isBlankOrEmpty(title)) {
			errs.push("Title is required.")
		}
		if(isBlankOrEmpty(description)) {
			errs.push("Description is required.")
		}

		if(!productMediaJobValues || productMediaJobValues.length === 0) {
			errs.push("At least one Product / Media Job is required.")
		}
		else {
			// make sure all productMediaJobs have at least one selected question
			productMediaJobValues.forEach(pmj => {
				if(!pmj.selectedQuestions || pmj.selectedQuestions.length === 0){
					// get product to use it's name in the error
					const product = productMediaJobs.find(p => p.id === pmj.productId)
					errs.push(`Product/Media Job "${product.name}" must have at least one question selected.`)
				}
			})
		}

		return errs
	}

	const handleSave = async () => {
		setSaving(true)
		try {
			const errs = validate()

			if(errs && errs.length > 0) {
				setErrors(errs)
				return
			}

			const options = {
				body: JSON.stringify({
					title,
					description,
					productMediaJobs: productMediaJobValues
				})
			}
			const response = await doPost(`/admin/videos/jobs/create`, options)
			const updated = await response.json()
			onSaved(updated)
		}
		catch (err) {
			console.error(err)
		}
		finally {
			setSaving(false)
		}
	}

	const handleProductMediaJobChange = (value, changedProperty) => {
		setProductMediaJobValues(prev => {
			const updated = [...prev]
			let index = updated.findIndex(j => j.key === value.key)
			if(index === -1) {
				// added a new one
				updated.push(value)
			}
			else {
				// update an existing one
				updated[index] = value
			}
			return updated
		})

	}

	const handleRemoveProductMediaJob = (index) => {
		setProductMediaJobs(prev => {
			const updated = [...prev].filter((_, i) => i !== index)
			return updated
		})
		setProductMediaJobValues(prev => {
			const updated = [...prev].filter((_, i) => i !== index)
			return updated
		})

	}

	const handleGetCustomClip = async () => {
		return new Promise((resolve) => {
			setShowCustomClips(true)
			setResolveSelectClip(() => resolve)
		})
	}

	const handleCustomClipSelection = (clip) => {
		if (resolveSelectClip) {
			resolveSelectClip(clip)
			setShowCustomClips(false)
			setResolveSelectClip(null)
		}
	}

	const mediaJobSelected = (mediaJob) => {
		console.log({mediaJob})
		return productMediaJobs &&
			productMediaJobs.find(j => {
				console.log({j})
				return j.product.id===mediaJob.product_id && j.mediaJob.id === mediaJob.id
			})
	}

	let drawerTitle
	let mediaJobsComp
	let customClipsComp

	if (showMediaJobs) {
		if (mediaJobs) {
			drawerTitle = "Media Jobs"
			mediaJobsComp = <Box>
				<Box>
					<Button onClick={() => setSelectedProduct(null)}>
						<ChevronLeft/> Back
					</Button>
				</Box>
				<List>
					{
						mediaJobs.map(j => {
							return <MediaJobListItem
								key={j.id}
								mediaJob={j}
								disabled={mediaJobSelected(j)}
								onAddMediaJob={addMediaJob}
							/>
						})
					}
				</List>
			</Box>
		}
		else if (products) {
			drawerTitle = "Products"
			mediaJobsComp = <Box>
				<List>
					{
						products.map(p => {
							return <ListItem
								key={p.id}
								onClick={() => setSelectedProduct(p)}
								sx={{
									cursor: 'pointer',
									justifyContent: "space-between",
									py: 2,
									px: 3,
									borderBottom: '1px solid ' + theme.palette.divider
								}}
							>
								{p.name}
								<ChevronRight/>
							</ListItem>
						})
					}
				</List>
			</Box>
		}
		else {
			mediaJobsComp = <p style={{textAlign: 'center'}}><CircularProgress size={14}/> Loading Products</p>
		}
	}
	else if (showCustomClips) {
		if (filteredClips) {
			drawerTitle = "Custom Clips"
			customClipsComp = <Box>
				<Box>
					{
						customClipsPath &&
						customClipsPath.length > 1 &&
						<Button onClick={() => setCustomClipsPath(prev => [...prev].slice(0, -1))}>
							<ChevronLeft/> Back
						</Button>
					}
				</Box><Box>
			</Box>
				<List>
					{
						filteredClips.map(c => {
							return c.key.endsWith("/")
								? <ListItem key={c.key}>
									<ListItemButton onClick={() => setCustomClipsPath(prev => [...prev, c.key])}>
										<ListItemIcon>
											<Folder/>
										</ListItemIcon>
										<ListItemText primary={c.key.split('/')
											.filter(Boolean)
											.pop()}/>
									</ListItemButton>
								</ListItem>
								: <ListItem key={c.key}>
									<ListItemButton onClick={() => {
										handleCustomClipSelection(c)
									}}>
										<ListItemIcon>
											<Videocam/>
										</ListItemIcon>
										<ListItemText primary={c.key.split('/')
											.pop()}/>
									</ListItemButton>
								</ListItem>

						})
					}
				</List>
			</Box>
		}
	}

	return <Dialog
		open={open}
		fullScreen={true}
	>
		<DialogTitle>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					Create Video Job
				</div>
				<IconButton onClick={onCancel}>
					<Close color="error"/>
				</IconButton>
			</Box>
		</DialogTitle>
		<Divider/>
		<DialogContent>
			<Grid container maxWidth="lg" sx={{margin: "0 auto"}}>
				<Grid xs={12}>
					<Box
						sx={{pb: 2}}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<h2 style={{padding: 0, margin: 0}}>
							<EditableLabel
								placeholder="Enter Video Title"
								label={title}
								onSave={setTitle}
							/>
						</h2>
						<Box>
							<Tooltip title="Add Product / Media Job">
								<IconButton onClick={() => setShowMediaJobs(true)}
								            variant="contained"
								            color="primary"
								            size="medium"
								            style={{
									            backgroundColor: theme.palette.primary.main,
									            color: theme.palette.primary.contrastText,
									            '&:hover': {
										            backgroundColor: theme.palette.primary.dark,
									            },
								            }}
								>
									<Add/>
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Grid>

				<Grid xs={12}>
					<EditableLabel fullWidth={true}
					               label={description}
					               placeholder={"Enter video description"}
					               onSave={setDescription}
					               multiline={true}
					/>

				</Grid>
				{
					errors &&
					<Grid xs={12}>
						<Alert severity="error" onClose={() => setErrors(null)}>
							<AlertTitle>
								Error Saving Video Job
							</AlertTitle>
							{errors.map(e => <div>{e}</div>)}
						</Alert>
					</Grid>
				}
				<Grid xs={12}>
					<Divider sx={{my: 2}}/>
					{
						productMediaJobs && <Box sx={{mb: 1}} display="flex" justifyContent="flex-end">
							<FormControlLabel
								control={
									<Switch
										color="secondary"
										value={hideExcluded}
										onChange={(event) => {
											setHideExcluded(event.target.checked)
										}}
									/>
								}
								label="Hide Excluded"
							/>
						</Box>
					}
					{
						productMediaJobs
							? productMediaJobs.map((pmjs, i) => {
								return <ProductMediaTree
									key={`${pmjs.product.id}-${pmjs.mediaJob.id}`}
									product={pmjs.product}
									mediaJob={pmjs.mediaJob}
									hideExcluded={hideExcluded}
									onGetCustomClip={handleGetCustomClip}
									onChange={handleProductMediaJobChange}
									onRemove={() => handleRemoveProductMediaJob(i)}
								/>
							})
							: <h2 style={{
								textAlign: "center",
								color: theme.palette.text.disabled
							}}>
								Add a Product and Media Job
							</h2>
					}
				</Grid>
				<Drawer anchor="right"
				        open={showMediaJobs || showCustomClips}
				        variant={"persistent"}
				>
					<Box sx={{width: "400px"}}>
						<Box display="flex" flexDirection="row" justifyContent="space-between">
							<Box sx={{pl: 2}}>
								<h3>{drawerTitle}</h3>
							</Box>
							<IconButton onClick={() => {
								if (showCustomClips) {
									resolveSelectClip(null)
									setShowCustomClips(false)
									setResolveSelectClip(null)
								}
								else if (showMediaJobs) {
									setShowMediaJobs(false)
								}
							}}
							>
								<Close color="error" size="20"/>
							</IconButton>
						</Box>

						{showMediaJobs && mediaJobsComp}
						{showCustomClips && customClipsComp}
					</Box>
				</Drawer>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button size="large"
			        disabled={saving}
			        onClick={onCancel}
			>
				Cancel
			</Button>
			<Button size="large"
			        variant="contained"
			        disabled={saving}
			        onClick={handleSave}
			>
				Save
				{ saving && <CircularProgress size={18}/> }
			</Button>
		</DialogActions>
	</Dialog>
}


function MediaJobListItem({mediaJob, onAddMediaJob, disabled}) {

	const theme = useTheme()
	const [working, setWorking] = useState(false)

	const handleAddMediaJob = async () => {
		setWorking(true)
		try {
			await onAddMediaJob(mediaJob)
		}
		finally {
			setWorking(false)
		}
	}

	return <ListItem
		onClick={disabled ? null : handleAddMediaJob}
		disabled={disabled}
		sx={{
			cursor: disabled ? 'default' : 'pointer',
			justifyContent: "space-between",
			py: 2,
			px: 3,
			color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
			borderBottom: '1px solid ' + theme.palette.divider
		}}
	>
		{mediaJob.id}: {mediaJob.tts_model}/{mediaJob.voice_id}
		{
			working
				? <CircularProgress size={18}/>
				: <AddCircle sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,}}/>
		}
	</ListItem>
}


export default VideoJobDialog
