import React, {useEffect, useState}                                                       from 'react'
import {Modal, Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material'
import SelectCategory                                 from "./SelectCategory"
import {fetchCategories, fetchTTSModels, fetchVoices} from "../../util/admin"

const EditProductModal = ({ open, product, onClose, onSave }) => {
	const [name, setName] = useState(product.name || '')
	const [shortName, setShortName] = useState(product.short_name || '')
	const [token, setToken] = useState(product.token || '')
	const [category, setCategory] = useState(product.category_id || null)


	const handleSave = () => {
		const update = { name, shortName, token, category }
		onSave(update)
		onClose() // Close modal after saving
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Edit Product</DialogTitle>
			<DialogContent>
				<TextField
					label="Name"
					fullWidth
					value={name}
					onChange={(e) => setName(e.target.value)}
					margin="normal"
				/>
				<SelectCategory sx={{mt: 1}} category={category} onChangeCategory={(c)=>setCategory(c.id)}/>
				<TextField
					label="Short Name"
					fullWidth
					value={shortName}
					onChange={(e) => setShortName(e.target.value)}
					margin="normal"
				/>
				<TextField
					label="URL Token"
					fullWidth
					value={token}
					onChange={(e) => setToken(e.target.value)}
					margin="normal"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EditProductModal
