import React, { useState, useCallback, useEffect } from 'react'
import {
	TextField,
	Button,
	Container,
	Box,
	Typography,
	useTheme,
	Alert,
	AlertTitle,
	CircularProgress
}                      from '@mui/material'
import { getSupabase } from "../../util/supabase-utils"
import {ArrowBack}     from "@mui/icons-material"

const supabase = getSupabase()

const UpdateEmail = ({switchView}) => {
	const theme = useTheme()
	const [currentEmail, setCurrentEmail] = useState('')
	const [newEmail, setNewEmail] = useState('')
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [loading, setLoading] = useState(false)
	const [changeRequested, setChangeRequested] = useState(false)

	useEffect(() => {
		const fetchCurrentEmail = async () => {
			const { data: { user } } = await supabase.auth.getUser()
			if (user) {
				setCurrentEmail(user.email)
			}
		}
		fetchCurrentEmail()
	}, [])

	const handleUpdateEmail = useCallback(async () => {
		if (!newEmail) {
			setError('Please enter a new email address.')
			return
		}

		try {
			setLoading(true)
			setError('')

			const { data, error } = await supabase.auth.updateUser({ email: newEmail })

			if (error) throw error

			setChangeRequested(true)
			setSuccess('Email change requested. Please check your new email for a confirmation link.')
		}
		catch (error) {
			console.error('Update email error:', error.message)
			setError(error.message || "An error occurred. Please try again later.")
		}
		finally {
			setLoading(false)
		}
	}, [newEmail])

	return (
		<Container>
			<Box component="form" mt={3}>
				<Typography variant="h6"
				            sx={{cursor: 'pointer'}}
				            onClick={()=>switchView('')}
				            display={"flex"}
				            alignItems={"center"}
				>
					<ArrowBack sx={{mr: 0.5}}/> Update Email Address
				</Typography>

				{error && <Alert severity="error" mb={2}>{error}</Alert>}
				{success && <Alert severity="success" mb={2}>{success}</Alert>}
				<TextField
					label="Current Email"
					fullWidth
					margin="normal"
					value={currentEmail}
					disabled
				/>
				<TextField
					label="New Email"
					type="email"
					fullWidth
					margin="normal"
					value={newEmail}
					onChange={(e) => setNewEmail(e.target.value)}
					disabled={loading || changeRequested}
				/>
				<Button
					variant="outlined"
					color="primary"
					fullWidth
					size="large"
					onClick={handleUpdateEmail}
					disabled={loading || changeRequested}
					sx={{ mt: 2, py: 1.5 }}
				>
					{loading ? <CircularProgress size={24} /> : "Update Email"}
				</Button>
				{changeRequested && (
					<Alert severity="info" sx={{ mt: 2 }}>
						<AlertTitle>Verification Required</AlertTitle>
						A verification link has been sent to your new email address.
						Please check your email and click the link to confirm the change.
					</Alert>
				)}
			</Box>
		</Container>
	)
}

export default UpdateEmail