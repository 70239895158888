import React from 'react';
import { Dialog, Backdrop } from '@mui/material';

const BlurredBackdrop = React.forwardRef(function BlurredBackdrop(props, ref) {
	return (
		<Backdrop
			ref={ref}
			{...props}
			sx={{
				backdropFilter: 'blur(3px)',
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				// Merge any other styles passed via props
				...props.sx,
			}}
		/>
	);
});

export default function DialogWithBlurredBackdrop({ open, onClose, children, ...props }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="md"
			BackdropComponent={BlurredBackdrop}
			{...props}
		>
			{children}
		</Dialog>
	);
}