import { useTheme }          from '@mui/material/styles';
import {useEffect, useState} from "react"
import Grid            from "@mui/material/Unstable_Grid2"
import {Button, Card}  from "@mui/material"
import {SquareRounded} from "@mui/icons-material"
import {logger}   from "../../util/log-utils"
import RSVPReader from "../prepkit/RSVPReader"

const log = logger("ThemePaletteBrowser", 1)

const psalm91 = "He that dwelleth in the secret place of the most High shall abide under the shadow of the Almighty. I will say of the LORD, He is my refuge and my fortress: my God; in him will I trust. Surely he shall deliver thee from the snare of the fowler, and from the noisome pestilence. He shall cover thee with his feathers, and under his wings shalt thou trust: his truth shall be thy shield and buckler. Thou shalt not be afraid for the terror by night; nor for the arrow that flieth by day; Nor for the pestilence that walketh in darkness; nor for the destruction that wasteth at noonday. A thousand shall fall at thy side, and ten thousand at thy right hand; but it shall not come nigh thee. Only with thine eyes shalt thou behold and see the reward of the wicked. Because thou hast made the LORD, which is my refuge, even the most High, thy habitation; There shall no evil befall thee, neither shall any plague come nigh thy dwelling. For he shall give his angels charge over thee, to keep thee in all thy ways. They shall bear thee up in their hands, lest thou dash thy foot against a stone. Thou shalt tread upon the lion and adder: the young lion and the dragon shalt thou trample under feet. Because he hath set his love upon me, therefore will I deliver him: I will set him on high, because he hath known my name. He shall call upon me, and I will answer him: I will be with him in trouble; I will deliver him, and honour him. With long life will I satisfy him, and shew him my salvation."
const explanation = "Concurrent ownership in California Real Estate refers to property that is owned by multiple parties at the same time. There are two primary forms, joint tenancy and tenancy in common. Joint tenancy is a type of ownership in which two or more people own a property with equal interest. The primary distinctive feature of joint tenancy is the right of survivorship; when a joint tenant dies, the deceased tenant’s interest is evenly divided among the surviving tenants. Tenancy in common, on the other hand, allows two or more individuals to hold title to real property. Each tenant has an undivided, separate interest in the entire property and can sell, will, or lease their interest without the other tenants' consent. Unlike joint tenancy, tenancy in common does not have the right to survivorship, meaning the share of a deceased tenant will pass to their heirs and not the surviving tenants."
const mobyDick = "The morning of the third day dawned fair and fresh, and once more the solitary" +
	" night-man at the fore-mast-head was relieved by crowds of the daylight look-outs, who dotted every" +
	" mast and almost every spar. 'D'ye see him?' cried Ahab; but the whale was not yet in sight. 'In" +
	" his infallible wake, though; but follow that wake, that's all. Helm there; steady, as thou goest," +
	" and hast been going. What a lovely day again! Were it a new-made world, and made for a summer-house" +
	" to the angels, and this morning the first of its throwing open to them, a fairer day could not" +
	" dawn upon that world. Here's food for thought, had Ahab time to think; but Ahab never thinks; he" +
	" only feels, feels, feels, that's tingling enough for mortal man! To think's audacity. God only has" +
	" that right and privilege. Thinking is, or ought to be, a coolness and a calmness; and our poor hearts" +
	" throb, and our poor brains beat too much for that. And yet, I've sometimes thought my brain was very" +
	" calm—frozen calm, this old skull cracks so, like a glass in which the contents turned to ice, and" +
	" shiver it."

function ThemePaletteBrowser() {
	const theme = useTheme();
	const [variables, setVariables] = useState([]);

	const getVariables = (obj, prefix = '') => {
		let cssVariables = {};

		for (let key in obj) {
			if (typeof obj[key] === 'string') {
				// Convert camelCase to kebab-case
				const kebabKey = key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
				cssVariables[`--${prefix}${kebabKey}`] = obj[key];
			} else if (typeof obj[key] === 'object') {
				// Recursive call for nested objects
				cssVariables = {
					...cssVariables,
					...getVariables(obj[key], `${prefix}${key}-`)
				};
			}
		}

		return cssVariables;
	};

	useEffect(() => {

		const paletteVariables = getVariables(theme.palette);
		const vars = []

		for (let key in paletteVariables) {
			vars.push({variable: key, color: paletteVariables[key]});
		}
		setVariables(vars)
	}, [theme]);

	const variableName = (variable) => {
		if(variable.indexOf("--") === 0){
			variable = variable.substring(2)
			variable = variable.replaceAll("-", ".")
			variable = variable.replaceAll("..", "--")

		}
		return variable;
	}

	const paletteValue = (s) => {
		const path = "theme.palette['" + s.split(".").join("']['") + "']"
		const props = s.split(".")
		const val = dig(theme.palette, props, 0)
		return "";
	}

	const dig = (o, props, i) => {

		try {
			if (i >= props.length) {
				return null
			}
			else if (i === props.length - 1) {
				// we are at value depth
				const prop = props[i]
				if (o && o[prop]) {
					const val = o[prop]
					if (typeof (val) === "string"
						&& val.startsWith("theme.palette")) {
						// the value refers to another variable, os start over
						const props2 = val.replaceAll("theme.palette.", "")
							.split(".")
						return dig(theme.palette, props2, 0)
					}
					else {
						return val;
					}
				}
				else {
					// prop not found
					return o
				}
			}
			else {
				// we're not there yet
				const prop = props[i]
				return dig(o[props[i]], props, i + 1)
			}
		}
		catch(err) {
			log.error(err)
		}
	}

	const handleCopy = (variable) => {
		navigator.clipboard.writeText(`color: var(${variable.variable});`)
			.then(() => {
				log.debug('Text copied to clipboard');
			})
			.catch(err => {
				log.error('Unable to copy text: ', err);
			});
	}

	return (
		<>
			<Grid container>
				<Grid xs={4}></Grid>
				<Grid xs={4}>
					<Card>
					<RSVPReader text={explanation} />
					</Card>
				</Grid>
			</Grid>
			{variables && variables.map((v) => {
				return (
					<Grid key={v.variable} container>
						<Grid xs={4} style={{border: "0px solid blue"}}/>
						<Grid xs={4} style={{border: "0px solid red"}}>
							{v.variable !== "--mode" &&
								<Button onClick={() => handleCopy(v)} startIcon={<SquareRounded style={{color: v.color}}/>}>
									{v.variable}: {v.color} {paletteValue(`${variableName(v.variable)}`)}
								</Button>
							}
						</Grid>
					</Grid>
				)
			})}
		</>
	);
}

export default ThemePaletteBrowser;