import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import {doGet, doPost}                  from "../../util/do-fetch"
import {getSupabase}                    from "../../util/supabase-utils"
import {useUserStore}                                                  from "../../state"
import {DEBUG, logger}                                                             from "../../util/log-utils"
import {Box, Button, CircularProgress, Divider, Drawer, Fab, Typography, Alert, AlertTitle, useTheme} from "@mui/material"
import CircularProgressWithLabel                                 from "../CircularProgressWithLabel"
import TopicToc                                              from "./TopicToc"
import {ArrowBack, ArrowForward, ArrowUpwardOutlined, Close} from "@mui/icons-material"
import ExpiringPrepKit                                       from "./ExpiringPrepKit"
import {findQuestionInPrepKit, findTopicInPrepKit, scorePrepKit} from "../../util/mini"
import TopicQuestionToc                                          from "./TopicQuestionToc"
import LoadingDots                                               from "../LoadingDots"
import PurchasePackage                                           from "./PurchasePackage"
import RenewPrepKit                                              from "./RenewPrepKit"
import {cloneDeep}                                               from "lodash"
import TryPackage          from "./TryPackage"

const MiniPrepKitContext = createContext()

const log = logger("MiniPrepKit", DEBUG)

const MiniPrepKit = () => {
	const navigate = useNavigate()
	const {id, code} = useParams()
	const {user} = useUserStore()
	const [prepKit, setPrepKit] = useState(null)
	const [packages, setPackages] = useState(null)
	const [trialPackages, setTrialPackages] = useState(null)
	const [selectedTopic, setSelectedTopic] = useState(null)
	const [showToc, setShowToc] = useState(false)
	const [expired, setExpired] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const showWelcome = false //useMemo(() => prepKit && !code)
	const prepKitRef = useRef(null)

	useEffect(() => {
		prepKitRef.current = prepKit;
	}, [prepKit]);

	// subscribe to answer channel
	useEffect(() => {
		if (user && prepKit) {
			const answerChannel = subscribeToAnswerChannel();

			return () => {
				if (answerChannel) {
					log.debug("unsubscribe from answerChannel");
					answerChannel.unsubscribe();
				}
			}
		}
	}, [prepKit, user])

	useEffect(() => {
		log.debug(`useEffect[id,prepKit,code] ${id}/${code}`)
		if(id) {
			const fetchPrepKit = async () => {
				if (!prepKit || prepKit.id !== id) {
					setLoading(true)
					setTrialPackages(null)
					setPackages(null)
					// only fetch the prepKit data if not loaded
					const response = await doGet(`/mini/prepkit/${id}`)


					if (response.ok) {
						const data = await response.json()
						scorePrepKit(data) // important to do this before you set local state
						setPrepKit(data)
					}
					else if (response.status === 404) {
						setError("PrepKit not found.")
					}

					setLoading(false)
				}
			}
			fetchPrepKit()
		}
		else if(code) {
			const checkAccess = async () => {
				// get the package/license for this question and user
				try {
					const url = `/mini/check-access/${code}`
					const response = await doGet(url)
					const data = await response.json()
					if (response.ok) {
					    if (data.prepKit) {
						    setTrialPackages(null)
						    setPackages(null)
							navigate(`/prepkit/${data.prepKit.id}/q/${code}`)
						}
						else if (data.trialPackages) {
							// if there are other packages available, then offer them
							setTrialPackages(data.trialPackages)
						}
						else if (data.prepKits) {
							// show modal so user can choose which prepKit
							// this is a RARE possibility
						}
					}
					else if (response.status === 402) { // payment required
						// need to buy a license
						// or renew a license
						setPackages(data.packages)
					}
				}
				catch (err) {
					log.error(err)
				}
			}
			checkAccess()
		}
	}, [id, prepKit, code])

	const subscribeToAnswerChannel = () => {
		if (!user) return null

		const channelName = `channel-answer-${user.id}`
		console.log(`subscribing to ${channelName}`)
		const supabase = getSupabase()
		return supabase
			.channel(channelName)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "answer",
					filter: `user_id=eq.${user.id}`,
				},
				(event) => {
					log.debug(`${channelName} ${event.eventType} subscribe answer ${event.new.id}`)
					if (event.eventType === "INSERT" ||
						event.eventType === "UPDATE"
					) {
						const newAnswer = event.new

						// Use the ref to access the latest prepKit
						const currentPrepKit = prepKitRef.current;

						if (!currentPrepKit) {
							console.log("no prepKit, bailing");
							return
						}

						if (currentPrepKit.id !== newAnswer.prepkit_id) {
							console.log(`id[${currentPrepKit.id}] !== newId[${newAnswer.prepkit_id}], bailing`);
							return
						}

						setPrepKit(prev => {
							const prepKitX = cloneDeep(prev)

							// update answer array in the prepKit
							const answerIndex = prepKitX.answers.findIndex(a => a.id === newAnswer.id)
							if (answerIndex !== -1) {
								// answer with same id exists
								prepKitX.answers[answerIndex] = newAnswer  // update the answer at the found index
							}
							else {
								// no answer with the same id
								log.debug("did not find matching answer, append to answers")
								prepKitX.answers.push(newAnswer)
							}

							// find the question and update it's answer
							const { question } = findQuestionInPrepKit(prepKitX, newAnswer.question_id)
							question.userAnswer = newAnswer

							// re-score the prepKit to account for new/updated answer
							// NOTE: it is important to note that any objects updated by scoring should have been
							// already cloned above as part of updating the answer state
							scorePrepKit(prepKitX)
							return prepKitX
						})
					}
				}
			)
			.subscribe()
	}

	const handleShowToc = (show) => {
		setShowToc(show)
	}

	const handleTopicClick = (t) => {
		setShowToc(false)
		if(t) {
			const topic = findTopicInPrepKit(prepKit, t.id)
			setSelectedTopic(topic)
		}
		else {
			setSelectedTopic(null)
		}
	}

	const handleQuestionClick = (q) => {
		setShowToc(false)
		navigate(`q/${q.code}`)
	}

	const handleTryPackage = async (packageToTry) => {
		try {
			const response = await doPost(`/mini/package/${packageToTry.id}/try`)

			if (response.ok) {
				const data = await response.json()
				const prepKit = data.prepKit
				if(prepKit) {
					navigate(`/prepkit/${prepKit.id}`)
				}
				else {
					throw `Your complimentary study session has expired.`
				}
			}
			else if (response.status === 403) {
				// forbidden
				throw `Your complimentary study session has expired.`
			}
		}
		catch (err) {
			log.error(err)
			setError(err)
		}
	}

	const handlePurchaseComplete = async (paymentIntent) => {
		// update local state to set is_trial = false
		setPrepKit(prev => {
			const clone = cloneDeep(prev)
			clone.is_trial = false
			return clone
		})
	}

	let comp = null

	if(loading) {
		comp = (
			<Box pt={10}>
				<LoadingDots/>
			</Box>
		)
	}
	else if(error) {
		comp = (
			<Box pt={10} width={"100%"} maxWidth={"600px"} margin={"0 auto"}>
				<Alert severity="error">
					<AlertTitle>
						<Typography variant={"h5"}>Error</Typography>
					</AlertTitle>
					<Typography variant={"h6"}>{error}</Typography>
					<Button onClick={()=>navigate('/prepkit')}>
						<ArrowBack/> Back to PrepKits
					</Button>
				</Alert>
			</Box>
		)
	}
	else if(expired) {
		comp = (
			<RenewPrepKit prepKit={prepKit}
			              id={id}
			              onPurchaseComplete={handlePurchaseComplete}
			/>
		)
	}
	else if(packages) {
		comp = (
			<Box textAlign="center">
				<PurchasePackage title="Purchase PrepKit"
				                 prepKit={prepKit}
				                 packages={packages}
				                 onCancel={()=>{
					                 navigate('/shop')
				                 }}
				                 onPurchaseComplete={handlePurchaseComplete}
				/>
			</Box>
		)
	}
	else if(trialPackages) {
		comp = (
			<Box textAlign="center">
				<TryPackage packages={trialPackages}
				            onTryPackage={handleTryPackage}
				            onCancel={()=>{
					            navigate('/shop')
				            }}
				            onPurchaseComplete={handlePurchaseComplete}
				/>
			</Box>
		)
	}
	else {
		if(showWelcome) {
			comp = (
				<Welcome
					prepKit={prepKit}
					onTopicClick={handleTopicClick}
					selectedTopic={selectedTopic}
				/>
			)
		}
		else {
			comp = <Outlet context={{onShowToc: handleShowToc}}/>
		}
	}

	return (
		<MiniPrepKitContext.Provider value={prepKit}>
			<Box>
				{comp}
			</Box>

			{
				!expired &&
					<TocDrawer prepKit={prepKit}
			           showToc={showToc}
			           setShowToc={setShowToc}
			           onTopicClick={handleTopicClick}
			           onQuestionClick={handleQuestionClick}
				/>
			}
			<ExpiringPrepKit prepKit={prepKit}
			                 onBuy={() => setExpired(true)}
			                 onPrepKitExpired={() => setExpired(true)}
			/>

		</MiniPrepKitContext.Provider>
	)
}

const Welcome = ({prepKit, selectedTopic, onTopicClick}) => {
	const theme = useTheme()

	const navButtonSx = {
		borderRadius: 25,
		border: '1px solid',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px 24px',
		textAlign: 'left',
		width: '100%',
		fontSize: '18px',
		fontWeight: 500,
		textTransform: 'none',
		mb: 2,
		'&:hover': {
			border: '1px solid'
		}
	}

	const sx = {
		transition: 'all 500ms'
	}

	const leftSx = useMemo(() => {
		return selectedTopic
			? {
				...sx,
				border: `2px solid magenta`,
				width: '50%',
				overflowY: 'auto'
			}
			: {
				...sx,
				border: `2px solid magenta`,
				width: '100%',
				overflowY: 'auto'
			}
	}, [selectedTopic])

	const rightSx = useMemo(() => {
		return selectedTopic
			? {
				...sx,
				border: `2px solid orange`,
				width: '50%',
				overflowY: 'auto'
			}
			: {
				...sx,
				border: `2px solid orange`,
				width: '0%',
				overflowX: 'hidden',
				overflowY: 'auto'
			}
	}, [selectedTopic])

	const first = false

	prepKit.products[1] = prepKit.products[0]
	prepKit.products[2] = prepKit.products[0]

	const bw = 0

	if(first) {

		return <Box display={"flex"}>
			<Box sx={leftSx}>
				<h3>Left</h3>
				<Box
					mt={2}
					border={"2px solid gold"}
				>
					{
						prepKit &&
						prepKit.products &&
						prepKit.products.map(p => {
							return (
								<Box
									key={`product-${p.id}`}
									maxWidth="500px"
									sx={[
										{
											border: '2px dashed red',
											width: selectedTopic ? '70%' : '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center'
										},
										sx
									]}
								>
									<h3>{p.name}</h3>
									<Box mt={2}>
										{
											p.topics.map(t => {
												return <Button
													fullWidth={true}
													key={`topic-${t.id}`}
													onClick={() => onTopicClick(t)}
													variant="outlined"
													size="large"
													sx={navButtonSx}
													endIcon={<ArrowForward/>}
												>
													{t.name}
												</Button>
											})
										}
									</Box>
								</Box>
							)
						})
					}
				</Box>
			</Box>
			<Box sx={rightSx}>
				<h3>Right</h3>
				<Button onClick={()=>onTopicClick(null)}>Close</Button>
			</Box>
		</Box>
	}
	else {
		// THIS USES FLEX AND TRANSITIONS AND IT WORKS BUT COULD BE BETTER
		return <Box sx={{
				position: 'fixed',
				top: '100px',
				left: 0,
				right: 0,
				bottom: 0,
				border: `${bw*4}px solid pink`
			}}>
				<Box
					display="flex"
					border={`${bw+1}px solid orange`}
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<Box
						border={`${bw}px solid magenta`}
						width={selectedTopic ? '50%' : '100%'}
						sx={sx}
					>
						<Box
							border={`${bw*2}px solid purple`}
							width={selectedTopic ? '100%' : '33%'}
							margin={selectedTopic ? '0 auto' : '0 auto'}
							height={"800px"}
							sx={sx}
							display={"flex"}
							flexDirection="column"
							alignItems={"flex-end"}
							overflow={"auto"}

						>
							{
								prepKit &&
								prepKit.products &&
								prepKit.products.map(p => {
									return (
										<Box
											key={`product-${p.id}`}
											maxWidth="500px"
											sx={[
												{
													border: `${bw}px solid red`,
													width: selectedTopic ? '70%' : '100%',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center'
												},
												sx
											]}
										>
											<h3>{p.name}</h3>
											<Box mt={2}>
												{
													p.topics.map(t => {
														return <Button
															fullWidth={true}
															key={`topic-${t.id}`}
															onClick={() => onTopicClick(t)}
															variant="outlined"
															size="large"
															sx={navButtonSx}
															endIcon={<ArrowForward/>}
														>
															{t.name}
														</Button>
													})
												}
											</Box>
										</Box>
									)
								})
							}
						</Box>
					</Box>
					<Box
						border={`${bw*2}px solid green`}
						width={selectedTopic ? '50%' : '0'}
						borderLeft={`1px solid ${theme.palette.divider}`}
						overflow={"auto"}
						height={"800px"}
						sx={sx}
					>
						<Button onClick={() => onTopicClick(null)}>Close</Button>
						<Divider/>
						<TopicQuestionToc selectedTopic={selectedTopic}
								  answers={prepKit && prepKit.answers}
								  onTopicClick={onTopicClick}
								  onQuestionClick={()=>alert(1)}
						/>
					</Box>
				</Box>
			</Box>
	}
}

const TocDrawer = ({prepKit, showToc, setShowToc, onTopicClick, onQuestionClick}) => {

	const theme = useTheme()

	return <Drawer open={showToc}
	               onClose={() => setShowToc(false)}
	               anchor="left"
	>
		<Box sx={{
			width: '800px',
			margin: '0 auto',
			height: '100vh'
		}}>
			<Box
				sx={{p: 2}}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box>
					<h2 style={{fontWeight: 'normal'}}>{prepKit && prepKit.name}</h2>
					{!prepKit && <CircularProgress size={18}/>}
				</Box>
				<CircularProgressWithLabel
					color={theme.palette.success.light}
					label={60}
					value={80}
					size={72}
					thickness={4}
					labelSize={22}
				/>

			</Box>
			<Divider/>
			{
				prepKit &&
				prepKit.products &&
				prepKit.products.map(product => {
					return <Box key={`product-${product.id}`}>
						<h2>{product.name}</h2>
						<Divider/>
						<TopicToc topics={product.topics}
						          answers={prepKit && prepKit.answers}
						          onTopicClick={onTopicClick}
						          onQuestionClick={onQuestionClick}
						/>
					</Box>
				})
			}
		</Box>
		<Fab
			sx={{
				position: 'absolute',
				bottom: 10,
				right: 10,
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}}
			onClick={() => {
				setShowToc(false)
			}}
		>
			<Close style={{color: theme.palette.error.main}}/>
		</Fab>
	</Drawer>
}

export default MiniPrepKit
export {MiniPrepKitContext}
