import React, { useEffect, useState }           from 'react'
import {
	Box,
	IconButton,
	CircularProgress,
}                                     from '@mui/material'
import PlayArrowIcon                  from '@mui/icons-material/PlayArrow'
import PauseIcon                      from '@mui/icons-material/Pause'
import {PlayArrow, PlayArrowOutlined} from "@mui/icons-material"
import {fetchSignedMediaUrl}          from "../../util/admin"

const AudioPlayer = ({ audioUrl }) => {
	const [isPlaying, setIsPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const [signedUrl, setSignedUrl] = useState(null)
	const [playedOnce, setPlayedOnce] = useState(false)
	const audioRef = React.useRef(null)

	useEffect(() => {
		setSignedUrl(null) // reset if audioUrl changes
	}, [audioUrl])

	useEffect(() => {
		const updateProgress = () => {
			if (audioRef.current) {
				const currentTime = audioRef.current.currentTime
				const duration = audioRef.current.duration
				const progressPercentage = (currentTime / duration) * 100
				setProgress(progressPercentage)
			}
		}

		if (isPlaying) {
			const intervalId = setInterval(updateProgress, 100)
			return () => clearInterval(intervalId)
		}
	}, [isPlaying])

	const togglePlay = async () => {
		if(!playedOnce) setPlayedOnce(true)
		if(!signedUrl) {
			// first time user clicked play, so fetch the signed url which will render the audio
			// element, which will then autoplay
			setSignedUrl(await fetchSignedMediaUrl(audioUrl))
			setIsPlaying(true)
		}
		else {
			if (isPlaying) {
				audioRef.current.pause()
			}
			else {
				audioRef.current.play()
			}
			setIsPlaying(!isPlaying)
		}
	}

	return (
		<Box display="flex"
		     alignItems="center"
		     onClick={(event) => {
				event.preventDefault()
				event.stopPropagation()
			}}
		>
			{ playedOnce && signedUrl &&
				<audio ref={audioRef}
				       src={signedUrl}
				       autoPlay={true}
				       onCanPlay={(event) => {
					       event.target.play()
					   }}
				       onEnded={() => {
								setIsPlaying(false)
								setProgress(100)
								setTimeout(() => {
									setProgress(0)
								}, 1000)
						}}
				/>
			}
			<Box position="relative" display="inline-flex">
				<CircularProgress variant="determinate" value={progress} />
				<IconButton onClick={togglePlay} sx={{ position: 'absolute', top: 0, left: 0 }}>
					{isPlaying ? <PauseIcon /> : <PlayArrow />}
				</IconButton>
			</Box>
		</Box>
	)
}

export default AudioPlayer
