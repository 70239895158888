import React from 'react';
import { useTheme, Box } from '@mui/material';

const PrettyJson = ({ data, fontSize }) => {
	const theme = useTheme();

	const syntaxHighlight = (json) => {
		if (typeof json !== 'string') {
			json = JSON.stringify(json, undefined, 2);
		}
		json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
		return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
			let cls = theme.palette.text.primary;
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = theme.palette.primary.main; // Object keys
				} else {
					cls = theme.palette.success.main; // Strings
				}
			} else if (/true|false/.test(match)) {
				cls = theme.palette.warning.main; // Booleans
			} else if (/null/.test(match)) {
				cls = theme.palette.error.main; // Nulls
			} else if (/\d/.test(match)) {
				cls = theme.palette.info.main; // Numbers
			}
			return '<span style="color: ' + cls + '">' + match + '</span>';
		});
	};

	return (
		<Box component="pre" sx={{ overflowX: 'auto', fontFamily: 'monospace', fontSize: (fontSize ? fontSize + "px" : "inherit")  }}>
			<Box component="code" dangerouslySetInnerHTML={{ __html: syntaxHighlight(data) }} />
		</Box>
	);
};

export default PrettyJson;
