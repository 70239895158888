const CONTENT_REVIEW = "content-review"
const RENDERING_ASSETS = "rendering-assets"
const ASSET_REVIEW = "asset-review"
const RENDERING_CLIPS = "rendering-clips"
const COMPLETED = "completed"

const MediaJobStatus = {}

MediaJobStatus[CONTENT_REVIEW] = {
	label: "TTS Content Review",
	value: CONTENT_REVIEW,
	color: "purple",
	index: 1
}
MediaJobStatus[RENDERING_ASSETS] = {
	label: "Rendering Assets",
	value: RENDERING_ASSETS,
	color: "purple",
	index: 2
}
MediaJobStatus[ASSET_REVIEW] = {
	label: "Asset Review",
	value: ASSET_REVIEW,
	color: "purple",
	index: 3
}
MediaJobStatus[RENDERING_CLIPS] = {
	label: "Rendering Clips",
	value: RENDERING_CLIPS,
	color: "purple",
	index: 4
}
MediaJobStatus[COMPLETED] = {
	label: "Completed",
	value: COMPLETED,
	color: "purple",
	index: 7
}

export {MediaJobStatus, CONTENT_REVIEW, RENDERING_ASSETS, ASSET_REVIEW, RENDERING_CLIPS, COMPLETED }