import React, { useCallback, useRef, useState, useEffect } from 'react'
import {
	Alert,
	AlertTitle,
	TextField,
	Button,
	Container,
	Box,
	Typography,
	useTheme,
	CircularProgress
} from '@mui/material'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { getSupabase } from "../../util/supabase-utils"
import { ArrowBack, Check } from "@mui/icons-material"

const supabase = getSupabase()

const ForgotPassword = ({ switchView }) => {
	const theme = useTheme()
	const [email, setEmail] = useState('')
	const [sent, setSent] = useState(false)
	const [sending, setSending] = useState(false)
	const [error, setError] = useState('')
	const [captchaToken, setCaptchaToken] = useState('')
	const captchaRef = useRef(null)
	const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY

	const resetCaptcha = useCallback(() => {
		if (captchaRef.current) {
			captchaRef.current.resetCaptcha()
		}
		setCaptchaToken('')
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (captchaRef.current) {
				captchaRef.current.execute()
			}
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	const handleForgotPassword = useCallback(async () => {
		if (!email) {
			setError('Please enter your email address.')
			return
		}
		if (!captchaToken) {
			setError('Please complete the captcha to verify you are not a bot.')
			return
		}

		setSending(true)
		setError('')

		try {
			const { error } = await supabase.auth.resetPasswordForEmail(email, {
				redirectTo: `${window.location.origin}/update-password`,
				captchaToken: captchaToken
			})
			if (error) throw error
			setSent(true)
		} catch (error) {
			setError(error.message)
		} finally {
			setSending(false)
			resetCaptcha()
		}
	}, [email, captchaToken, resetCaptcha])

	return (
		<Container>
			<Box component="form" mt={3} mb={0}>
				<Typography variant="h6" sx={{cursor: 'pointer'}} onClick={() => switchView('')} display="flex" alignItems="center">
					<ArrowBack sx={{mr: 0.5}}/> Forgot password?
				</Typography>
				<Box>
					{!sent && (
						<TextField
							disabled={sending}
							label="Email"
							fullWidth
							margin="normal"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					)}
					{error && <Typography color="error">{error}</Typography>}
					{sent && (
						<Alert sx={{mt: 2}} onClose={() => setSent(false)}>
							<AlertTitle>Reset Email Sent</AlertTitle>
							A link was sent to you at {email}. Please check your email and click the link to continue.
						</Alert>
					)}
					<Box mt={1} display="flex" alignItems="center" justifyContent="center">
						<HCaptcha
							ref={captchaRef}
							size="invisible"
							sitekey={captchaSiteKey}
							onVerify={setCaptchaToken}
							theme={theme.palette.mode}
							onError={setError}
						/>
					</Box>
					{!sent && (
						<Button
							variant="outlined"
							color="primary"
							size="large"
							fullWidth
							disabled={sending}
							sx={{ py: 2 }}
							onClick={handleForgotPassword}
						>
							{sending && <CircularProgress size={16} sx={{mr: 1, color: theme.palette.primary.contrastText}}/>}
							{sending ? 'Sending...' : 'Reset Password'}
						</Button>
					)}
					<Box pt={3} pb={1}>
						{captchaToken && (
							<Box display="flex" justifyContent="center" alignItems="center">
								<Check color="success" fontSize="small" />
								<Typography variant="body2" ml={1}>
									Verified Human
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Container>
	)
}

export default ForgotPassword