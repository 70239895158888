import React           from "react"
import {Box} from "@mui/material"

function SlideView({views, index, top = 75}) {

	return <Box
		sx={{
			position: "fixed",
			top: top,
			left: 0,
			right: 0,
			bottom: 0,
			border: "0px solid yellow",
			overflowX: "hidden"
		}}
	>
		<Box
			sx={{
				display: "flex",
				width: `${views ? views.length : 1}00vw`,
				marginLeft: `${(index * -100)}vw`,
				transition: "margin-left 550ms ease-in-out", // Ensure marginLeft is used in the transition
			}}
		>
			{
				views &&
				views.map((view, i) => (
					<Box
						key={`view-${i}`}
						sx={{
							width: "100vw"
						}}
					>
						{view}
					</Box>
				))
			}
		</Box>
	</Box>
}

export default React.memo(SlideView)