import React, {useEffect, useState}                        from 'react'
import {TextField, IconButton, useTheme, CircularProgress} from '@mui/material'
import CheckIcon                                           from '@mui/icons-material/Check'
import CloseIcon                         from '@mui/icons-material/Close'
import EditableTree                      from "./EditableTree"
import {Edit}                            from "@mui/icons-material"


const EditableLabel = ({ label, editing, multiline, placeholder, fullWidth, onSave, onCancel, style, showEditIcon, isSaving }) => {
	const theme = useTheme()
	const [isEditing, setIsEditing] = useState(true)
	const [editValue, setEditValue] = useState(label || "")
	const [isHovered, setIsHovered] = useState(false)

	useEffect(() => {
		setEditValue(label)
		setIsEditing(false)
	}, [label])

	useEffect(() => {
		setIsEditing(editing)
	}, [editing])

	const handleSave = () => {
		onSave(editValue)
		setIsEditing(false)
	}

	const handleCancel = () => {
		setEditValue(label)
		setIsEditing(false)
		onCancel && onCancel()
	}

	const handleKeyPress = (event) => {
		if (!multiline && event.key === 'Enter') {
			handleSave()
		}
	}

	return <div style={style}>
		{
			isEditing ? (
				<div>
					<TextField
						size="small"
						value={editValue}
						placeholder={placeholder}
					    autoFocus
						rows={editValue ? Math.max(editValue.split('\n').length, 5) : 5}
						multiline={multiline}
						onChange={(e) => setEditValue(e.target.value)}
						onKeyPress={handleKeyPress}
						fullWidth={fullWidth}
					/>
					{isSaving
						? <IconButton disabled={true}><CircularProgress size={16}/></IconButton>
						: <IconButton onClick={handleSave}>
							<CheckIcon sx={{color: isSaving ? theme.palette.text.disabled : theme.palette.score.success}}/>
						  </IconButton>
					}

					<IconButton disabled={isSaving} onClick={handleCancel}>
						<CloseIcon
						           sx={{color: isSaving
								           ? theme.palette.text.disabled
								           : theme.palette.score.error
								   }}/>
					</IconButton>
				</div>
			) : (
				<div onClick={() => setIsEditing(true)}
				     onMouseEnter={() => setIsHovered(true)}
				     onMouseLeave={() => setIsHovered(false)}
				     style={{
					     cursor: 'pointer',
					     color: label ? theme.palette.text.primary : theme.palette.text.disabled,
					     backgroundColor: isHovered ? theme.palette.action.hover : 'transparent',
					     padding: '4px',
					     borderRadius: '4px'
				     }}
				>
					{
						label
						? label.split('\n').map((line, index) => (
									<p key={index} style={{ margin: 0 }}>
										{line}
									</p>
								))
						: placeholder
					}
					{
						showEditIcon &&
							<IconButton
								onClick={() => setIsEditing(true)}
								sx={{marginLeft: '15px'}}
							>
								<Edit/>
							</IconButton>
					}
				</div>
			)
		}
	</div>
}

export default EditableLabel
