import React, {useEffect}  from "react"
import { useTheme } from '@mui/material/styles'

function ThemeCssInjector() {
	const theme = useTheme()

	const injectVariables = (obj, prefix = '') => {
		let cssVariables = {}

		for (let key in obj) {
			if (typeof obj[key] === 'string') {
				// Convert camelCase to kebab-case
				const kebabKey = key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
				cssVariables[`--${prefix}${kebabKey}`] = obj[key]
			} else if (typeof obj[key] === 'object') {
				// Recursive call for nested objects
				cssVariables = {
					...cssVariables,
					...injectVariables(obj[key], `${prefix}${key}-`)
				}
			}
		}

		return cssVariables
	}

	useEffect(() => {
		const root = document.documentElement
		const paletteVariables = injectVariables(theme.palette)

		for (let key in paletteVariables) {
			root.style.setProperty(key, paletteVariables[key])
		}
	}, [theme])

	return null
}

export default React.memo(ThemeCssInjector)