import React, {useEffect, useState}                                   from 'react'
import {
	Badge,
	Box,
	Button,
	Checkbox, Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider, FormControl, FormControlLabel,
	IconButton, InputLabel, Link,
	MenuItem, OutlinedInput, Pagination,
	Paper,
	Select,
	styled, Switch, Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow, Tabs,
	TextField, ToggleButton, ToggleButtonGroup, Tooltip,
	useTheme,
} from '@mui/material'
import Grid                                                           from '@mui/material/Unstable_Grid2' // Grid version 2
import {logger}                              from "../../util/log-utils"
import {fmt2Dec, fmtDttm, fmtDur, fmtMinSec} from "../../util/prepkit-utils"
import {
	Check,
	CheckBox,
	CheckBoxOutlineBlank,
	Close, Filter, Filter1, FilterAlt, FilterAltOff,
	Image, Panorama,
	PanoramaOutlined,
	PresentToAllOutlined, VideoCameraBack, VideoCameraBackOutlined,
	YouTube
}                            from "@mui/icons-material"
import {PieChart}            from '@mui/x-charts/PieChart'
import {doPut, getUrl}       from "../../util/do-fetch"
import AudioPlayer           from "./AudioPlayer"
import AssetPreviewDialog    from "./AssetPreviewDialog"
import VideoPreviewDialog    from "./VideoPreviewDialog"
import HoverTableRow         from "../HoverTableRow"
import {fetchSignedMediaUrl} from "../../util/admin"
import moment                                            from 'moment'
import {ASSET_REVIEW, RENDERING_ASSETS, RENDERING_CLIPS, COMPLETED} from "../../util/media-job-status"


const log = logger("MediaJobProcessing", 1)

function MediaJobProcessing({product, mediaJob, mediaJobItems, onReRender}) {

	const theme = useTheme()
	const [filtered, setFiltered] = useState(null)
	const [filteredJobType, setFilteredJobType] = useState('')
	const [filteredStatuses, setFilteredStatuses] = useState(["processing", "failed"])
	const [filteredMediaType, setFilteredMediaType] = useState('image')
	const [filteredProcessId, setFilteredProcessId] = useState('')
	const [filter, setFilter] = useState('')
	const [selected, setSelected] = useState([])
	const [allSelected, setAllSelected] = useState(false)
	const [previewUrls, setPreviewUrls] = useState(null)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [statusCounts, setStatusCounts] = useState(null)
	const [statusDurations, setStatusDurations] = useState(null)
	const [showContent, setShowContent] = useState(false)
	const [showLive, setShowLive] = useState(true)
	const [processIds, setProcessIds] = useState(null)
	const [autoPlay, setAutoPlay] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [page, setPage] = useState(null)
	const [pageCount, setPageCount] = useState(0)
	const pageSize = 14

	const jobTypes = [
		"section",
		"title",
		"question",
		"option-a",
		"option-c",
		"option-b",
		"option-d",
		"answer",
		"explanation"
	]
	const statuses = [
		"completed",
		"queued",
		"processing",
		"failed"
	]
	const mediaTypes = [
		"image",
		"audio",
		"video"
	]

	useEffect(() => {
		if (!previewUrls || !previewUrls.item) {
			setIsDialogOpen(false)
		}
	}, [previewUrls])

	useEffect(() => {
		if (!mediaJobItems) {
			setFiltered(null)
			return
		}

		const updates = {
			jobTypes: {},
			mediaTypes: {},
			statuses: {},
			processIds: {}
		}

		// do counts
		const counts = {
			image: {
				active: [RENDERING_ASSETS, ASSET_REVIEW, COMPLETED].some(s => s===mediaJob.status)
			},
			audio: {
				active: [RENDERING_ASSETS, ASSET_REVIEW, COMPLETED].some(s => s===mediaJob.status)
			},
			video: {
				active: [RENDERING_CLIPS, COMPLETED].some(s => s===mediaJob.status)
			}
		}
		const durations = {
			image: {
				started_at: null,
				completed_at: null,
			},
			audio: {
				started_at: null,
				completed_at: null,
			},
			video: {
				started_at: null,
				completed_at: null,
			},
			total: {
				started_at: null,
				completed_at: null
			}
		}
		mediaJobItems.forEach(i => {
			if (i.process_id) updates.processIds[i.process_id] = i.process_id
			const count = counts[i.media_type]
			if (!count[i.status]) {
				count[i.status] = 1
			}
			else {
				count[i.status] = count[i.status] + 1
			}

			// if this item's started_at is before durations[i.media_type].start, then update it
			if(i.started_at &&
				(
					!durations[i.media_type].started_at
					||
					moment(i.started_at).valueOf() < moment(durations[i.media_type].started_at).valueOf()
				)
			) {
				durations[i.media_type].started_at = i.started_at
			}

			// if this item is completed_at, and it is after durations[i.media_type].end, the update it
			if(i.completed_at &&
				(
					!durations[i.media_type].completed_at
					||
					moment(i.completed_at).valueOf() > moment(durations[i.media_type].completed_at).valueOf()
				)
			) {
				durations[i.media_type].completed_at = i.completed_at
			}
		})

		durations.image.duration = Math.max(moment(durations.image.completed_at || 0).diff(moment(durations.image.started_at || 0)), 0)
		durations.audio.duration = Math.max(moment(durations.audio.completed_at || 0).diff(moment(durations.audio.started_at || 0)), 0)
		durations.video.duration = Math.max(moment(durations.video.completed_at || 0).diff(moment(durations.video.started_at || 0)), 0)
		durations.total.started_at = durations.image.started_at
		durations.total.completed_at = durations.video.completed_at
		durations.total.duration = Math.max(moment(durations.total.completed_at || 0).diff(moment(durations.total.started_at || 0)), 0)
		durations.total.processing = durations.image.duration + durations.audio.duration + durations.video.duration

		setProcessIds(Object.keys(updates.processIds))
		setStatusCounts(counts)
		setStatusDurations(durations)

		let items = sortItemsByPlayOrder(mediaJobItems)

		if(showLive) {
			items = items.filter(i => i.started_at && !i.completed_at)

			const now = new Date().getTime()

			items = items.filter(i => {
				// Check if the item is not yet completed
				if (!i.completed_at) {
					return true
				}
				const completedAt = new Date(i.completed_at).getTime()
				return (now - completedAt) < 5000 // less than 5 seconds ago
			})
		}
		else {
			if (filter) items = items.filter(i => i.file_name.indexOf(filter)!==-1)
			if (filteredMediaType) items = items.filter(i => i.media_type === filteredMediaType)
			if (filteredJobType) items = items.filter(i => i.job_type === filteredJobType)
			if (filteredStatuses && filteredStatuses.length > 0) items = items.filter(i => filteredStatuses.some(s => s===i.status))
			if (filteredProcessId) items = items.filter(i => i.process_id === filteredProcessId)
		}

		setFiltered(items)

	}, [mediaJobItems, filter, filteredMediaType, filteredJobType, filteredStatuses, filteredProcessId, showLive])

	useEffect(() => {
		setAllSelected(selected && filtered && filtered.length > 0 && (selected.length === filtered.length))
	}, [selected, filtered])

	useEffect(() => {
		if (pageCount > pageNumber) {
			setPageNumber(1)
		}
	}, [pageCount])

	useEffect(() => {
		if (!filtered) {
			setPage(null)
			setPageCount(1)
		}
		else {
			const start = (pageNumber-1) * pageSize
			const end = start + pageSize
			setPage(filtered.slice(start, end))
			setPageCount(Math.ceil(filtered.length / pageSize) || 1)
		}

	}, [filtered, pageNumber])

	const sortItemsByPlayOrder = (items) => {
		return items.sort((a, b) => {

			const akey = `${a.topic_id}_${a.question_id}`
			const bkey = `${b.topic_id}_${b.question_id}`

			// First, sort by topic_id
			if (akey !== bkey) {
				return akey - bkey
			}

			// Then, sort by question_id
			if (a.question_id !== b.question_id) {
				// Handle null values for question_id
				if (a.question_id === null) return 1;
				if (b.question_id === null) return -1;
				return a.question_id - b.question_id;
			}

			// If question_id is the same, sort by job_type
			if (a.question_id !== null && b.question_id !== null) {
				const jobTypeOrder = [
					'question',
					'option-a',
					'option-b',
					'option-c',
					'option-d',
					'answer',
					'explanation',
				];
				return jobTypeOrder.indexOf(a.job_type) - jobTypeOrder.indexOf(b.job_type);
			}

			// If all criteria are equal, maintain the current order
			return 0;
		});
	}

	const handleSaveContent = async (mediaJobItem, content) => {
		try {
			const body = {content: content}

			const options = {
				body: JSON.stringify(body)
			}
			const response = await doPut(
				`/admin/products/${product.id}/media-jobs/${mediaJobItem.media_job_id}/item/${mediaJobItem.id}`,
				options
			)
			const data = await response.json()

			if (!data.success) {
				throw "Error updating product"
			}

			return data
		}
		catch (err) {
			log.error(err)
		}
	}

	const isSelected = (id) => {
		return selected.indexOf(id) !== -1
	}

	const handleClickMediaItemRow = (event, mediaJobItem) => {
		const id = mediaJobItem.id
		const selectedIndex = selected.indexOf(id)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id)
		}
		else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		}
		else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		}
		else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}
		setSelected(newSelected)
	}

	const toggleAllSelected = () => {
		if (allSelected) {
			setSelected([])
		}
		else {
			setSelected(filtered && filtered.map(i => i.id))
		}
	}

	const setPreviewUrlsGracefully = (next) => {
		setIsDialogOpen(true) // Ensure the dialog stays open
		setPreviewUrls(next)
	}

	const handlePreviewPreviousVideo = () => {
		const item = previewUrls.item
		const indexOf = mediaJobItems.findIndex(i=>i.id === item.id)
		// find the video prior to this one
		for (let i = indexOf-1; i >= 0; i--) {
			if(mediaJobItems[i].media_type === 'video') {
				setPreviewUrlsGracefully({
					item: mediaJobItems[i],
					video: getMediaJobItemUrl(mediaJobItems[i]),
				})
				return
			}
		}
	}

	const handlePreviewNextVideo = () => {
		const item = previewUrls.item
		const indexOf = mediaJobItems.findIndex(i=>i.id === item.id)
		// find the video prior to this one
		for (let i = indexOf+1; i < mediaJobItems.length; i++) {
			if(mediaJobItems[i].media_type === 'video') {
				setPreviewUrlsGracefully({
					item: mediaJobItems[i],
					video: getMediaJobItemUrl(mediaJobItems[i]),
				})
				return
			}
		}
	}

	const handlePreviewPreviousImage = () => {
		const item = previewUrls.item
		const indexOf = mediaJobItems.findIndex(i=>i.id === item.id)
		// find the video prior to this one
		for (let i = indexOf-1; i >= 0; i--) {
			if(mediaJobItems[i].media_type === 'image') {
				setPreviewUrlsGracefully({
					item: mediaJobItems[i],
					image: getMediaJobItemUrl(mediaJobItems[i]),
					audio: getMediaJobItemUrl(mediaJobItems[i]).replace('mp3', 'mp4')
				})
				return
			}
		}
	}

	const handlePreviewNextImage = () => {
		const item = previewUrls.item
		const indexOf = mediaJobItems.findIndex(i=>i.id === item.id)
		// find the video prior to this one
		for (let i = indexOf+1; i < mediaJobItems.length; i++) {
			if(mediaJobItems[i].media_type === 'image') {
				setPreviewUrlsGracefully({
					item: mediaJobItems[i],
					image: getMediaJobItemUrl(mediaJobItems[i]),
					audio: getMediaJobItemUrl(mediaJobItems[i]).replace('/image/', '/audio/').replace('.png', '.mp3')
				})
				return
			}
		}
	}

	const handleToggleAutoPlay = (newAutoPlay) => {
		setAutoPlay(newAutoPlay)
	}

	const getMediaJobItemUrl = (mediaJobItem) => {
		return`/admin/products/${product.id}/media-jobs/${mediaJob.id}/media-url/${mediaJobItem.media_type}/${mediaJobItem.file_name}`
	}

	const getAudioUrlFromImageMediaJobItem = (mediaJobItem) => {
		const imageUrl = getMediaJobItemUrl(mediaJobItem)
		return imageUrl.replace('/image/', '/audio/').replace('png', 'mp3')
	}

	const downloadMediaJobItemFile = async (mediaJobItem) => {
		const url = getMediaJobItemUrl(mediaJobItem)
		const signedUrl = await fetchSignedMediaUrl(url)

		const link = document.createElement('a')
		link.href = signedUrl
		link.download = mediaJobItem.file_name
		link.target = "_media-item-download"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	if (!mediaJobItems) return null

	const colors = {
		completed: theme.palette.success.light,
		processing: theme.palette.info.light,
		queued: theme.palette.warning.light,
		failed: theme.palette.error.light
	}

	return <Grid xs={12} container >
		<Grid xs={12}>
			<Grid container xs={12} justifyContent="center" alignItems="flex-start">
				{
					statusCounts && Object.keys(statusCounts)
						.map((mediaType, i) => {
							// image, audio, video
							const mediaCount = {...statusCounts[mediaType]}
							const active = mediaCount.active
							delete  mediaCount.active
							return <Grid key={`chart-${mediaType}`}
							            sx={{px: 1, border: '0px solid red'}}
							            xs={12}
							            sm={4}
							>
								<Box sx={{opacity: active ? 1 : 0.5}}
								     display="flex"
								     flexDirection="row"
								     justifyContent="center"
								     alignItems="center"
								>
									<PieChart
										sx={{
											border: '0px solid blue',
											p: 2
										}}
										series={[
											{
												data: Object.keys(mediaCount)
													.map((k, i) => {
														return {
															id: k,
															value: mediaCount[k] || 20,
															label: `${k}`,
															color: colors[k]
														}
													})
											}
										]}
										slotProps={{
											legend: {
												direction: 'row',
												position: {vertical: 'bottom', horizontal: 'middle'},
												padding: 0,
												hidden: true
											},
										}}
										margin={{top: 0, bottom: 0, left: 0, right: 0}}
										width={200}
										height={165}
									/>
									<pre style={{width: '300px', minHeight: '115px', border: '1px solid ' + theme.palette.divider}}>
										{
											<Box sx={{p: 1}} display="flex" justifyContent="space-between">
												<b>{mediaType}</b>
												<b>
													{fmtMinSec(statusDurations[mediaType].duration)}
												</b>
											</Box>
										}
										<Divider/>
										{
											mediaCount && Object.keys(mediaCount)
												.map((k, i) => {
													return <div key={`legend-${mediaType}-${k}`} style={{padding: '5px'}}>
														<b style={{
															color: colors[k],
															textTransform: 'capitalize'
														}}>{k}: </b> {mediaCount[k]}
													</div>
												})
										}
									</pre>
								</Box>
							</Grid>
						})
				}
			</Grid>
			<Grid container pb={6} justifyContent="center" alignItems="center">
				<pre>
					<b>total processing: {statusDurations && fmtMinSec(statusDurations.total.processing)}</b>
					&nbsp;&middot;&nbsp;
					<b>total duration: {statusDurations && fmtMinSec(statusDurations.total.duration)}</b>
				</pre>
			</Grid>

			<Box pb={2} display="flex" justifyContent="center" alignItems="center">
				<ToggleButtonGroup
					color="primary"
					value={filteredMediaType}
					exclusive={true}
					centered="true"
					onChange={(event, newTab) => setFilteredMediaType(newTab)}
				>
					{
						mediaTypes && mediaTypes.map(type => (
							<ToggleButton disabled={showLive} sx={{fontSize: 16, px: 5}} key={`tab-${type}`} value={type}>
								{type}
							</ToggleButton>
						))
					}
				</ToggleButtonGroup>
			</Box>
			<TableContainer >
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{backgroundColor: theme.palette.background.default}}/>
							<TableCell sx={{backgroundColor: theme.palette.background.default}}>
								<TextField
									disabled={showLive}
									size="small"
									placeholder="ID Filter"
									value={filter}
									onChange={(e) => setFilter(e.target.value || '')}
								/>
							</TableCell>
							<TableCell sx={{backgroundColor: theme.palette.background.default}}>
								<Select
									disabled={showLive}
									labelId="process-id-select-label"
									id="process-id-select"
									value={filteredProcessId}
									label="Process ID"
									size="small"
									onChange={(e) => setFilteredProcessId(e.target.value || '')}
								>
									<MenuItem key={-1} value={null}>All</MenuItem>
									{processIds && processIds.map((pid, index) => (
										<MenuItem key={index} value={pid}>
											{pid.slice(0, 3)}...{pid.slice(-3)}
										</MenuItem>
									))}
								</Select>
							</TableCell>
							<TableCell sx={{backgroundColor: theme.palette.background.default}}>
								<Select
									disabled={showLive}
									labelId="job-type-select-label"
									id="job-type-select"
									value={filteredJobType}
									label="Job Type"
									size="small"
									onChange={(e) => setFilteredJobType(e.target.value || '')}
								>
									<MenuItem key={-1} value={null}>All</MenuItem>
									{jobTypes && jobTypes.map((type, index) => (
										<MenuItem key={index} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</TableCell>
							<TableCell sx={{backgroundColor: theme.palette.background.default}} colSpan={showContent ? 1 : 4}>
								<Select
									disabled={showLive}
									multiple
									value={filteredStatuses}
									onChange={(event) => {
											const {target: { value }} = event;
											console.log({value})
											setFilteredStatuses(
												// On autofill we get a stringified value.
												Array.isArray(value) ? value : value && value.split(',')
											);
									}}
									input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
									renderValue={(selected) => (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((value) => (
												<Chip size="small" key={value} label={value} />
											))}
										</Box>
									)}
								>
									{statuses.map((status) => (
										<MenuItem
											key={status}
											value={status}
										>
											{status}
										</MenuItem>
									))}
								</Select>
								<FormControlLabel
									sx={{ml: 2}}
									control={
										<Switch checked={showLive}
										        onChange={()=>setShowLive(!showLive)}
										/>
									}
									label="Live"
								/>
							</TableCell>
							<TableCell colSpan={showContent ? 2 : 6} sx={{backgroundColor: theme.palette.background.default}}>
								<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
									<FormControlLabel
										control={
											<Switch checked={showContent}
											        onChange={()=>setShowContent(!showContent)}
											/>
										}
										label="Show Content"
									/>

									<Badge badgeContent={selected.length}
									       invisible={!selected || selected.length === 0}
									       color="error"
									       max={9999}
									>
										<Button variant="contained"
										        disabled={!selected || selected.length === 0}
										        onClick={async () => {
											        await onReRender(selected)
											        setSelected([])
												}}
										>
											Re-Render Selected
										</Button>
									</Badge>
								</Box>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell padding="checkbox">
								<IconButton onClick={toggleAllSelected}>
									{allSelected
										? <CheckBox/>
										: <CheckBoxOutlineBlank/>
									}
								</IconButton>
							</TableCell>
							<TableCell style={{fontWeight: "bold"}}>ID</TableCell>
							<TableCell style={{fontWeight: "bold"}}>Proc ID</TableCell>
							<TableCell style={{fontWeight: "bold"}}>Job Type</TableCell>
							<TableCell style={{fontWeight: "bold"}}>Status</TableCell>
							{
								showContent
									? <TableCell key="th-content" style={{fontWeight: "bold"}}>Content</TableCell>
									: [
										<TableCell key="th-start" style={{fontWeight: "bold"}} align="right">Started</TableCell>,
										<TableCell key="th-complete" style={{fontWeight: "bold"}} align="right">Completed</TableCell>,
										<TableCell key="th-dur" style={{fontWeight: "bold"}} align="right">Duration</TableCell>,
										<TableCell key="th-fs" style={{fontWeight: "bold"}}>File</TableCell>,
										<TableCell key="th-size" style={{fontWeight: "bold"}}>Size</TableCell>
									  ]
							}
							<TableCell style={{fontWeight: "bold"}} align="center">Media</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							page && page.map(i => {
								const isItemSelected = isSelected(i.id)
								const labelId = `table-row-checkbox-${i.id}`
								let mediaComp = null

								if (i.status === 'processing') {
									mediaComp = <CircularProgress size={24}/>
								}
								else if (i.status === 'completed') {
									switch (i.media_type) {
										case 'image':
											mediaComp = <IconButton
												onClick={(e) => {
													e.stopPropagation()
													setPreviewUrls({
														item: i,
														image: getMediaJobItemUrl(i),
														audio: getAudioUrlFromImageMediaJobItem(i)
													})
												}}
											>
												<Panorama color="info"/>
											</IconButton>
											break
										case 'audio':
											mediaComp = <AudioPlayer audioUrl={getMediaJobItemUrl(i)} />
											break
										case 'video':
											mediaComp = <IconButton
												onClick={(e) => {
													e.stopPropagation()
													setPreviewUrls({
														item: i,
														video: getMediaJobItemUrl(i)
													})
												}}
											>
												<VideoCameraBack color="secondary"/>
											</IconButton>
											break
									}
								}

								return <HoverTableRow
									key={i.id}
									role="checkbox"
									style={{cursor: 'pointer'}}
									aria-checked={isItemSelected}
									tabIndex={-1}
									selected={isItemSelected}
									onClick={(event) => handleClickMediaItemRow(event, i)}
								>
									<TableCell padding="checkbox">
										<Checkbox
											color="primary"
											checked={isItemSelected}
											inputProps={{
												'aria-labelledby': labelId,
											}}
										/>
									</TableCell>
									<TableCell>
										{i.id} / {i.topic_id}{i.question_id && ` / ${i.question_id}`}
									</TableCell>
									<TableCell>
										{
											filteredProcessId === i.process_id
												? <Tooltip title="Clear filter">
													<IconButton
														onClick={(e) => {
															e.stopPropagation()
															setFilteredProcessId(null)
														}}
													>
														<FilterAltOff/>
													</IconButton>
												  </Tooltip>
												: <Tooltip title={`Filter by ${i.process_id}`}>
													<IconButton
														onClick={(e) => {
															e.stopPropagation()
															setFilteredProcessId(i.process_id)
														}}
													>
														<FilterAlt/>
													</IconButton>
												  </Tooltip>
										}
									</TableCell>
									<TableCell>
										{i.job_type}
									</TableCell>
									<TableCell>
										{i.status}
										{i.status === "failed" && <p>{i.message}</p>}
									</TableCell>

									{
										showContent
											? <TableCell key="tc-content" sx={{maxWidth: '500px'}}>{i.content}</TableCell>
											: [
												<TableCell key="tc-start" align="right">
													{fmtDttm(i.started_at)}
												</TableCell>,
												<TableCell key="tc-complete" align="right">
													{fmtDttm(i.completed_at)}
												</TableCell>,
												<TableCell key="tc-dur" align="right">
													{fmtDur(i.started_at, i.completed_at)}
												</TableCell>,
												<TableCell key="tc-url">
													{
														i.status === 'completed'
															? <Link onClick={(e) => {
																        e.stopPropagation()
																		downloadMediaJobItemFile(i)
															        }}
															  >
																{i.file_name}
															  </Link>
															: i.file_name
													}
												</TableCell>,
												<TableCell key="tc-fs">
													{i.file_size}
												</TableCell>,
											]
									}
									<TableCell align="center" sx={{p: '6px'}}>
										<Box display="flex" justifyContent="center" alignItems="center">
											{mediaComp}
										</Box>
									</TableCell>
								</HoverTableRow>
							})
						}
					</TableBody>
				</Table>
			</TableContainer>

			<AssetPreviewDialog open={previewUrls && previewUrls.image}
			                    imageUrl={previewUrls && previewUrls.image}
			                    audioUrl={previewUrls && previewUrls.audio}
			                    autoPlay={autoPlay}
			                    onClose={() => setPreviewUrls(null)}
			                    onToggleAutoPlay={handleToggleAutoPlay}
			                    onPrevious={handlePreviewPreviousImage}
			                    onNext={handlePreviewNextImage}
			/>
			<VideoPreviewDialog open={previewUrls && previewUrls.video}
								videoUrl={previewUrls && previewUrls.video}
								autoPlay={autoPlay}
								onClose={() => setPreviewUrls(null)}
								onToggleAutoPlay={handleToggleAutoPlay}
			                    onPrevious={handlePreviewPreviousVideo}
			                    onNext={handlePreviewNextVideo}
			/>
			<Box
				sx={{pt: 3, pb: 2}}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<Pagination count={pageCount} page={pageNumber} onChange={(e,v)=>setPageNumber(v)} />
			</Box>
			{
				filtered &&
				mediaJobItems &&
				<Box sx={{pb: 12, textAlign: 'center'}}>
					<b>{filtered ? filtered.length : 0}</b> {filteredMediaType} items,
					<b> {mediaJobItems ? mediaJobItems.length : 0}</b> total
					<Divider/>
					<span>#={pageNumber} / ct={pageCount} / len={page && page.length}</span>
				</Box>
			}
		</Grid>
	</Grid>
}

function EditableContent({mediaJobItem, onSave}) {

	const theme = useTheme()
	const [saving, setSaving] = useState(false)
	const [content, setContent] = useState(null) // editing when not null

	return <Box>
		{
			content
				? <Box display="flex"
				       flexDirection="row"
				       justifyContent="space-between"
				       alignItems="center"
				>
					<TextField
						size="small"
						fullWidth={true}
						value={content}
						multiline={true}
						onChange={(e) => setContent(e.target.value)}
						autoFocus
					/>
					<Box display="flex"
					     flexDirection="row"
					>
						<IconButton
							disabled={saving}
							onClick={async () => {
								if (saving) return
								setSaving(true)
								await onSave(mediaJobItem, content)
								setSaving(false)
							}}
						>
							{
								saving
									? <CircularProgress/>
									: <Check sx={{color: theme.palette.success.main}}/>
							}
						</IconButton>
						<IconButton disabled={saving} onClick={() => setContent(null)}>
							<Close sx={{color: theme.palette.error.main}}/>
						</IconButton>
					</Box>
				</Box>
				: <Box
					onClick={() => setContent(mediaJobItem.content)}
					sx={{
						padding: '5px',
						'&:hover': {
							backgroundColor: theme.palette.action.hover
						},
					}}
				>
					{mediaJobItem.content}
				</Box>
		}

	</Box>
}

export default MediaJobProcessing
