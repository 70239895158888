import React from 'react'
import {Box} from "@mui/material"

function LeftRightCenter({left, right, center, spacing, ...props}) {
	spacing = spacing || 0
	return (
		<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{width: "100%"}} {...props}>
			<Box mr={spacing/2}>{left}</Box>
			<Box ml={spacing/2}>{center}</Box>
			<Box ml={spacing/2}>{right}</Box>
		</Box>
	)
}

export default LeftRightCenter
