import React, {useEffect, useState}                                   from 'react'
import {
	Badge,
	Box,
	Button,
	Checkbox,
	CircularProgress, Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider, FormControlLabel, FormGroup,
	IconButton, Link,
	MenuItem, Pagination,
	Paper,
	Select,
	styled, Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField, Typography,
	useTheme,
} from '@mui/material'
import Grid                                                           from '@mui/material/Unstable_Grid2' // Grid version 2
import {logger}                              from "../../util/log-utils"
import {fmt2Dec, fmtDttm, fmtDur, fmtMinSec} from "../../util/prepkit-utils"
import {
	ArrowBack,
	Check,
	CheckBox,
	CheckBoxOutlineBlank,
	Close, ContentCopy,
	Image, Panorama,
	PanoramaOutlined,
	PresentToAllOutlined, VideoCameraBack, VideoCameraBackOutlined,
	YouTube
} from "@mui/icons-material"
import {PieChart}             from '@mui/x-charts/PieChart'
import {doGet, doPut, getUrl} from "../../util/do-fetch"
import AudioPlayer            from "./AudioPlayer"
import AssetPreviewDialog    from "./AssetPreviewDialog"
import VideoPreviewDialog    from "./VideoPreviewDialog"
import HoverTableRow         from "../HoverTableRow"
import {fetchSignedMediaUrl} from "../../util/admin"
import moment                   from 'moment'
import {useNavigate, useParams} from "react-router-dom"


const log = logger("VideoJobProcessing", 1)

const types = [
	{key: "custom-clip", value: "Custom"},
	{key: "section", value: "Section"},
	{key: "title", value: "Title"},
	{key: "question", value: "Question"},
]

const statuses = [
	"completed",
	"queued",
	"processing",
	"failed"
]

function VideoJobProcessing() {

	const theme = useTheme()
	const navigate = useNavigate()
	const {id} = useParams()
	const [videoJob, setVideoJob] = useState(null)
	const [videoJobItems, setVideoJobItems] = useState(null)
	const [filtered, setFiltered] = useState(null)
	const [typeSelection, setTypeSelection] = useState(
		types.reduce((state, type) => ({ ...state, [type.key]: true }), {})
	)


	useEffect(() => {
		fetchVideoJob()
	}, [id])

	useEffect(() => {
		if(videoJob) {
			setVideoJobItems(videoJob.video_job_item)
		}
		else {
			setVideoJobItems(null)
		}
	}, [videoJob])

	useEffect(() => {
		if(videoJobItems) {
			const filteredItems = videoJobItems.filter(item =>
				typeSelection[item.ref_type]
			)
			setFiltered(filteredItems)
		}
		else {
			setFiltered(videoJobItems)
		}
	}, [videoJobItems, typeSelection])

	const fetchVideoJob = async () => {
		if(id) {
			try {
				const response = await doGet(`/admin/videos/jobs/${id}`)
				const data = await response.json()
				setVideoJob(data)
			}
			catch (err) {
				log.error(err)
			}
		}
		else {
			setVideoJob(null)
		}
	}

	const downloadVideoJobItemFile = async (videoJobItem) => {
		alert("do this!")
		/*
		const url = getVideoJobItemUrl(videoJobItem)
		const signedUrl = await fetchSignedMediaUrl(url)

		const link = document.createElement('a')
		link.href = signedUrl
		link.download = videoJobItem.file_name
		link.target = "_media-item-download"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		 */
	}

	const handleTypeCheckboxChange = (event) => {
		const { name, checked } = event.target
		setTypeSelection(prevState => ({
			...prevState,
			[name]: checked
		}))
	}

	const handleCopyTimeCodesClick = () => {
		// Create a string of time codes separated by newlines
		const timeCodeString = filtered.map(item => item.time_code).join('\n')

		// Copy the string to the clipboard
		navigator.clipboard.writeText(timeCodeString)
			.then(() => {
				console.log('Time codes copied to clipboard!')
			})
			.catch(err => {
				console.error('Failed to copy time codes to clipboard:', err)
			})
	}

	if (!videoJobItems) return null


	return <Container maxWidth="lg">
		<Grid xs={12} container>
			<Grid xs={12}>
				{/*
				<Grid container xs={12} justifyContent="center" alignItems="flex-start">
					{
						statusCounts && Object.keys(statusCounts)
							.map((mediaType, i) => {
								// image, audio, video
								const mediaCount = statusCounts[mediaType]
								return <Grid key={`chart-${mediaType}`}
								            sx={{px: 1, border: '0px solid red'}}
								            xs={12}
								            sm={4}
								>
									<PieChart
										sx={{
											border: '0px solid blue',
											p: 2
										}}
										series={[
											{
												data: Object.keys(mediaCount)
													.map((k, i) => {
														return {
															id: k,
															value: mediaCount[k] || 20,
															label: `${k}`,
															color: colors[k]
														}
													})
											}
										]}
										slotProps={{
											legend: {
												direction: 'row',
												position: {vertical: 'bottom', horizontal: 'middle'},
												padding: 0,
												hidden: true
											},
										}}
										margin={{top: 0, bottom: 0, left: 50, right: 50}}
										width={300}
										height={250}
									/>
									<pre style={{width: '300px', border: '1px solid ' + theme.palette.divider}}>
										{
											<Box sx={{p: 1}} display="flex" justifyContent="space-between">
												<b>{mediaType}</b>
												<b>{fmtMinSec(statusDurations[mediaType].duration)}</b>
											</Box>
										}
										<Divider/>
										{
											mediaCount && Object.keys(mediaCount)
												.map((k, i) => {
													return <div key={`legend-${mediaType}-${k}`} style={{padding: '5px'}}>
														<b style={{
															color: colors[k],
															textTransform: 'capitalize'
														}}>{k}: </b> {mediaCount[k]}
													</div>
												})
										}
									</pre>
								</Grid>
							})
					}
				</Grid>
				<Grid container sx={{pb: 3}} xs={12} justifyContent="center" alignItems="center">
					<pre>
						<b>total processing: {statusDurations && fmtMinSec(statusDurations.total.processing)}</b>
						<br/><b>total duration: {statusDurations && fmtMinSec(statusDurations.total.duration)}</b>
					</pre>
				</Grid>
				<Divider/>
				*/}
				<h2><IconButton sx={{mb: .5}} onClick={() => navigate(`/admin/videos`)}>
					<ArrowBack/>
				</IconButton> {videoJob && videoJob.title}</h2>
				<p>{videoJob.description}</p>

				<TableContainer component={Paper}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<Box display="flex"
									     flexDirection="row"
									     justifyContent="flex-end"
									     alignItems="center"
									>
										<FormGroup row>
											{types.map(type => (
												<FormControlLabel
													key={type.key}
													control={
														<Checkbox
															checked={typeSelection[type.key]}
															onChange={handleTypeCheckboxChange}
															name={type.key}
														/>
													}
													label={type.value}
												/>
											))}
											<Button
												variant="outlined"
												onClick={handleCopyTimeCodesClick}
											>
												<ContentCopy/>Copy Time Codes
											</Button>
										</FormGroup>

									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{fontWeight: "bold"}}>ID</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Status</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Type</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Key</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Started</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Duration</TableCell>
								<TableCell style={{fontWeight: "bold"}}>Time Code</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								filtered && filtered.map(i => {
									return <TableRow key={i.id}>
										<TableCell>{i.id}</TableCell>
										<TableCell>
											{i.status}
											{i.status === 'processing' && <CircularProgress sx={{ml: 1}} size={24}/> }
										</TableCell>
										<TableCell>{i.ref_type}</TableCell>
										<TableCell>{i.key}</TableCell>
										<TableCell>{fmtDttm(i.started_at)}</TableCell>
										<TableCell>{i.duration}</TableCell>
										<TableCell>{i.time_code}</TableCell>
									</TableRow>
								})
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	</Container>
}

export default VideoJobProcessing
