import React, {useMemo}                     from 'react'
import {Box, Tooltip, Typography, useTheme} from '@mui/material'
import {Circle, MoreHoriz}                  from "@mui/icons-material"
import {getColorForScore}                   from "../../util/utils"
import {fmtPct}                             from "../../util/prepkit-utils"

const ScoreDots = ({topic, size="7px", onClick}) => {

	const theme = useTheme()
	const dots = useMemo(()=>{
		return topic && topic.topics && topic.topics.map(t => {
			return {
				name: t.name,
				percentCorrect: t.percentCorrect,
				color: t.percentCorrect > 0 ? getColorForScore(t.percentCorrect) : theme.palette.divider,
				complete: t.percentAnswered === 1,
				topic: t
			}
		})
	}, [topic, theme.palette.divider])

	return <>
		{ dots && dots.map(d => {
			const title = d.percentCorrect > 0
				? `${fmtPct(d.percentCorrect)} - ${d.name}`
				: `Not Started - ${d.name} `
			return <Tooltip title={title} key={`dot-${d.topic.id}`}>
				<Circle sx={{
							fontSize: size,
							color: d.color,
							mr: 0.5,
							cursor: "pointer"
						}}
				        onClick={()=>onClick(d.topic)}
				/>
			</Tooltip>
		})}
	</>


}

export default ScoreDots
