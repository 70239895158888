import {Box, MenuItem, Select, Typography} from "@mui/material"
import {CircleOutlined}                    from "@mui/icons-material"
import LabeledSelect                       from "../LabeledSelect"
import {useEffect, useState}               from "react"
import getSupabase                         from "../../util/supabase-utils"

const SelectCategory = ({category, onChangeCategory, sx}) => {

	const [categories, setCategories] = useState([])

	useEffect(()=>{
		fetchCategories()
	}, [])

	const fetchCategories = async () => {
		const { data, error } = await getSupabase()
			.from('category')
			.select('*')
			.order('name', { ascending: true })

		if (error) {
			console.error('Error fetching categories:', error.message)
		} else {
			setCategories(data)
		}
	}

	const getCategoryById = (id) => {
		return categories.find(c=>c.id===id)
	}

	// Handle empty or undefined category
	const selectedCategory = getCategoryById(category)

	return <LabeledSelect
		sx={sx}
		value={selectedCategory ? selectedCategory.id : ''}
		onChange={e => onChangeCategory(getCategoryById(e.target.value))}
		displayEmpty
		fullWidth
		label="Category"
		placeholder={categories ? `Select Category` : `Loading Categories...`}
	>
		<MenuItem value={0}>
			<Typography component="span" style={{opacity: 0.5}}>
				Select Category
			</Typography>
		</MenuItem>
		{
			categories && categories.map((c,i) => {
				return <MenuItem key={c.id} value={c.id}>
					<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
						<CircleOutlined fontSize='14px' sx={{color: c.color, mr: 1}}/>
						<Typography component="span">
							{c.name}
						</Typography>
					</Box>
				</MenuItem>
			})
		}
	</LabeledSelect>

}

export default SelectCategory