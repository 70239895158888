import React, {useMemo}                                                         from 'react'
import {Box, Card, CardContent, ListItem, ListItemButton, Typography, useTheme} from "@mui/material"
import {ArrowForward}                                                           from "@mui/icons-material"
import LeftRight                                                                from "../LeftRight"
import {fmt2Dec}                                                                from "../../util/prepkit-utils"

const PackageListItem = ({packageData, highlight, showPricing, onClick}) => {

	const theme = useTheme()
	const lowestPriceOption = useMemo(() => {
		if (!packageData.options || packageData.options.length === 0) {
			return null;
		}
		const x = packageData.options &&
			packageData.options.length > 0 &&
			packageData.options.reduce((lowest, current) =>
				current.price_in_cents < lowest.price_in_cents ? current : lowest
			)
		return x
	}, [packageData.options]);

	const LI = onClick ? ListItemButton : ListItem

	const sx = highlight
		? {
			border: `3px solid ${theme.palette.primary.main}`
	      }
		: {
			backgroundColor: theme.palette.background.default
		  }

	return <LI
		onClick={() => {
			onClick && onClick(packageData)
		}}
		sx={[sx, {
			borderRadius: 1,
			mb: 2,
			p: 0,
			cursor: 'pointer'
		}]}
	>
		<Card sx={{
			width: '100%',
			'&:hover': {
				backgroundColor: theme.palette.action.hover,
			}
		}}>
			<CardContent>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					flex={1}
					sx={{elevation: 2}}
				>
					{
						showPricing &&
						lowestPriceOption
						?	<LeftRight
								left={
									<Typography fontWeight={600}>
										{packageData.name}
									</Typography>
								}
								right={
									<Box
										display={"flex"}
										flexDirection={"column"}
										justifyContent={"flex-start"}
										alignItems={"flex-start"}
									>
										<Typography display={"flex"}
													variant="body"
										            color={"success.main"}
										            textAlign={"right"}
										>
											${fmt2Dec(lowestPriceOption.price_in_cents / 100)}
										</Typography>
									</Box>
								}
							/>
						:   <Typography fontWeight={600}>
								{packageData.name}
							</Typography>
					}

					<Typography variant="body2">{packageData.tagline}</Typography>
				</Box>
			</CardContent>
		</Card>
	</LI>

}

export default PackageListItem
