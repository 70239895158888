import React, { useState }                                                                                                                                                                       from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material'
import {MoreVert}                                                                                                                                                                                from "@mui/icons-material"
import moment
                                                                                                                                                                                                 from "moment"

const LicenseTable = ({ licenses, onDeleteLicense, onUpdateExpiresAt }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedLicense, setSelectedLicense] = useState(null)
	const [openDialog, setOpenDialog] = useState(false)
	const [newExpirationDate, setNewExpirationDate] = useState('')

	const handleMenuClick = (event, license) => {
		setAnchorEl(event.currentTarget)
		setSelectedLicense(license)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteClick = () => {
		if (window.confirm('Are you sure you want to delete this license?')) {
			onDeleteLicense(selectedLicense.id)
		}
		handleMenuClose()
	}

	const handleEditClick = () => {
		setNewExpirationDate(moment(selectedLicense.expires_at).format('YYYY-MM-DDTHH:mm'))
		setOpenDialog(true)
		handleMenuClose()
	}

	const handleDialogClose = () => {
		setOpenDialog(false)
	}

	const handleSave = () => {
		onUpdateExpiresAt(selectedLicense.id, newExpirationDate)
		handleDialogClose()
	}

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>License ID</TableCell>
						<TableCell>User Email</TableCell>
						<TableCell>Product Name</TableCell>
						<TableCell>Expiration Date</TableCell>
						<TableCell>Creation Date</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{licenses && licenses.map((license) => (
						<TableRow key={license.id}>
							<TableCell>{license.id}</TableCell>
							<TableCell>{license.user.email}</TableCell>
							<TableCell>{license.product.name}</TableCell>
							<TableCell>{moment(license.expires_at).calendar()}</TableCell>
							<TableCell>{moment(license.created_at).calendar()}</TableCell>
							<TableCell>
								<IconButton onClick={(event) => handleMenuClick(event, license)}>
									<MoreVert />
								</IconButton>
								<Menu
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl) && selectedLicense === license}
									onClose={handleMenuClose}
								>
									<MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
									<MenuItem onClick={handleEditClick}>Edit Expiration</MenuItem>
								</Menu>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Dialog open={openDialog} onClose={handleDialogClose}>
				<DialogTitle>Edit Expiration {newExpirationDate}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please enter the new expiration date and time.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						label="Expiration Date and Time"
						type="datetime-local"
						fullWidth
						value={newExpirationDate}
						onChange={(e) => setNewExpirationDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>

		</TableContainer>
	)
}

export default LicenseTable
