import React from 'react'
import {Box} from "@mui/material"

function LeftRight({left, right, spacing, ...props}) {
	spacing = spacing || 0
	return (
		<Box display="flex"
		     flexDirection="row"
		     justifyContent="space-between"
		     alignItems="center"
		     sx={{width: "100%"}}
		     {...props}
		>
			<Box display="flex" mr={spacing/2}>{left}</Box>
			<Box display="flex" ml={spacing/2}>{right}</Box>
		</Box>
	)
}

export default LeftRight
