import '../stylesheets/App.css'
import {useEffect, useState}                                                      from "react"
import {useNavigate}                                                                                from "react-router-dom"
import {
	Box,
	Button,
	Dialog, DialogContent,
	DialogTitle, IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme
}                      from "@mui/material"
import {doGet, doPost} from "../util/do-fetch"
import {useUserStore}  from "../state"
import {logger}        from "../util/log-utils"
import getSupabase     from "../util/supabase-utils"
import moment          from "moment"
import {shortenString} from "../util/prepkit-utils"
import LeftRight                           from "./LeftRight"
import {CheckCircle, Close, CloseOutlined} from "@mui/icons-material"
import {Alert, AlertTitle}                 from "@mui/material"

const log = logger("BadJson", 1)

function BadJson(props) {

	const theme = useTheme()
	const [rows, setRows] = useState(null)
	const [json, setJson] = useState(null)
	const [jsonError, setJsonError] = useState(null)

	useEffect(()=> {
		fetchBadJson()
	}, [])

	useEffect(()=> {
		if(json) {
			try {
				JSON.parse(json)
			}
			catch(e) {
				setJsonError(e.toString())
			}
		}
		else {
			setJsonError(null)
		}
	}, [json])

	const fetchBadJson = async () => {
		try {
			const response = await doGet(`/admin/bad-json`)
			const data = await response.json()
			setRows(data)
		}
		catch (err) {
			log.error(err)
		}
	}

/*
created_at timestamp with time zone not null default now(),
    job_type text not null,
    bad_json text not null,
    fixed_json text null,
    fix_worked boolean null,
    id uuid not null default gen_random_uuid (),
    error_message_1 text null,
    error_message_2 text null,
 */
	return (

		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Created</TableCell>
						<TableCell>Bad Json</TableCell>
						<TableCell>Bad Err</TableCell>
						<TableCell>Fixed Json</TableCell>
						<TableCell>Fixed Err</TableCell>
						<TableCell>Fixed?</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows && rows.map(r => {

						const fixAttempted = r.fix_worked === true || r.fix_worked === false
						const sx1 = fixAttempted && r.fix_worked &&
							{
								color: theme.palette.success.main,
								p: 1,
								cursor: 'pointer'
							}
						const sx2 = fixAttempted && !r.fix_worked &&
							{
								color: theme.palette.error.main,
								p: 1,
								cursor: 'pointer'
							}

						return <TableRow>
							<TableCell>{r.id}</TableCell>
							<TableCell>{moment(r.created_at).calendar()}</TableCell>
							<TableCell onClick={()=>setJson(r.bad_json)} sx={{cursor: 'pointer'}}>
								{shortenString(r.bad_json)}
							</TableCell>
							<TableCell>{r.error_message_1}</TableCell>
							<TableCell onClick={()=>setJson(r.fixed_json)}  sx={{cursor: 'pointer'}}>
								<Box sx={sx1 || sx2}>
									{shortenString(r.fixed_json)}
								</Box>
							</TableCell>
							<TableCell>{r.error_message_2}</TableCell>
							<TableCell>{r.fix_worked === true
								? <CheckCircle color="success"/>
								: r.fix_worked === false
									? <CloseOutlined color="error"/>
									: 'null'
							}</TableCell>

						</TableRow>
					})}
				</TableBody>
			</Table>
			<Dialog open={json} maxWidth={800}>
				<DialogTitle>
					<LeftRight
							left={"JSON Viewer"}
							right={<IconButton onClick={()=>setJson(null)}><Close color="error"/></IconButton>}
						/>
				</DialogTitle>
				<DialogContent>
					<Box m={2} p={2} borderRadius={5} sx={{maxHeight: '500px', overflowY: 'scroll'}}>
						{
							jsonError && <Alert color="error">
								<AlertTitle title="JSON Error"/>
								<code>{jsonError}</code>
							</Alert>
						}
						<pre>{json}</pre>
					</Box>

				</DialogContent>
			</Dialog>
		</TableContainer>
	)
}



export default BadJson