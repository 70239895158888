import React from 'react';
import { Box } from '@mui/material';

const LoadingDots = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Box
				sx={{
					width: 8,
					height: 8,
					margin: '0 4px',
					backgroundColor: 'currentColor',
					borderRadius: '50%',
					animation: 'loading 1.2s infinite ease-in-out',
				}}
			/>
			<Box
				sx={{
					width: 8,
					height: 8,
					margin: '0 4px',
					backgroundColor: 'currentColor',
					borderRadius: '50%',
					animation: 'loading 1.2s infinite ease-in-out 0.2s',
				}}
			/>
			<Box
				sx={{
					width: 8,
					height: 8,
					margin: '0 4px',
					backgroundColor: 'currentColor',
					borderRadius: '50%',
					animation: 'loading 1.2s infinite ease-in-out 0.4s',
				}}
			/>
			<style>
				{`
          @keyframes loading {
            0%, 80%, 100% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
          }
        `}
			</style>
		</Box>
	);
};

export default LoadingDots;
