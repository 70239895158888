import React, { useState, useEffect }                                                                     from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	IconButton,
	Alert, Button, Box, CircularProgress
} from '@mui/material'
import CheckIcon              from '@mui/icons-material/Check'
import CloseIcon              from '@mui/icons-material/Close'
import {doGet, doPost, doPut} from "../../util/do-fetch"
import Grid2
                              from "@mui/material/Unstable_Grid2"
import Grid
	                      from "@mui/material/Unstable_Grid2"
import {Refresh, Warning} from "@mui/icons-material"
import HoverTableRow      from "../HoverTableRow"
import EditableLabel          from "../EditableLabel"
import LeftRight              from "../LeftRight"

const AppConfigManager = () => {
	const [config, setConfig] = useState(null)
	const [saving, setSaving] = useState(false)
	const [reloading, setReloading] = useState(false)

	useEffect(() => {
		fetchAppConfig()
	}, [])

	const fetchAppConfig = async () => {
		try {
			const response = await doGet(`/admin/app-config`)
			const data = await response.json()
			setConfig(data)
		}
		catch (err) {
			console.log(err)
		}
	}

	const initAppConfig = async () => {
		setReloading(true)
		try {
			const response = await doPost(`/admin/app-config/init`)
			const data = await response.json()
			setConfig(data)
		}
		catch (err) {
			console.log(err)
		}
		finally {
			setReloading(false)
		}
	}

	const handleUpdateAppConfig = async (key, newValue) => {
		setSaving(true)
		try {
			const options = {
				body: JSON.stringify({value: newValue})
			}
			const response = await doPut(`/admin/app-config/${key}`, options)
			const data = await response.json()
			setConfig(data)
		}
		catch (err) {
			console.log(err)
		}
		finally {
			setSaving(false)
		}
	}

	
	return (
		<Grid container maxWidth="lg" sx={{margin: "0 auto"}}>
			<Grid sm={12}>
				<LeftRight
					left={<h2>App Config</h2>}
					right={<Box>
						<Button disabled={reloading} variant={"outlined"} onClick={initAppConfig}>
							{
								reloading
									? <CircularProgress size={14}/>
									: <Refresh/>
							}
							Reload
						</Button>
					</Box>}
				/>

				<Alert color="warning" icon={<Warning/>} sx={{mb: 2}}>
					Many of these values will take effect immediately when modified, please be cautious making changes.
				</Alert>
			</Grid>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><b>Key</b></TableCell>
							<TableCell><b>Value</b></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{config && config.map(({ key, value }) => (
							<HoverTableRow key={key} hover>
								<TableCell valign="top" sx={{fontFamily: 'monospace'}}>{key}</TableCell>
								<TableCell valign="top" sx={{fontFamily: 'monospace'}}>
									<EditableLabel label={value}
									               onSave={(newValue) => {
										               handleUpdateAppConfig(key, newValue, value)
									               }}
									               isSaving={saving}
									               multiline={value && value.length > 255}
									               fullWidth={value && value.length > 255}
									/>
								</TableCell>
							</HoverTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	)
}

export default AppConfigManager
