import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function TextFormatter({ text, useTypography = false, style }) {
	// Split the text by newline character
	const paragraphs = text ? text.split('\n').filter(p => p) : []; // filter out empty paragraphs

	if (useTypography) {
		return (
			<>
				{paragraphs.map((para, index) => (
					<Typography key={index} variant="body1" paragraph style={style}>
						{para}
					</Typography>
				))}
			</>
		);
	} else {
		return (
			<>
				{paragraphs.map((para, index) => (
					<p key={index} style={style}>
						{para}
					</p>
				))}
			</>
		);
	}
}

TextFormatter.propTypes = {
	text: PropTypes.string.isRequired,
	useTypography: PropTypes.bool,
	style: PropTypes.object,
};

export default TextFormatter;

