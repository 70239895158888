import {Box, CircularProgress, Typography, useTheme} from "@mui/material"

function CircularProgressWithLabel(props) {

	const theme = useTheme()

	const sx = {...props.sx} || {}
	sx.color = props.color || theme.palette.primary.main

	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress
				variant="determinate"
				value={props.value || 0}
				size={props.size || 32}
				thickness={props.thickness || 4}
				sx={sx}
			/>
			<Box
				position="absolute"
				top={0}
				left={0}
				bottom={0}
				right={0}
				display="flex"
				alignItems="center"
				justifyContent="center"
				borderRadius="50%"
			>
				<Typography variant="label" component="div" color="textSecondary" fontSize={props.labelSize} fontWeight={600}>
					{`${Math.round(props.label)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

export default CircularProgressWithLabel