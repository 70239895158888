import React, {useEffect}                       from 'react'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Home                                     from "./Home"
import Admin                                    from "./admin/Admin"
import Users                                    from "./admin/Users"
import Products                                 from "./admin/Products"
import Product                                  from "./admin/Product"
import Licenses                                 from "./admin/Licenses"
import ThemePaletteBrowser                      from "./admin/ThemePaletteBrowser"
import {useUserStore}                           from "../state"
import {logger}                                 from "../util/log-utils"
import useNavBarHeight                          from "../hooks/useNavBarHeight.js"
import CheckAccess                              from "./CheckAccess"
import ProductGenerator                         from "./admin/ProductGenerator"
import TopicJobs                                from "./admin/TopicJobs"
import AppConfigManager                         from "./admin/AppConfigManager"
import ProductQuestions                         from "./admin/ProductQuestions"
import ProductMedia                             from "./admin/ProductMedia"
import ProductCost                              from "./admin/ProductCost"
import Videos                                   from "./admin/Videos"
import VideoJobProcessing                       from "./admin/VideoJobProcessing"
import ProductMediaJobs                         from "./admin/ProductMediaJobs"
import MiniQuestionView                         from "./mini/MiniQuestionView"
import MiniLayout                               from "./layout/MiniLayout"
import MainLayout                               from "./layout/MainLayout"
import MiniCodeView                             from "./mini/MiniCodeView"
import BirdsyAuth           from "./auth/BirdsyAuth"
import UpdatePassword       from "./auth/UpdatePassword"
import Packages             from "./admin/Packages"
import Package              from "./admin/Package"
import useUserRoles         from "../hooks/useUserRoles"
import BadJson              from "./BadJson"
import MiniPrepKit          from "./mini/MiniPrepKit"
import MiniPrepKits         from "./mini/MiniPrepKits"
import MiniShop             from "./mini/MiniShop"
import MiniPrepKitDashboard from "./mini/MiniPrepKitDashboard"
import Categories           from "./admin/Categories"
import UserProfile          from "./profile/UserProfile"
import AuthCallback         from "./auth/AuthCallback"
import AiPersonalityManager from "./admin/AiPersonaltyManager"

const log = logger("AuthGate", 1)

function AuthGate({toggleTheme}) {
	log.trace("Component render")

	const {user} = useUserStore()
	const isAdmin = useUserRoles(['admin'])
	const {navBarRef, height} = useNavBarHeight()

	useEffect(() => {
		log.debug("useEffect[height, navBarRef]")
		if (navBarRef.current) {
			log.debug(`setting --navbar-height = ${height}px`)
			document.documentElement.style.setProperty('--navbar-height', `${height}px`)
		}
	}, [height, navBarRef])

	const sharedRoutes = [
		<Route path="/"
		       key="/"
		       element={<MiniLayout toggleTheme={toggleTheme}/>}
		>
			<Route index element={<Home/>}/>
		</Route>,

		<Route path="/auth/callback"
		       key="/auth/callback"
		       element={<AuthCallback />}
		/>,

		<Route path="/q"
		       key="/q"
		       element={<MiniLayout toggleTheme={toggleTheme}/>}
		>
			<Route index element={<MiniCodeView/>}/>
		</Route>,

		<Route path="/shop"
		       key="/shop"
		       element={<MiniLayout toggleTheme={toggleTheme}/>}
		>
			<Route index element={<MiniShop/>}/>
			<Route path=":id" element={<MiniShop/>}/>
		</Route>,

		<Route path="/c"
		       key="/c"
		       element={<MiniLayout toggleTheme={toggleTheme}/>}
		>
			<Route index element={<MiniShop/>}/>
			<Route path=":category" element={<MiniShop/>}/>
			<Route path=":category/:id" element={<MiniShop/>}/>
		</Route>
	]

	const authenticatedRoutes = (
		<Router>
			<Routes>
				<Route element={<MainLayout navBarRef={navBarRef} toggleTheme={toggleTheme}/>}>

					<Route path="/update-password" element={<UpdatePassword/>}/>

					{isAdmin && (
						<>
							<Route path="/admin" element={<Admin/>}/>
							<Route path="/admin/users" element={<Users/>}/>
							<Route path="/admin/products" element={<Products/>}/>
							<Route path="/admin/products/create" element={<ProductGenerator/>}/>
							<Route path="/admin/products/:productId" element={<Product/>}>
								<Route index element={<ProductQuestions/>}/>
								<Route path="media" element={<ProductMediaJobs/>}/>
								<Route path="media/:id" element={<ProductMedia/>}/>
								<Route path="cost" element={<ProductCost/>}/>
							</Route>
							<Route path="/admin/packages/new" element={<Package/>}/>
							<Route path="/admin/packages/:id/edit" element={<Package/>}/>
							<Route path="/admin/packages" element={<Packages/>}/>
							<Route path="/admin/topic-job-queue" element={<TopicJobs/>}/>
							<Route path="/admin/licenses" element={<Licenses/>}/>
							<Route path="/admin/videos" element={<Videos/>}/>
							<Route path="/admin/videos/jobs/:id" element={<VideoJobProcessing/>}/>
							<Route path="/admin/categories" element={<Categories/>}/>
							<Route path="/admin/app-config" element={<AppConfigManager/>}/>
							<Route path="/admin/ai-personalities" element={<AiPersonalityManager/>}/>
							<Route path="/admin/palette" element={<ThemePaletteBrowser/>}/>
							<Route path="/admin/check-access" element={<CheckAccess/>}/>
							<Route path="/admin/bad-json" element={<BadJson/>}/>
						</>
					)}
				</Route>

				<Route path="/shop" element={<MiniLayout toggleTheme={toggleTheme}/>}>
					<Route index element={<MiniShop/>}/>
					<Route path=":id" element={<MiniShop/>}/>
					<Route path=":id/option/:optionId" element={<MiniShop/>}/>
				</Route>

				{sharedRoutes}

				<Route path="/q/:code" element={<MiniLayout toggleTheme={toggleTheme}/>}>
					<Route index element={<MiniPrepKit/>}/>
				</Route>

				<Route path="/prepkit" element={<MiniLayout toggleTheme={toggleTheme}/>}>
					<Route index element={<MiniPrepKits/>}/>
					<Route path=":id" element={<MiniPrepKit/>}>
						<Route index element={<MiniPrepKitDashboard/>}/>
						<Route path="t/:topicId" element={<MiniPrepKitDashboard/>}/>
						<Route path="t/:topicId/q/:code" element={<MiniQuestionView/>}/>
						<Route path="q/:code" element={<MiniQuestionView/>}/>
					</Route>
				</Route>

				<Route path="/account" element={<MiniLayout toggleTheme={toggleTheme}/>}>
					<Route path="profile" element={<UserProfile/>}/>
				</Route>
			</Routes>
		</Router>
	)

	const unauthenticatedRoutes = (
		<Router>
			<Routes>
				<Route element={<MainLayout navBarRef={navBarRef} toggleTheme={toggleTheme}/>}>
					{sharedRoutes}
					<Route path="*" element={<MiniLayout toggleTheme={toggleTheme}/>}>
						<Route path="*" element={<BirdsyAuth/>}/>
					</Route>
				</Route>
			</Routes>
		</Router>
	)

	return user ? authenticatedRoutes : unauthenticatedRoutes
}

export default React.memo(AuthGate)
