import {Box, Skeleton} from "@mui/material"
import React           from "react"


const MiniSkeleton = ({title, animation = false}) => {
	
	return <Box mt={3} sx={{opacity: .7}}>
		<h2>{title}</h2>
		<Skeleton animation={animation} variant="text" width={300} height={20}/>
		<Skeleton animation={animation} variant="text" width={800} height={200} sx={{my: 0}}/>

		<Box mt={1}>
			<Skeleton animation={animation} variant="text" width={700} height={80}/>
		</Box>

		<Box mt={3} display="flex" alignItems="center">
			<Skeleton animation={animation} variant="rectangular" width={40} height={40}
			          style={{marginRight: 8}}/>
			<Skeleton animation={animation} variant="text" width={300} height={20}/>
		</Box>

		<Box mt={3} display="flex" alignItems="center">
			<Skeleton animation={animation} variant="rectangular" width={40} height={40}
			          style={{marginRight: 8}}/>
			<Skeleton animation={animation} variant="rectangular" width={300} height={50}/>
		</Box>

		<Box mt={3} display="flex" alignItems="center">
			<Skeleton animation={animation} variant="rectangular" width={600} height={40}/>
		</Box>

		<Box sx={{position: "fixed", bottom: 0, left: 0, right: 0}}>
			<Box
				sx={{width: "800px", margin: "0 auto"}}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				pb={2}
			>
				<Skeleton animation={animation} variant="rectangular" width={650} height={60}/>
				<Skeleton animation={animation} variant="circular" width={60} height={60}/>
				<Skeleton animation={animation} variant="circular" width={60} height={60}/>
			</Box>

		</Box>
	</Box>
}

export default MiniSkeleton