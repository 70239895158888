
/*
 * todo: check out createLight and createDark to add or override values
 */

const colors = {
	light: {
		"mode": "light",
		"common": {
			"black": "#000",
			"white": "#fff"
		},
		"primary": {
			"main": "#1976d2",
			"light": "#42a5f5",
			"dark": "#1565c0",
			"contrastText": "#fff"
		},
		"secondary": {
			"main": "#9c27b0",
			"light": "#ba68c8",
			"dark": "#7b1fa2",
			"contrastText": "#fff"
		},
		"error": {
			"main": "#d32f2f",
			"light": "#ef5350",
			"dark": "#c62828",
			"contrastText": "#fff"
		},
		"warning": {
			"main": "#ed6c02",
			"light": "#ff9800",
			"dark": "#e65100",
			"contrastText": "#fff"
		},
		"info": {
			"main": "#0288d1",
			"light": "#03a9f4",
			"dark": "#01579b",
			"contrastText": "#fff"
		},
		"success": {
			"main": "#2e7d32",
			"light": "#4caf50",
			"dark": "#1b5e20",
			"contrastText": "#fff"
		},
		"grey": {
			"50": "#fafafa",
			"100": "#f5f5f5",
			"200": "#eeeeee",
			"300": "#e0e0e0",
			"400": "#bdbdbd",
			"500": "#9e9e9e",
			"600": "#757575",
			"700": "#616161",
			"800": "#424242",
			"900": "#212121",
			"A100": "#f5f5f5",
			"A200": "#eeeeee",
			"A400": "#bdbdbd",
			"A700": "#616161"
		},
		"contrastThreshold": 3,
		"tonalOffset": 0.2,
		"text": {
			"primary": "rgba(0, 0, 0, 0.87)",
			"secondary": "rgba(0, 0, 0, 0.6)",
			"disabled": "rgba(0, 0, 0, 0.38)"
		},
		"divider": "rgba(0, 0, 0, 0.12)",
		"background": {
			"paper": "#fff",
			"default": "#fff"
		},
		"action": {
			"active": "rgba(0, 0, 0, 0.54)",
			"hover": "rgba(0, 0, 0, 0.04)",
			"hoverOpacity": 0.04,
			"selected": "rgba(0, 0, 0, 0.08)",
			"selectedOpacity": 0.08,
			"disabled": "rgba(0, 0, 0, 0.26)",
			"disabledBackground": "rgba(0, 0, 0, 0.12)",
			"disabledOpacity": 0.38,
			"focus": "rgba(0, 0, 0, 0.12)",
			"focusOpacity": 0.12,
			"activatedOpacity": 0.12
		},
	},
	dark: {
		"mode": "dark",
		"common": {
			"black": "#000",
			"white": "#fff"
		},
		"primary": {
			"main": "#90caf9",
			"light": "#e3f2fd",
			"dark": "#42a5f5",
			"contrastText": "rgba(0, 0, 0, 0.87)"
		},
		"secondary": {
			"main": "#ce93d8",
			"light": "#f3e5f5",
			"dark": "#ab47bc",
			"contrastText": "rgba(0, 0, 0, 0.87)"
		},
		"error": {
			"main": "#f44336",
			"light": "#e57373",
			"dark": "#d32f2f",
			"contrastText": "#fff"
		},
		"warning": {
			"main": "#ffa726",
			"light": "#ffb74d",
			"dark": "#f57c00",
			"contrastText": "rgba(0, 0, 0, 0.87)"
		},
		"info": {
			"main": "#29b6f6",
			"light": "#4fc3f7",
			"dark": "#0288d1",
			"contrastText": "rgba(0, 0, 0, 0.87)"
		},
		"success": {
			"main": "#66bb6a",
			"light": "#81c784",
			"dark": "#388e3c",
			"contrastText": "rgba(0, 0, 0, 0.87)"
		},
		"grey": {
			"50": "#fafafa",
			"100": "#f5f5f5",
			"200": "#eeeeee",
			"300": "#e0e0e0",
			"400": "#bdbdbd",
			"500": "#9e9e9e",
			"600": "#757575",
			"700": "#616161",
			"800": "#424242",
			"900": "#212121",
			"A100": "#f5f5f5",
			"A200": "#eeeeee",
			"A400": "#bdbdbd",
			"A700": "#616161"
		},
		"contrastThreshold": 3,
		"tonalOffset": 0.2,
		"text": {
			"primary": "#fff",
			"secondary": "rgba(255, 255, 255, 0.7)",
			"disabled": "rgba(255, 255, 255, 0.5)",
			"icon": "rgba(255, 255, 255, 0.5)"
		},
		"divider": "rgba(255, 255, 255, 0.12)",
		"background": {
			"paper": "#121212",
			"default": "#121212"
		},
		"action": {
			"active": "#fff",
			"hover": "rgba(255, 255, 255, 0.08)",
			"hoverOpacity": 0.08,
			"selected": "rgba(255, 255, 255, 0.16)",
			"selectedOpacity": 0.16,
			"disabled": "rgba(255, 255, 255, 0.3)",
			"disabledBackground": "rgba(255, 255, 255, 0.12)",
			"disabledOpacity": 0.38,
			"focus": "rgba(255, 255, 255, 0.12)",
			"focusOpacity": 0.12,
			"activatedOpacity": 0.24
		}
	}
}

const createLight = () => {
	return {
		background: {
			default: "rgba(245,245,245,1)",
			secondary: {
				paper: "rgba(255,255,255,1)"
			}
		},
		chat: {
			user: {
				bubble: "rgba(0,0,0,.05)",
				copy: "rgba(0, 0, 0, 1)",
				text: "black",
				border: "transparent"
			},
			ai: {
				bubble: "transparent",
				correct: "rgba(0,200,0,.05)",
				incorrect: "rgba(200,0,0,.05)",
				copy: "rgba(0, 0, 0, 1)",
				text: "black"
			},
			input: {},
			action: {
				text: "rgba(66, 165, 245, 1)",
				outline: {
					main: "rgba(66, 165, 245, 1)",
					light: "rgba(66, 165, 245, .5)"
				}
			}
		},
		prepKit: {
			questionCard: {
				paper: "white", //"rgba(255,255,255,1)",
				border: "rgba(200,200,200,1)",
				button: {
					border: "rgba(0,0,0,.075)",
					text: "#0058FF",
					hover: {
						border: "#0058FF",
						text: "#0058FF"
					}
				},
				skip: {
					color: 'black',
					background: 'transparent'
				},
				continue: {
					color: '#66bb6a', // var(--success-light)
					colorPulse: 'rgb(185,219,144)', // var(--progress-main)
					background: 'transparent'
				}
			}
		},
		logo: {
			primary: "#000000"
		},
		score: {
			success: "#9DD05C",
			warning: "#FFFF00",
			error: "#E86B6B",
		},
		pending: colors.light.primary,
		review: colors.light.warning,
		processing: colors.light.primary,
		rendering: colors.light.secondary,
		completed: colors.light.success,
		failed: colors.light.error,
		translucent:  "rgba(255,255,255,.8)"
	}
}

const createDark = (defaultTheme) => {
	return {
		background: {
			default: "rgba(55,55,66,1)",
			secondary: {
				paper: "rgba(33,33,44,1)"
			}
		},
		chat: {
			user: {
				bubble: "rgba(255,255,255,.1)",
				border: "transparent",
				text: "white"
			},
			ai: {
				bubble: "transparent",
				border: "transparent",
				text: "white"
			},
			outline: {
				main: "rgba(66, 165, 245, 1)",
				light: "rgba(66, 165, 245, .5)"
			}
		},
		prepKit: {
			questionCard: {
				paper: colors.dark.background.paper, //"rgba(33,33,44,1)",
				border: "red",
				button: {
					border: "rgba(255,255,255,.05)",
					text: "white",
					hover: {
						border: "#0058FF",
						text: "white"
					},
					skip: {
						color: 'white',
						background: 'transparent'
					},
					continue: {
						color: '#66bb6a', // var(--success-light)
						colorPulse: 'rgb(185,219,144)', // var(--progress-main)
						background: 'transparent'
					}
				}
			}
		},
		logo: {
			primary: "#ffffff"
		},
		score: {
			success: "#9DD05C",
			warning: "#FFFF00",
			error: "#E86B6B",
		},
		pending: colors.light.primary,
		review: colors.light.warning,
		processing: colors.light.primary,
		rendering: colors.light.secondary,
		completed: colors.light.success,
		failed: colors.light.error,
		translucent:  "rgba(0,0,0,.6)"
	}
}

export {createLight, createDark}