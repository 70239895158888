import React                      from 'react'
import {Button, Container, Stack} from '@mui/material'
import {useNavigate}              from "react-router-dom"
import {ADMIN_ACTIONS}            from "../../util/admin"


function Admin() {

	const navigate = useNavigate()

	return (
		<Container maxWidth="xs">
			<Stack direction="column">
				{
					ADMIN_ACTIONS.map(action => {
							return (<Button
								size="large"
								key={`btn-${action.label}`}
								color="divider"
								sx={{m: 2, fontSize: '16px', p: 2}}
								variant="contained"
								onClick={() => navigate(action.url)}>{action.label}
							</Button>)
						}
					)
				}
			</Stack>
		</Container>
	)
}

export default Admin