import { createStore } from 'zustand/vanilla'
import { create } from 'zustand'
import { getSupabase } from '../util/supabase-utils'

// Create the store configuration
const configureStore = (set, get) => {
	const supabase = getSupabase()

	// Initialize the session from Supabase on startup
	const initialSession = supabase.session

	// Listen for auth state changes and update the store
	supabase.auth.onAuthStateChange((event, session) => {
		if (event === 'SIGNED_OUT') {
			set({ session: null, user: null })
		} else if (event === 'SIGNED_IN') {
			set({ session, user: session?.user })
		}
	})

	return {
		session: initialSession,
		user: initialSession?.user,
		setSession: (session) => set(() => ({ session, user: session?.user })),
		signOut: async () => {
			const { error } = await supabase.auth.signOut()
			if (error) {
				console.error("Error signing out:", error)
			} else {
				set({ session: null, user: null })
			}
		}
	}
}

// Create a vanilla store
const store = createStore(configureStore)

// Create a hook to use the store
export const useUserStore = create(store)
