import React, {useEffect, useState}                                                 from 'react'
import { Backdrop, Box, Container, Dialog, DialogContent, useTheme, useMediaQuery } from "@mui/material"
import getSupabase                                                                  from "../../util/supabase-utils"
import BirdsyMiniLogo                                                               from "../BirdsyMiniLogo"
import Login                                                                        from "./Login"
import SignUp                                                                       from "./SignUp"
import ForgotPassword                                                               from "./ForgotPassword"
import styled                                                                       from "@emotion/styled"
import UpdatePassword                                                               from "./UpdatePassword"
import { useUserStore }                                                             from "../../state"
import MiniSkeleton                                                                 from "../mini/MiniSkeleton"
import AuthIntro                                                                    from "./AuthIntro"
import {useNavigate}                                                                from "react-router-dom"

function BirdsyAuth({ view }) {

	const { user } = useUserStore()
	const theme = useTheme()
	const navigate = useNavigate()
	const [currentView, setCurrentView] = useState(view || (!user && ''))
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(()=>{
		if(user) {
			navigate('/')
		}
	}, [user])


	const switchView = (view) => {
		setCurrentView(view)
	}

	return (
		<Container
			style={{
				maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '?600px' : '800px',
				textAlign: 'center',
				padding: isSmallScreen ? '0 16px' : '0'
			}}
		>
			{
				!isSmallScreen && !isMediumScreen && <MiniSkeleton />
			}
			<Dialog open={true} fullScreen={isSmallScreen}>
				<DialogContent>
					<Box display="flex" justifyContent="center" pb={2}>
						<BirdsyMiniLogo width={isSmallScreen ? 100 : 125} />
					</Box>
					{currentView === '' && <AuthIntro switchView={switchView} />}
					{currentView === 'login' && <Login switchView={switchView} />}
					{currentView === 'forgotPassword' && <ForgotPassword switchView={switchView} />}
					{currentView === 'signUp' && <SignUp switchView={switchView} />}
					{currentView === 'update' && <UpdatePassword switchView={switchView} />}
				</DialogContent>
			</Dialog>
		</Container>
	)
}

export default BirdsyAuth
