import {get_encoding} from "tiktoken"

const countTokens = (text) => {
	/*
	 * From OpenAI: A helpful rule of thumb is that one token generally corresponds to ~4 characters
	 * of text for common English text. This translates to roughly ¾ of a word (so 100 tokens ~= 75 words).
	 */
	return text && text.length / 4
}

export { countTokens }

