import {doGet}             from "./do-fetch"
import {Divider, MenuItem} from "@mui/material"
import React               from "react"


const ADMIN_ACTIONS = [
	"Users", "Categories", "Products",
	"Packages", "Licenses", "Videos",
	"AI Personalities", "Topic Job Queue",
	"App Config", "Bad JSON", "Palette",
	"Check Access"
].map(n => ({
		url:  `/admin/${n.toLowerCase().replace(/\s+/g, '-')}`,
		label: n
	}))

const fetchLLMModels = async () => {
	const response = await doGet(`/admin/models/llm`)
	const data = await response.json()
	return data
}

const fetchTTSModels = async () => {
	const response = await doGet(`/admin/models/tts`)
	const data = await response.json()
	return data
}

const fetchVoices = async () => {
	const response = await doGet(`/admin/voices`)
	const data = await response.json()
	return data
}

const fetchCategories = async () => {
	const response = await doGet(`/admin/categories`)
	const data = await response.json()
	return data
}

const fetchSignedMediaUrl = async (apiMediaUrl) => {
	const response = await doGet(apiMediaUrl)
	return await response.text()
}

export {fetchTTSModels, fetchLLMModels, fetchVoices, fetchSignedMediaUrl, fetchCategories, ADMIN_ACTIONS}