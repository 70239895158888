import { useEffect }   from 'react'
import { useNavigate } from 'react-router-dom'
import getSupabase     from "../../util/supabase-utils"
import {DEBUG, logger} from "../../util/log-utils"

const log = logger("AuthCallback", DEBUG)
const supabase = getSupabase()

const AuthCallback = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const processAuth = async () => {
			// Supabase detects the session from the URL fragment
			const { data: { session }, error } = await supabase.auth.getSession()

			if (error) {
				log.error('Error retrieving session:', error)
				// Optionally, display an error message to the user
			} else if (session) {
				log.info(`Session established: ${session}`)
				// Retrieve the intended URL from local storage
				const intendedUrl = localStorage.getItem('intendedUrl') || '/'
				localStorage.removeItem('intendedUrl')

				// Redirect the user to the intended URL
				log.info(`Navigating to  intendedUrl: ${intendedUrl}`)
				navigate(intendedUrl, { replace: true })
			}
		}

		processAuth()
	}, [navigate])

	return <div>Processing authentication...</div>
}

export default AuthCallback
