import {Box, Typography} from "@mui/material"
import React             from "react"
import useMediaQuery     from "@mui/material/useMediaQuery"

const bw = 0

const TopicTitle = ({topic, parentTopics, continueButtons}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))


	return <Box display={"flex"}
	            flex={1}
	            justifyContent={"center"}
	            alignItems={"center"}
	            flexGrow={1}
	            border={`${bw}px solid orange`}
	            height={"100%"}
	            margin={"0 auto"}
	            paddingBottom={"70px"}
	            flexDirection={"column"}
	>
		<Box
			display={"flex"}
			flex={1}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"row"}
			px={isSmallScreen ? 1 : 0}
		>
			<Box>
				<Typography
					variant={isSmallScreen ? "h6" : "h5"}
					sx={{opacity: 0.5, ml: 0.5}}
				>
					{parentTopics?.join(' / ')}
				</Typography>
				<Typography
					variant={isSmallScreen ? "h4" : "h2"}
				>
					{topic.name}
				</Typography>
				<Box mt={10}>
					{continueButtons}
				</Box>
			</Box>
		</Box>
	</Box>
}

export default TopicTitle