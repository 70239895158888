import {Link} from "@mui/material"


const ClickLink = ({children, onClick, ...props}) => {
	return <Link

		{...props}
		onClick={onClick}
		sx={[
			props.sx || {},
			{
				textDecoration: "none",
				cursor: "pointer"
			}
		]}
	>
		{children}
	</Link>
}

export default ClickLink