import {Box, Chip, Link, Typography, useTheme} from "@mui/material"
import React, {useMemo}                        from "react"
import {ExpandLess, ExpandMore}                from "@mui/icons-material"
import ClickLink                               from "./ClickLink"
import {fmtPct}                                from "../util/prepkit-utils"
import {getColorForScore}                      from "../util/utils"
import LeftRight                               from "./LeftRight"
import ScoreDots                               from "./mini/ScoreDots"


const SectionSummary = ({section, expanded, onClick}) => {

	const theme = useTheme()

	return <LeftRight
		left={
			<Box display={"flex"} alignItems={"center"}>
				<ClickLink
					onClick={onClick}
					sx={{
						fontSize: "14px",
						display: "flex",
						alignItems: "center",
						mr: 3
					}}
				>
					{section.topics && section.topics.length} Topics
					{
						expanded
							? <ExpandLess fontSize={"small"} sx={{color: theme.palette.text.primary}}/>
							: <ExpandMore fontSize={"small"} sx={{color: theme.palette.text.primary}}/>
					}
				</ClickLink>

			</Box>
		}
		right={
			<ScoreDots topic={section} onClick={onClick}/>
		}
	/>

}

export default SectionSummary