import { useLocation } from 'react-router-dom'

const useFragment = () => {
	const { hash } = useLocation()

	const hashParams = new URLSearchParams(hash.startsWith('#') ? hash.substring(1) : hash)

	const values = {}
	for (const [key, value] of hashParams.entries()) {
		values[key] = value
	}

	return values
}

export default useFragment
