import React, {useEffect, useState} from 'react'
import {
	Box,
	Button, Dialog, DialogActions, DialogContent,
	DialogTitle, Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField, ToggleButton, ToggleButtonGroup, useTheme
} from '@mui/material'

import {abcd}                             from "../../util/prepkit-utils"
import Grid                               from "@mui/material/Unstable_Grid2"
import {isBlankOrEmpty, isIntegerBetween} from "../../util/utils"
import {Alert}                            from "@mui/material"
import CircleIndicator                    from "../CircleIndicator"

const QuestionEditor = ({question, onSave, onCancel}) => {
	const theme = useTheme()
	const [editedQuestion, setEditedQuestion] = useState(question ? question.question : '')
	const [editedOptions, setEditedOptions] = useState(question ? question.options : [])
	const [editedAnswer, setEditedAnswer] = useState(question ? question.answer.toString() : '')
	const [editedExplanation, setEditedExplanation] = useState(question ? question.explanation : '')
	const [show, setShow] = useState(false)
	const [saving, setSaving] = useState(false)
	const [showAllFieldsRequiredError, setShowAllFieldsRequiredError] = useState(false)

	useEffect(() => {
		setShow(question !== null)
		setEditedQuestion(question ? question.question : '')
		setEditedOptions(question ? question.options : [])
		setEditedAnswer(question ? question.answer.toString() : '')
		setEditedExplanation(question ? question.explanation : '')
	}, [question])

	useEffect(() => {
		if (!question) {
			setShowAllFieldsRequiredError(false);
			return;
		}

		if (isBlankOrEmpty(editedQuestion) ||
			!isIntegerBetween(editedAnswer, 0, 3) ||
			isBlankOrEmpty(editedExplanation) ||
			editedOptions.some(o => isBlankOrEmpty(o.label))) {

			console.log({
				editedQuestion:	isBlankOrEmpty(editedQuestion),
				editedAnswer: !isIntegerBetween(editedAnswer, 0, 3),
				editedExplanation: isBlankOrEmpty(editedExplanation),
				editedOptions: editedOptions.some(o => isBlankOrEmpty(o.label))
			})

			setShowAllFieldsRequiredError(true);
			return;
		}

		setShowAllFieldsRequiredError(false);
	}, [question, editedQuestion, editedOptions, editedAnswer, editedExplanation]);

	const handleOptionChange = (index, newValue) => {
		const newOptions = editedOptions.map((option, i) => {
			if (i === index) {
				return {...option, label: newValue}
			}
			return option
		})
		setEditedOptions(newOptions)
	}

	const handleInsightChange = (index, newInsight) => {
		const newOptions = editedOptions.map((option, i) => {
			if (i === index) {
				return {...option, insight: newInsight}
			}
			return option
		})
		setEditedOptions(newOptions)
	}

	const handleSave = async () => {
		if(!onSave) return
		setSaving(true)
		const updatedQuestion = {
			question: editedQuestion,
			options: editedOptions,
			answer: Number.parseInt(editedAnswer),
			explanation: editedExplanation,
		}
		await onSave(question, updatedQuestion)
		setSaving(true)
	}

	return (
		question &&
		<Dialog
			open={show}
			maxWidth="lg"
		>
			<DialogTitle>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<span>Edit Question</span>

					<Box>
						ID: {question.id},
						CODE: {question.code}
					</Box>
				</Box>
			</DialogTitle>
			{
				showAllFieldsRequiredError &&
				<Alert severity="error">All fields are required.</Alert>
			}
			<DialogContent>
				<Grid container >
					<Grid xs={12}>
						<TextField
							fullWidth
							margin="normal"
							value={editedQuestion}
							onChange={(e) => setEditedQuestion(e.target.value)}
							inputProps={{
								style: { fontWeight: 'bold' }
							}}
						/>
					</Grid>
					<Grid xs={12} spacing={0}>
						<FormControl
							component="fieldset"
							margin="normal"
							sx={{
								m: 0,
								pt: 1,
							}}
						>
							<h4 style={{padding:0, paddingRight: "10px", margin:0, display: "inline-block"}}>
								Correct Answer
							</h4>
							<ToggleButtonGroup
								value={editedAnswer}
								exclusive
								onChange={(event, newAnswer) => setEditedAnswer(newAnswer)}
								aria-label="text alignment"
								orientation="horizontal"
							>
								{editedOptions.map((option, index) => (
									<ToggleButton
										key={index}
										value={index.toString()}
										sx={{minWidth: "60px"}}
									>
										{abcd(index)}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</FormControl>
					</Grid>
					<Grid xs={12} container style={{margin:0,padding:0}}>
					{
						editedOptions.map((option, index) => {
							const letterColor = editedAnswer == index
								? theme.palette.score.success
								: theme.palette.text.secondary
							return <Grid
								xs={6}
								key={question.id + "-option-" + index}
								sx={{p: 0, pr: index % 2 === 0 ? 2 : 0}}
							>
								<div style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
									<div style={{ padding: '28px 15px 0 0' }}>
										<CircleIndicator character={abcd(index)} size={32} backgroundColor={letterColor}/>
									</div>
									<div style={{
											display: 'flex',
											flexDirection: 'column',
											flex: 1,
										}}
									>
										<TextField
											sx={{width: '100%'}}
											variant="outlined"
											fullWidth
											margin="normal"
											value={option.label}
											onChange={(e) => handleOptionChange(index, e.target.value)}
										/>
										<TextField
											sx={{width: '100%'}}
											label={`Insight`}
											variant="outlined"
											fullWidth
											margin="normal"
											value={option.insight}
											multiline={true}
											rows={3}
											onChange={(e) => handleInsightChange(index, e.target.value)}
										/>
									</div>
								</div>
							</Grid>
						})}
					</Grid>

					<Grid xs={12}>
						<h4 style={{padding:0, margin:0, marginTop: '15px'}}>Explanation</h4>
						<TextField
							sx={{mt: 0}}
							fullWidth
							margin="normal"
							multiline
							maxRows={4}
							value={editedExplanation}
							onChange={(e) => setEditedExplanation(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{p: 2, pt: 0}}>
				<Button size="large" variant="outlined" onClick={() => onCancel && onCancel(question)} disabled={saving}>
					Cancel
				</Button>
				<Button size="large" variant="contained" onClick={handleSave} disabled={saving}>
					{ saving ? "Saving" : "Save"}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default QuestionEditor
