import React, {useState} from 'react'
import BirdsyMiniLogo    from "../BirdsyMiniLogo"
import {
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme
}                        from "@mui/material"
import {
	Dashboard,
	Home,
	Logout,
	Menu as MenuIcon,
	Settings,
	Shop
}                        from "@mui/icons-material"
import AccountCircle     from "@mui/icons-material/AccountCircle"
import ThemeToggle       from "../ThemeToggle"
import {useUserStore}    from "../../state"
import {useNavigate}     from "react-router-dom"
import useUserRoles      from "../../hooks/useUserRoles"
import LeftRight         from "../LeftRight"
import BirdsyMiniMark    from "../BirdsyMiniMark"

const MiniNavbar = ({toggleTheme}) => {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const navigate = useNavigate()
	const isAdmin = useUserRoles(['admin'])
	const {user, signOut} = useUserStore()
	const [navAnchorEl, setNavAnchorEl] = useState(null)
	const [navMenuOpen, setNavMenuOpen] = useState(false)

	const [anchorEl, setAnchorEl] = useState(null)
	const [menuOpen, setMenuOpen] = useState(false)

	const logout = () => {
		setMenuOpen(false)
		signOut()
	}

	const goto = (url) => {
		setNavMenuOpen(false)
		navigate(url)
	}

	const navItems = [
		<MenuItem key={"mnu-acct-profile"} onClick={() => goto(`/`)}>
			<ListItemIcon sx={{mr: 1}}>
				<Home fontSize="small"/>
			</ListItemIcon>
			<ListItemText primary="Home"/>
		</MenuItem>,
		<MenuItem key={"mnu-acct-shop"} onClick={() => goto(`/prepkit`)}>
			<ListItemIcon sx={{mr: 1}}>
				<Dashboard fontSize="small"/>
			</ListItemIcon>
			<ListItemText primary="My PrepKits"/>
		</MenuItem>,
		<MenuItem key={"mnu-acct-shop"} onClick={() => goto(`/shop`)}>
			<ListItemIcon sx={{mr: 1}}>
				<Shop fontSize="small"/>
			</ListItemIcon>
			<ListItemText primary="Shop PrepKits"/>
		</MenuItem>
	]

	if (isAdmin) {
		navItems.push(<Divider key={"div-1"}/>)
		navItems.push(<MenuItem key={"mnu-acct-admin"} onClick={() => goto(`/admin`)}>
			<ListItemIcon sx={{mr: 1}}>
				<Settings fontSize="small"/>
			</ListItemIcon>
			<ListItemText primary="Admin"/>
		</MenuItem>)
	}


	return (
		<nav style={{
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			padding: `${isSmallScreen ? 0 : 5}px`,
			zIndex: 1000,
			backgroundColor: isSmallScreen ? theme.palette.background.paper : "transparent",
			xxbackdropFilter: "blur(10px)",
			xxWebkitBackdropFilter: "blur(10px)",
			border: "0px solid magenta"
		}}>
			<div style={{
				margin: "0px auto",
				padding: "5px 15px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				backgroundColor: "transparent",
				border: "0px solid red"
			}}>
				<Box>
					{
						isSmallScreen
							? <BirdsyMiniMark style={{display: 'flex'}} width={50}/>
							: <BirdsyMiniLogo style={{display: 'flex'}} width={130} height={45}/>
					}
				</Box>


				{user &&
				<Box display={"flex"}>
					<LeftRight
						left={
							<Box>
								<IconButton
									size="large"
									onClick={(event) => {
										event.stopPropagation()
										event.preventDefault()
										setNavAnchorEl(event.currentTarget)
										setNavMenuOpen(true)
									}}
								>
									<MenuIcon/>
								</IconButton>

								<Menu
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={navMenuOpen}
									anchorEl={navAnchorEl}
									onClose={(event) => {
										event.stopPropagation()
										setNavAnchorEl(null)
										setNavMenuOpen(false)
									}}
								>
									{user &&
									navItems
									}
								</Menu>
							</Box>
						}
						right={
							<Box>
								<IconButton
									size="large"
									onClick={(event) => {
										event.stopPropagation()
										event.preventDefault()
										setAnchorEl(event.currentTarget)
										setMenuOpen(true)
									}}
								>
									<AccountCircle/>
								</IconButton>

								<Menu
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={menuOpen}
									anchorEl={anchorEl}
									onClose={(event) => {
										event.stopPropagation()
										setAnchorEl(null)
										setMenuOpen(false)
									}}
								>
									<ListItemText
										secondary={user.email}
										sx={{px: 2}}
									/>
									{user &&
									[
										<Divider key={"mnu-acct-div"}/>,
										<MenuItem key={"mnu-acct-profile"} onClick={() => navigate(`/account/profile`)}>
											<ListItemIcon sx={{mr: 1}}>
												<AccountCircle fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary="Profile"/>

										</MenuItem>,
										<MenuItem key={"mnu-acct-signout"} onClick={logout}>
											<ListItemIcon sx={{mr: 1}}>
												<Logout fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary="Sign Out"/>
										</MenuItem>,
									]
									}
									<Divider/>
									<ThemeToggle toggleTheme={toggleTheme}/>
								</Menu>
							</Box>
						}
					/>
				</Box>
				}

			</div>
		</nav>
	)
}

export default MiniNavbar
