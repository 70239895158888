import React, {useEffect, useRef, useState} from 'react'
import {
	Box,
	Button,
	ButtonGroup,
	Chip,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	useTheme
}                                           from '@mui/material'
import Grid                         from "@mui/material/Unstable_Grid2"
import MediaTree                    from "./MediaTree"
import {fmtDttm}                    from "../../util/prepkit-utils"
import {
	AddCircleOutline, CheckBox, CheckBoxOutlined, Checklist,
	DeleteOutline,
	ExpandLess,
	ExpandMore,
	KeyboardArrowDown,
} from "@mui/icons-material"

const ProductMediaTree = ({product, mediaJob, hideExcluded, onGetCustomClip, onChange, onRemove}) => {

	const WORDS_PER_SECOND = 160 / 60
	const SECONDS_BETWEEN_SLIDES = 2

	const theme = useTheme()

	// state we publish change events on for saving
	const [selectedQuestions, setSelectedQuestions] = useState({})
	const [selectedTopics, setSelectedTopics] = useState({})
	const [attachedBefore, setAttachedBefore] = useState({})
	const [attachedAfter, setAttachedAfter] = useState({})

	const [selectedQuestionCount, setSelectedQuestionCount] = useState(0)
	const [selectedTopicCount, setSelectedTopicCount] = useState(0)
	const [attachmentCount, setAttachmentCount] = useState(0)

	const [show, setShow] = useState(true)
	const [questionCountAnchorEl, setQuestionCountAnchorEl] = useState(null)
	const [slides, setSlides] = useState([])
	const [wordCount, setWordCount] = useState(0)
	const [charCount, setCharCount] = useState(0)
	const [tokenCount, setTokenCount] = useState(0)
	const [ttsCost, setTTSCost] = useState(0)
	const [duration, setDuration] = useState(0)
	const [initialRender, setInitialRender] = useState(true)
	const questionCountMenuOpen = Boolean(questionCountAnchorEl)

	useEffect(()=>{
		if (!initialRender) {
			publishChange("attachedBefore")
		}
		updateAttachmentCount()
	}, [attachedBefore])

	useEffect(()=>{
		if (!initialRender) {
			publishChange("attachedAfter")
		}
		updateAttachmentCount()
	}, [attachedAfter])

	useEffect(() => {
		if (!initialRender) {
			publishChange("selectedQuestions")
		}
		setSelectedQuestionCount(selectedQuestions ? Object.keys(selectedQuestions).length : 0)
		updateStats()
	}, [selectedQuestions])

	useEffect(() => {
		publishChange("init")
		setInitialRender(false)
	}, [])

	useEffect(() => {
		setSelectedTopicCount(selectedTopics ? Object.keys(selectedTopics).length : 0)
		updateStats()
	}, [selectedTopics])

	const publishChange = (changedProperty) => {
		onChange({
			key: `${product.id}-${mediaJob.id}`,
			productId: product.id,
			mediaJobId: mediaJob.id,
			selectedQuestions: Object.keys(selectedQuestions),
			attachedBefore,
			attachedAfter
		}, changedProperty)
	}

	const updateAttachmentCount = () => {
		const before = attachedBefore ? Object.keys(attachedBefore).length : 0
		const after =  attachedAfter ? Object.keys(attachedAfter).length : 0
		setAttachmentCount(before + after)
	}

	const updateStats = () => {
		const slides = []
		let totalWordCount = 0
		let totalDuration = 0
		const slide = (content) => {
			const wordCount = content.split(" ").length
			const duration = wordCount / WORDS_PER_SECOND
			totalWordCount += wordCount
			totalDuration += (duration + SECONDS_BETWEEN_SLIDES)
			slides.push({content, wordCount, duration})
		}

		Object.values(selectedTopics)
			.forEach(t => {
				t && slide(t.name)
			})

		Object.values(selectedQuestions)
			.forEach(q => {
				slide(q.question)
				q.options.forEach(o => slide(o.label))
				slide(q.options[q.answer].label)
				slide(q.explanation)
			})

		setSlides(slides)
		setWordCount(totalWordCount)
		setDuration(totalDuration)
	}

	const handleClickQuestion = (question) => {
		const updatedSelectedQuestions = {...selectedQuestions}
		if (updatedSelectedQuestions[question.id]) {
			delete updatedSelectedQuestions[question.id] // Deselect if already selected
		}
		else {
			updatedSelectedQuestions[question.id] = question // Select if not selected
		}
		setSelectedQuestions(updatedSelectedQuestions)

		// Update selected topics based on selected questions
		const updatedSelectedTopics = calculateSelectedTopics(product.topics, updatedSelectedQuestions)
		setSelectedTopics(updatedSelectedTopics)
	}

	const handleClickTopic = (topic) => {
		const updatedSelectedQuestions = {...selectedQuestions}
		const selected = selectedTopics[topic.id]
		setTopicQuestionsSelection(topic, updatedSelectedQuestions, !selected)
		setSelectedQuestions(updatedSelectedQuestions)

		// Update selected topics based on selected questions
		const updatedSelectedTopics = calculateSelectedTopics(product.topics, updatedSelectedQuestions)
		setSelectedTopics(updatedSelectedTopics)
	}

	const setTopicQuestionsSelection = (topic, selectedQuestions, selected) => {
		if (topic.questions) {
			if (!selected) {
				topic.questions.forEach((question) => {
					delete selectedQuestions[question.id] // Deselect all questions if all are selected
				})
			}
			else {
				topic.questions.forEach((question) => {
					selectedQuestions[question.id] = question // Select all questions if not all are selected
				})
			}
		}
		if (topic.topics) {
			topic.topics.forEach((subtopic) => {
				setTopicQuestionsSelection(subtopic, selectedQuestions, selected)
			})
		}
	}

	const calculateSelectedTopics = (topics, selectedQuestions, updatedSelectedTopics) => {
		updatedSelectedTopics = updatedSelectedTopics || {}
		topics.forEach((topic) => {
			if (hasSelectedQuestionInTopic(topic, selectedQuestions)) {
				//console.log("[has selected] " + topic.name)
				updateSelectedTopics(topic, updatedSelectedTopics)
				if (topic.topics) {
					calculateSelectedTopics(topic.topics, selectedQuestions, updatedSelectedTopics)
				}
			}
			else {
				//console.log("[not selected] " + topic.name + ":" + (topic.topics && topic.topics.length))
				if (topic.topics) {
					calculateSelectedTopics(topic.topics, selectedQuestions, updatedSelectedTopics)
				}
			}
		})
		return updatedSelectedTopics
	}

	const hasSelectedQuestionInTopic = (topic, selectedQuestions) => {
		if (topic.questions) {
			return topic.questions.some((question) => selectedQuestions[question.id])
		}
		if (topic.topics) {
			return topic.topics.some((subtopic) => hasSelectedQuestionInTopic(subtopic, selectedQuestions))
		}
		return false
	}

	const handleAttachBefore = async (questionOrTopic) => {
		const clip = await onGetCustomClip()
		if (clip) {
			setAttachedBefore(prev => {
				const updated = {...prev}
				updated[questionOrTopic.id] = clip.key
				return updated
			})
		}
	}

	const handleAttachAfter = async (questionOrTopic) => {
		const clip = await onGetCustomClip()
		if (clip) {
			setAttachedAfter(prev => {
				const updated = {...prev}
				updated[questionOrTopic.id] = clip.key
				return updated
			})
		}
	}

	const handleRemoveAttachmentBefore = (questionOrTopic) => {
		console.log(questionOrTopic)
		if (attachedBefore[questionOrTopic.id])
			setAttachedBefore(prev => {
				const updated = {...prev}
				delete updated[questionOrTopic.id]
				return updated
			})
	}

	const handleRemoveAttachmentAfter = (questionOrTopic) => {
		console.log(questionOrTopic)
		if (attachedAfter[questionOrTopic.id])
			setAttachedAfter(prev => {
				const updated = {...prev}
				delete updated[questionOrTopic.id]
				return updated
			})
	}

	const updateSelectedTopics = (topic, updatedSelectedTopics) => {
		updatedSelectedTopics[topic.id] = topic
		if (!Array.isArray(topic.ancestors)) {
			topic.ancestors.forEach((ancestorId) => {
				const ancestor = findTopicById(ancestorId, product.topics)
				updatedSelectedTopics[ancestorId] = ancestor
			})
		}
	}

	const findTopicById = (id, topics) => {
		if (!Array.isArray(topics)) return null
		for (let i = 0; i < topics.length; i++) {
			let topic = topics[i]
			if (topic.id == id) {
				console.log("FOUND " + topic.id)
				return topic
			}
			else {
				topic = findTopicById(id, topic.topics)
				if (topic) {
					console.log("FOUND " + topic.id)
					return topic
				}
			}
		}
		console.log("DID NOT FIND " + id)
		return null
	}

	const findAllQuestions = (topics, allQuestions, limit) => {
		topics = topics || product.topics
		allQuestions = allQuestions || {}
		for (let i = 0; i < topics.length; i++) {
			const topic = topics[i]
			if (topic.questions) {
				topic.questions.forEach((q, i) => {
					if (i < limit) allQuestions[q.id] = q
				})
			}
			else if (topic.topics) {
				findAllQuestions(topic.topics, allQuestions, limit)
			}
		}
	}

	const selectAll = (limit) => {
		limit = limit || 1000
		setQuestionCountAnchorEl(null)
		const allQuestions = {}
		findAllQuestions(product.topics, allQuestions, limit)
		console.log(allQuestions)
		const updatedSelectedTopics = calculateSelectedTopics(product.topics, allQuestions)
		setSelectedQuestions(allQuestions)
		setSelectedTopics(updatedSelectedTopics)
	}

	const selectNone = () => {
		setQuestionCountAnchorEl(null)
		setSelectedQuestions({})
		setSelectedTopics({})
	}

	if (!product || !mediaJob) {
		return null
	}

	return <Grid container>
		<Grid xs={12}>
			<Box
				sx={{
					px: 2,
					border: '1px solid ' + theme.palette.divider,
					borderBottom: 'none',
					backgroundColor: theme.palette.background.paper,
					borderTopRightRadius: '5px',
					borderTopLeftRadius: '5px'
				}}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<IconButton
						variant="outlined"
						size="small"
						onClick={() => setShow(!show)}
						sx={{mr: 1}}
					>
						{show ? <ExpandLess/> : <ExpandMore/>}
					</IconButton>

					<Divider
						sx={{ml: 1, mr: 2}}
						orientation="vertical"
						flexItem
					/>
					<Box sx={{my: 2}}>
						<h3 style={{padding: 0, margin: 0}}>
							{product.name}
							<Chip size="small" variant="outlined" sx={{ml: 1}} label={`ID: ${mediaJob.id}`}/>
							<Chip size="small" variant="outlined" sx={{ml: 1}} label={fmtDttm(mediaJob.created_at)}/>
							<Chip size="small" variant="outlined" sx={{ml: 1}} label={mediaJob.tts_model}/>
							<Chip size="small" variant="outlined" sx={{ml: 1}} label={mediaJob.voice_id}/>
						</h3>
						<Chip
							label={`${selectedTopicCount} Topics`}
							sx={{mt: 1}}
							variant={'outlined'}
							color={selectedTopicCount ? 'warning' : 'default'}
						/>
						<Chip
							label={`${selectedQuestionCount} Questions`}
							sx={{mt: 1, ml: 1}}
							variant={'outlined'}
							color={selectedQuestionCount ? 'info' : 'default'}
						/>
						<Chip
							label={`${attachmentCount} Custom Clips`}
							sx={{mt: 1, ml: 1}}
							variant={'outlined'}
							color={attachmentCount ? 'error' : 'default'}
						/>
					</Box>
				</Box>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<ButtonGroup variant="outlined">
						<Button
							size="small"
							onClick={(e) => setQuestionCountAnchorEl(e.currentTarget)}
							endIcon={<KeyboardArrowDown/>}
						>
							<Checklist />
						</Button>
						<Menu
							open={questionCountMenuOpen}
							anchorEl={questionCountAnchorEl}
							onClose={(e) => setQuestionCountAnchorEl(null)}
						>
							<MenuItem onClick={() => selectAll()}>
								Select All
							</MenuItem>
							<MenuItem onClick={selectNone}>
								Select None
							</MenuItem>
							<Divider sx={{my: 0.5}}/>
							{
								Array.from({length: 15}, (_, index) => index + 1)
									.map(i => <MenuItem key={`select-some-${i}`} onClick={() => {
										selectAll(i)
										setQuestionCountAnchorEl(null)
									}}>
										Select First {i}
									</MenuItem>)
							}
						</Menu>
						<Tooltip title="Remove this Product/Media Job">
							<Button
								xs={{ml: 1}}
								onClick={()=>onRemove(product, mediaJob)}
							>
								<DeleteOutline/>
							</Button>
						</Tooltip>
					</ButtonGroup>
				</Box>
			</Box>
		</Grid>
		<Grid xs={12}>
			<Box
				sx={{
					border: '1px solid ' + theme.palette.divider,
					backgroundColor: 'transparent',
					borderBottomRightRadius: '5px',
					borderBottomLeftRadius: '5px',
					marginBottom: '20px'
				}}
			>
				{
					show &&
					<MediaTree topics={product.topics}
					           selectedTopics={selectedTopics}
					           selectedQuestions={selectedQuestions}
					           attachedBefore={attachedBefore}
					           attachedAfter={attachedAfter}
					           hideExcluded={hideExcluded}
					           onClickTopic={handleClickTopic}
					           onClickQuestion={handleClickQuestion}
					           onAttachBefore={handleAttachBefore}
					           onAttachAfter={handleAttachAfter}
					           onRemoveAttachmentBefore={handleRemoveAttachmentBefore}
					           onRemoveAttachmentAfter={handleRemoveAttachmentAfter}
					/>
				}
			</Box>
		</Grid>
	</Grid>
}

export default ProductMediaTree
