import {Avatar, Box, Typography, useTheme} from "@mui/material"
import Score                               from "./Score"
import StreamingMarkdown         from "../prepkit/chat/StreamingMarkdown"
import React                     from "react"

const bw = 0

const TopicScore = ({topic, aiMessage, continueButtons}) => {

	const theme = useTheme()

	return <Box
		display={"flex"}
		flexDirection={"column"}
		justifyContent={"flex-start"}
		alignItems={"center"}
		maxWidth={"800px"}
		width={"100%"}
		alignSelf={"center"}
		border={`${bw}px solid red`}
		px={2}
	>
		{
			<Box>
				<Box pl={2}>
					<Score variant={"contained"}
					       topic={topic}
					       size={85}
					       animate={true}
					       complete={1}/>
				</Box>

				<Typography variant="h5"
				            sx={{
					            pt: 3,
					            pb: 0,
					            display: "block",
					            textAlign: "center",
					            border: "px solid red",
				            }}
				>
					{topic.name} Complete!
				</Typography>
			</Box>

		}
		{
			aiMessage &&
			aiMessage.streaming === false &&
			continueButtons &&
				<Box sx={{
					position: "fixed",
					bottom: 0,
					left: 0,
					right: 0,
					py: 1,
					backgroundColor: theme.palette.background.paper
				}}>
					{continueButtons}
				</Box>

		}
		{
			topic &&
			aiMessage &&
			<Box
				border={`${bw}px solid orange`}
				width={"100%"}
				display={"flex"}
				flex={1}
				flexDirection={"column"}
				justifyContent={"flex-start"}
				alignItems={"flex-start"}
			>
				<Box
					border={`${bw}px solid magenta`}
					width={"100%"}
					display={"flex"}
					flex={1}
					flexDirection={"row"}
					alignSelf={"flex-start"}
					justifyContent={"flex-start"}
					alignItems={"flex-start"}
					textAlign={"left"}
					py={3}
				>
					<Avatar src="/birdsy-avatar-black.png"
					        sx={{
						        border: '1px solid ' + theme.palette.divider,
						        padding: '2px',
						        width: 30,
						        height: 30,
						        mr: 2,
						        ml: 0,
						        mt: 2,
						        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
					        }}/>
					<StreamingMarkdown text={aiMessage.body}
					                   streaming={aiMessage.streaming}
					/>
				</Box>
			</Box>
		}
	</Box>
}

export default TopicScore