import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

const ConfirmDialog = ({ open, title, icon, content, onConfirm, onCancel }) => {
	return (
		<Dialog
			open={open}
			onClose={onCancel}
		>
			<DialogTitle>{icon} {title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{content}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					Cancel
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDialog
