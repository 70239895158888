import {ArrowBack, ArrowLeft, ArrowRight} from "@mui/icons-material"
import {getSupabase}                      from "../../util/supabase-utils"
import {
	Box,
	Breadcrumbs,
	Container, IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material"
import React, {
	useEffect,
	useState
}                    from "react"
import moment        from "moment"
import Grid          from "@mui/material/Unstable_Grid2"
import {useNavigate} from "react-router-dom"
import {doGet}       from "../../util/do-fetch"
import {logger}      from "../../util/log-utils"

const log = logger("Users", 1)

function Users() {
	const navigate = useNavigate()
	const [users, setUsers] = useState(null)

	useEffect(() => {
		fetchUsers()
	}, [])

	const actionButtons = [
		{
			color: 'primary',
			size: 'small',
			drawer: 'left',
			drawerContent: () => (
				<div>
					LEFT DRAWER
				</div>
			),
			buttonContent: () => (
				<ArrowLeft/>
			)
		},
		{
			color: 'secondary',
			size: 'small',
			drawer: 'right',
			drawerContent: () => (
				<div>
					RIGHT DRAWER
				</div>
			),
			buttonContent: () => (
				<ArrowRight/>
			)
		}
	]


	const fetchUsers = async () => {

		try {
			const response = await doGet(`/admin/users`)
			const data = await response.json()
			setUsers(data)
		}
		catch (err) {
			log.error(err)
		}

	}

	return (
		<Container maxWidth="lg">
			<Grid xs={12}>
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack/>
					</IconButton>
					<Typography variant={"h5"}>
						Users
					</Typography>
				</Box>
			</Grid>
			<Grid xs={12}>
				<TableContainer>
					<Table sx={{border: "1px solid #ccc"}} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell align="left">ID</TableCell>
								<TableCell align="left">Role</TableCell>
								<TableCell align="right">Email Confirmed</TableCell>
								<TableCell align="right">Last Sign In</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users && users.map((row) => (
								<TableRow
									key={row.email}
									sx={{'&:last-child td, &:last-child th': {border: 0}}}
								>
									<TableCell component="th" scope="row">
										{row.email}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.id}
									</TableCell>
									<TableCell align="left">{row.role}</TableCell>
									<TableCell align="right">{moment(row.email_confirmed_at)
										.calendar()}</TableCell>
									<TableCell align="right">{moment(row.last_sign_in_at)
										.calendar()}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Container>

	)
}

export default Users