import React, { useState, useEffect, useRef }                              from 'react'
import {Button, Slider, Typography, Box, ButtonGroup, IconButton, Divider} from '@mui/material'
import {Pause, PlayArrow, Replay, SkipNext, SkipPrevious}                  from "@mui/icons-material"

const RSVPReader = ({ text }) => {
	const [words, setWords] = useState([])
	const [currentWordIndex, setCurrentWordIndex] = useState(0)
	const [isPlaying, setIsPlaying] = useState(false)
	const [lastTime, setLastTime] = useState(0)
	const [wordsPerMinute, setWordsPerMinute] = useState(200)
	const isPlayingRef = useRef(false)
	const lastTimeRef = useRef(0)
	const wordsPerMinuteRef = useRef(200)

	useEffect(() => {
		const punctuationMarks = [',', ';', ':']
		const punctuationMarksLong = ['.','!','?']

		const wordsPadded = []

		text.split(' ').forEach((word) => {
			const lastChar = word.slice(-1)
			if (punctuationMarks.includes(lastChar)) {
				wordsPadded.push(word)
			}
			else if (punctuationMarksLong.includes(lastChar)) {
				wordsPadded.push(word)
				wordsPadded.push(word)
			}
			wordsPadded.push(word)
		})
		setWords(wordsPadded)

	}, [text])

	useEffect(() => {
		isPlayingRef.current = isPlaying
	}, [isPlaying])

	useEffect(() => {
		lastTimeRef.current = lastTime
	}, [lastTime])

	useEffect(() => {
		wordsPerMinuteRef.current = wordsPerMinute
	}, [wordsPerMinute])

	useEffect(() => {
		let timer;
		if (isPlaying) {
			const interval = (60 / wordsPerMinute) * 1000;
			timer = setInterval(loop, interval);
		} else if (!isPlaying && timer) {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [isPlaying, wordsPerMinute]);

	const loop = () => {
		setCurrentWordIndex((prevIndex) => {
			if (prevIndex >= words.length - 1) {
				setIsPlaying(false);
				return 0;
			}
			return prevIndex + 1;
		});
	};

	useEffect(() => {
		if (isPlayingRef.current) {
			setLastTime(performance.now())
		}
		return () => {
			setIsPlaying(false)
		}
	}, [])

	const handlePlay = () => {
		setIsPlaying(true)
		requestAnimationFrame(loop)
	}

	const handlePause = () => {
		setIsPlaying(false)
	}

	const handleRestart = () => {
		setCurrentWordIndex(0)
	}

	const handleWPMChange = (event, newValue) => {
		setWordsPerMinute(newValue)
	}

	const handleScrub = (event, newValue) => {
		setCurrentWordIndex(newValue)
	}

	return (
		<Box textAlign="center">
			<Typography variant="h1">
				{words[currentWordIndex]}
			</Typography>
			<Typography variant="h4">
				{currentWordIndex}
			</Typography>
			{/*
			<ButtonGroup variant="contained" color="grey">
				<Button onClick={handlePlay}>
					<PlayArrow />
				</Button>
				<Button onClick={handlePause}>
					<Pause />
				</Button>
				<Button onClick={handleRestart}>
					<Replay />
				</Button>
			</ButtonGroup>
			*/}
			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				pl: 1,
				pb: 1,
				border: "0px solid red"
			}}>
				<IconButton onClick={handleRestart}>
					<SkipPrevious />
				</IconButton>
				{
					isPlaying
					? <IconButton onClick={handlePause}>
							<Pause sx={{ height: 38, width: 38 }} />
					  </IconButton>
					: <IconButton onClick={handlePlay}>
							<PlayArrow sx={{ height: 38, width: 38 }} />
					  </IconButton>
				}
				<IconButton onClick={handleRestart}>
					<SkipNext />
				</IconButton>
			</Box>

			<Slider
				min={0}
				max={words.length}
				value={currentWordIndex}
				onChange={handleScrub}
			/>

			<Divider/>

			<Slider
				min={60}
				max={500}
				value={wordsPerMinute}
				onChange={handleWPMChange}
			/>
			<Typography variant="caption">
				{`${wordsPerMinute} WPM`}
			</Typography>
		</Box>
	)
}

export default RSVPReader
