import React, {useContext, useEffect, useMemo, useState} from 'react'
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader, Chip, CircularProgress,
	Divider, FormControlLabel,
	IconButton, Link,
	List, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	TextField,
	useTheme,
} from '@mui/material'
import {logger}                                                             from "../../util/log-utils"
import {Check, Close, Compare, CompareArrows, Edit, ExpandLess, ExpandMore} from "@mui/icons-material"
import {doPost, doPut}                                                      from "../../util/do-fetch"
import MediaJobItemFilter                                                   from "./MediaJobItemFilter"
import AudioPlayer                                                          from "./AudioPlayer"
import AudioPreviewPlayer                                                   from "./AudioPreviewPlayer"
import EditableLabel                                                        from "../EditableLabel"
import CheckIcon                                                            from "@mui/icons-material/Check"
import CloseIcon                                                            from "@mui/icons-material/Close"
import LeftRight                                                            from "../LeftRight"
import {Alert}                                                              from "@mui/material"

const log = logger("ContentReviewTable", 1)


// stateless
const matchFilter = (mediaJobItem, regex) => {
	if (!regex) return true
	// Use the regex to test against the content
	return regex.test(mediaJobItem.content)
}

const highlightMatches = (text, regex, color, textColor) => {
	if (!regex || !text) return text
	return text.replace(regex, match => `<span style="background-color: ${color}; color: ${textColor}">${match}</span>`)
}

const parseRegExp = (expr) => {
	// Check if the string starts and ends with `/`
	const regexPattern = /^\/(.*)\/([igmus]*)$/

	// Test the string against the pattern
	const match = expr.match(regexPattern)

	if (match) {
		// Extract the pattern and flags
		const pattern = match[1]
		const flags = match[2]

		// Construct a new RegExp object
		return new RegExp(pattern, flags)
	} else {
		return new RegExp(expr)
	}
}

const ContentReviewTable = ({product, mediaJob, audioJobItems, onSaveMediaJobItem}) => {

	const theme = useTheme()
	const [filter, setFilter] = useState({})
	const [filteredItems, setFilteredItems] = useState(audioJobItems)
	const [replacing, setReplacing] = useState(false)
	const [errors, setErrors] = useState(null)
	let prevQid = null

	const searchTerm = useMemo(()=>{
		return filter
			? filter.searchTerm
			: null
	}, [filter])

	const regex = useMemo(() => {
		if (searchTerm) {
			try {
				return parseRegExp(searchTerm)
			} catch (e) {
				return null
			}
		} else {
			return null
		}
	}, [searchTerm])

	useEffect(() => {
		if(filter) {
			const filteredIds = {}
			const items = audioJobItems.filter(i => {

				// Check if the item needs to be only modified
				const isModified = filter.onlyModified
					? i.original_content !== null
					: true

				// Check if the search term is part of the item content
				const matchesSearchTerm = matchFilter(i, regex)

				if(matchesSearchTerm) {
					filteredIds[`t${i.topic_id}`] = true
					if(i.question_id) {
						filteredIds[`q${i.question_id}`] = true
					}
				}

				// Only return true if all conditions are met
				return isModified && matchesSearchTerm
			})

			setFilteredItems(items)
		}
		else {
			setFilteredItems(audioJobItems)
		}

	}, [audioJobItems, filter])


	const handleReplaceAll = async (replaceWith) => {
		setReplacing(true)
		setErrors(null)

		try {
			// Prepare the data to send to the backend
			const itemIds = filteredItems.map(item => item.id)

			// Ensure the regex is in a format that can be used in SQL/Supabase
			const flags = regex.flags.includes('g') ? regex.flags : regex.flags + 'g'
			const regexPattern = regex.source

			// Make a single backend call with the list of item IDs and the regex/replacement
			const options = {
				body: JSON.stringify({
					itemIds,
					regex: {
						pattern: regexPattern,
						flags: flags
					},
					replaceWith
				})
			}
			const response = await doPost(`/admin/products/${product.id}/media-jobs/${mediaJob.id}/tts-find-replace`,
				options)

			// Update the local items or refresh the list as needed
			console.log(`Successfully updated ${itemIds.length} items.`)

		} catch (exc) {
			setErrors([
				`Error during replace all. Failed to update items.`,
				`Exception: ${exc.toString()}`
			])
		} finally {
			setReplacing(false)
		}
	}



	const handleReplaceAllOriginal = async (replaceWith) => {
		// work off a cloned array so incoming state changes don't modify the
		// source array while we are working on it
		setReplacing(true)
		setErrors(null)

		const flags = regex.flags.includes('g') ? regex.flags : regex.flags + 'g'
		// Return a new RegExp with the same pattern and updated flags
		const replaceRegExp = RegExp(regex.source, flags)

		let updateCount = 0
		let item
		try {
			const updated = [...filteredItems]
			for (let i = 0; i < updated.length; i++) {
				item = updated[i]
				console.log({regex, replaceWith})
				const editedValue = item.content.replaceAll(replaceRegExp, replaceWith)
				console.log(editedValue)
				await onSaveMediaJobItem(item, editedValue)
				updateCount++
			}
		}
		catch(exc) {
			setErrors([
				`Error during replace all.  Only ${updateCount} item(s) updated.`,
				`Failed on item [id=${item.id}] [type=${item.job_type}] ${item.content}.`,
				`Exception: ${exc.toString()}`
			])
		}
		finally{
			setReplacing(false)
		}
	}

	return <Box>
		<MediaJobItemFilter filteredItemCount={filteredItems && filteredItems.length}
		                    onChange={setFilter}
		                    onReplaceAll={handleReplaceAll}
		/>
		{
			errors &&
			errors.length &&
			<Alert color="error" onClose={()=>setErrors(null)}>
				{
					errors.map(e => <div><code>{e}</code></div>)
				}
			</Alert>
		}

		<TableContainer sx={{mt: 2, backgroundColor: theme.palette.background.paper}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell variant="head">
							<b>Media Type</b>
						</TableCell>
						<TableCell variant="head">
							<b>Job Type</b>
						</TableCell>
						<TableCell variant="head">
							<b>TTS Content</b>
						</TableCell>
						<TableCell variant="head">
							<b>Original Content</b>
						</TableCell>
						<TableCell variant="head">

						</TableCell>
						<TableCell variant="head">

						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						filteredItems &&
						filteredItems.map(i => {
							const diffQid = i.question_id !== prevQid
							prevQid = i.question_id
							return <ContentReviewTableRow
								key={i.id}
								mediaJob={mediaJob}
								item={i}
								regex={regex}
								showBreak={diffQid}
								onSave={onSaveMediaJobItem}
							/>
						})
					}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
}

const ContentReviewTableRow = ({mediaJob, item, regex, showBreak, onSave}) => {

	const theme = useTheme()
	const [edit, setEdit] = useState(false)
	const [saving, setSaving] = useState(false)
	const [editValue, setEditValue] = useState(item.content)
	const [originalValue, setOriginalValue] = useState(item.original_content || item.content)

	const handleSave = async () => {
		if(!saving) {
			setSaving(true)
			await onSave(item, editValue)
			setEdit(false)
			setSaving(false)
		}
	}

	const handleCancel = () => {
		if(!saving) {
			setEdit(false)
			setEditValue(item.content)
		}
	}

	const style = showBreak ? {borderTop: "2px dashed black"} : {}

	return <TableRow style={style}>
		<TableCell>
			{item.media_type}
		</TableCell>
		<TableCell>
			{item.job_type}
		</TableCell>
		<TableCell>
			{
				edit
					? <Box display="flex" flex="1" sx={{p: 1}}>
						<TextField
							size="small"
							fullWidth={true}
							value={editValue}
							multiline={true}
							onChange={(e) => setEditValue(e.target.value)}
							autoFocus
							sx={{
								backgroundColor: theme.palette.background.paper
							}}
						/>
					</Box>
					: <span dangerouslySetInnerHTML={{ __html: highlightMatches(item.content, regex, 'yellow', 'black') }} />
			}
		</TableCell>
		<TableCell sx={{fontFamily: 'Poppins-Italic'}}>
			<span dangerouslySetInnerHTML={{ __html: highlightMatches(item.original_content, regex, 'yellow', 'black') }} />
		</TableCell>
		<TableCell>
			{
				edit
					? <LeftRight
						left={
							<IconButton disabled={saving} onClick={handleSave}>
								<CheckIcon sx={{color: saving ? theme.palette.text.disabled : theme.palette.score.success}}/>
							</IconButton>
						}
						right={
							<IconButton disabled={saving} onClick={handleCancel}>
								<CloseIcon
									sx={{color: saving
											? theme.palette.text.disabled
											: theme.palette.score.error
									}}/>
							</IconButton>
						}
					  />
					: <Button onClick={() => setEdit(true)}>Edit</Button>
			}
		</TableCell>
		<TableCell>
			<Box style={{minWidth: '50px'}}>
				<AudioPreviewPlayer tts={editValue} model={mediaJob.tts_model} voiceId={mediaJob.voice_id} />
			</Box>
		</TableCell>
	</TableRow>
}



const orderBy = ['question', 'option-a', 'option-b', 'option-c', 'option-d', 'answer', 'explanation']

const findMediaJobItems = (topicOrQuestion, mediaJobItems) => {

	if (topicOrQuestion.topic_id) {
		// this is a question
		const q = topicOrQuestion
		const items = mediaJobItems && mediaJobItems.filter(i => i.question_id === q.id)
		// put in order of question, option-a, option-b, option-c, option-d, answer, explanation
		return items.sort((a, b) => {
			return orderBy.indexOf(a.job_type) - orderBy.indexOf(b.job_type)
		})
	}
	else {
		// this is a topic
		const t = topicOrQuestion
		return mediaJobItems.filter(i => i.question_id === null && i.topic_id === t.id)
	}

}

export default ContentReviewTable
