import React, {useMemo} from 'react'
import Markdown         from 'react-markdown'
import PulsingCircle from './PulsingCircle'
import {useTheme}    from "@mui/material"


const blackDot = '  ⚫';
const whiteDot = '  ⚪️';

const StreamingMarkdown = ({ text, streaming }) => {
	const theme = useTheme();

	const streamingDot = useMemo(() => {
		return theme.palette.mode === 'dark' ? whiteDot : blackDot;
	}, [theme.palette.mode]);

	return (
		!text || text === ""
			? <PulsingCircle fontSize="22px" sx={{ m: 1 }} />
			: <Markdown className="markdown">
				{text + (streaming ? streamingDot : '')}
			</Markdown>
	);
};


export default StreamingMarkdown;
