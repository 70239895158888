import React, {useEffect, useState} from 'react'
import BirdsyMiniLogo               from "../BirdsyMiniLogo"
import {
	Box, Button,
	Chip,
	Divider,
	IconButton, List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	useTheme
} from "@mui/material"
import {
	AccountCircleOutlined,
	ArrowBack,
	AutoAwesome,
	Check,
	CheckCircle, ChevronRight, Close,
	CloseOutlined,
	Logout, More, UnfoldMore
} from "@mui/icons-material"
import AccountCircle  from "@mui/icons-material/AccountCircle"
import ThemeToggle    from "../ThemeToggle"
import {useUserStore} from "../../state"
import {useNavigate}    from "react-router-dom"
import ScoreAndProgress from "./ScoreAndProgress"
import LeftRight        from "../LeftRight"

const TopicQuestionToc = ({selectedTopic, answers, selectedQuestion, onTopicClick, onQuestionClick}) => {

	const theme = useTheme()
	const [scoredTopics, setScoredTopics] = useState(null)

	useEffect(()=>{
		if(selectedTopic && answers) {
			scoreTopic(selectedTopic, answers)
			setScoredTopics(selectedTopic)
		}
	}, [selectedTopic, answers])

	const handleTopicClick = (t) => {
		onTopicClick(t)
	}

	const handleQuestionClick = (q) => {
		onQuestionClick(q)
	}

	const scoreTopic = (t, as) => {
		console.log(`scoreTopic ${t.id}`)
		let answeredCount = 0
		let correctCount = 0
		let totalQuestions = 0

		if (t.topics) {
			for (let i = 0; i < t.topics.length; i++) {
				scoreTopic(t.topics[i], as)
			}

			// Calculate average percentComplete and percentCorrect for parent topics
			const totalChildTopics = t.topics.length
			t.percentComplete = t.topics.reduce((acc, t) => acc + (t.percentComplete || 0), 0) / totalChildTopics
			t.percentCorrect = t.topics.reduce((acc, t) => acc + (t.percentCorrect || 0), 0) / totalChildTopics
		}
		else if (t.questions) {
			totalQuestions = t.questions.length

			for (let x = 0; x < t.questions.length; x++) {
				const q = t.questions[x]
				const a = as.find(o => o.question_id === q.id)
				if (a) {
					q.userAnswer = a
					answeredCount++
					if (a.is_correct) {
						correctCount++
					}
				}
			}

			t.percentComplete = totalQuestions > 0 ? (answeredCount / totalQuestions) * 100 : 0
			t.percentCorrect = totalQuestions > 0 ? (correctCount / totalQuestions) * 100 : 0
		}
	}

	const renderTopicsAndQuestions = (topics) => {
		return <TopicsAndQuestions
			topics={topics}
			selectedQuestion={selectedQuestion}
			handleTopicClick={handleTopicClick}
			handleQuestionClick={handleQuestionClick}
		/>
	};


	return <Box>
		{ selectedTopic
			&& renderTopicsAndQuestions(selectedTopic.topics)
		}
	</Box>
}

const TopicPath = ({topic}) => {

	return topic &&
		topic.path &&
		<div><small>{topic.path.split("/").slice(0, -1).filter(p => p && p!=='').join(" · ")}</small></div>
}

const TopicItem = ({ topic, onClick }) => {
	const theme = useTheme()

	return (
		<ListItem
			button
			onClick={() => onClick(topic)}
			sx={{
				pl: 2,
				cursor: 'pointer',
				color: theme.palette.primary.main,
			}}
		>
			{topic.name}
		</ListItem>
	);
};

const QuestionItem = ({ question, index, selected, onClick }) => {
	const theme = useTheme()

	let borderColor, bgColor, color;

	if (question.userAnswer) {
		if (question.userAnswer.is_correct) {
			borderColor = theme.palette.success.main;
			bgColor = theme.palette.success.main;
			color = theme.palette.success.contrastText;
		} else {
			borderColor = theme.palette.error.main;
			bgColor = theme.palette.error.main;
			color = theme.palette.error.contrastText;
		}
	} else {
		borderColor = theme.palette.primary.main;
		bgColor = 'transparent';
		color = theme.palette.text.primary;
	}

	return (
		<ListItem
			key={question.id}
			selected={selected}
			sx={{
				pl: 4,
				pt: 1,
				pb: 1,
				width: '45vw',
			}}
			onClick={() => onClick(question)}
		>
			<Box
				sx={{
					border: `1px solid ${borderColor}`,
					backgroundColor: bgColor,
					color: color,
					minWidth: '32px',
					height: '32px',
					borderRadius: '32px',
					mr: 1.5,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{index + 1}
			</Box>
			<Box sx={{ pt: 0.6 }}>
				{question.question}
				{question.userAnswer && (
					<Chip
						sx={{ ml: 1 }}
						color={question.userAnswer.is_correct ? 'success' : 'error'}
						label={question.userAnswer.is_correct ? 'Correct' : 'Incorrect'}
						variant="outlined"
						size="small"
						icon={question.userAnswer.is_correct ? <Check /> : <Close />}
					/>
				)}
			</Box>
		</ListItem>
	);
};

const TopicsAndQuestions = ({ topics, selectedQuestion, handleTopicClick, handleQuestionClick }) => {

	const [show, setShow] = useState(false)

	return (
		<List>
			<ListItem>
				<Button onClick={(()=>setShow(!show))}>{show ? 'Hide' : 'Show'}</Button>
			</ListItem>
			{topics.map((topic) => (
				<React.Fragment key={topic.id}>
					<TopicItem topic={topic} onClick={handleTopicClick} />

					{topic.questions && topic.questions.map((q, i) => !show ? null : (
						<QuestionItem
							key={q.id}
							question={q}
							index={i}
							selected={selectedQuestion && selectedQuestion.id === q.id}
							onClick={handleQuestionClick}
						/>
					))}
				</React.Fragment>
			))}
		</List>
	);
};

export default TopicQuestionToc;
