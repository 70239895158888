import React, { useState }                                                                           from 'react'
import { Card, CardContent, CardActions, Typography, Box, Button, IconButton, Menu, MenuItem, Chip } from '@mui/material'
import MoreVertIcon                                                                                  from '@mui/icons-material/MoreVert'
import LeftRight                                                                                     from "../LeftRight"
import {useNavigate}                                                                                 from "react-router-dom"
import CategoryLabel
                                                                                                     from "./CategoryLabel"

const PackageCard = ({ packageData, publishStatus, onDuplicate, onUnpublish, onPublish, onUnarchive, onArchive }) => {
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null)

	const handleMenuOpen = (event) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<Card
			sx={{cursor: 'pointer'}}
			onClick={() => {
				navigate(`/admin/packages/${packageData.id}/edit`)
			}}
		>
			<CardContent>
				<LeftRight
					left={<Typography variant="h5">{packageData.name}</Typography>}
					right={publishStatus && <Chip label={publishStatus.label} color={publishStatus.color} />}
				/>
				<Typography variant="body2" color="textSecondary">
					{packageData.tagline}
				</Typography>
			</CardContent>
			<CardActions>
				<LeftRight
					left={
						<CategoryLabel sx={{ml:1}} variant={"body2"} category={packageData.category}/>
					}
					right={<Box>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={handleMenuOpen}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								onClick={(event) => event.stopPropagation()}
							>
								<MenuItem onClick={()=> {
									handleMenuClose()
									onDuplicate(packageData)
								}}>
									Duplicate
								</MenuItem>

								{
									packageData.published_at ? (
										<MenuItem onClick={() => { onUnpublish(packageData); handleMenuClose() }}>
											Unpublish
										</MenuItem>
									) : (
										<MenuItem onClick={() => { onPublish(packageData); handleMenuClose() }}>
											Publish
										</MenuItem>
									)
								}
								{
									packageData.archived_at ? (
										<MenuItem onClick={() => { onUnarchive(packageData); handleMenuClose() }}>
											Unarchive
										</MenuItem>
									) : (
										<MenuItem onClick={() => { onArchive(packageData); handleMenuClose() }}>
											Archive
										</MenuItem>
									)
								}
							</Menu>
						</Box>
					}
				/>
			</CardActions>
		</Card>
	)
}

export default PackageCard
