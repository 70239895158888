import React                from 'react';
import MiniNavbar           from '../mini/MiniNavbar'; // Create a specific Navbar for MiniQuestionView
import { Outlet }           from 'react-router-dom';
import {Box, useMediaQuery} from "@mui/material"
import Navbar               from "../Navbar"

const MiniLayout = ({ toggleTheme }) => {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

	return (
		<Box
			border={`0px solid purple`}
			display={"flex"}
			flex={1}
		>
			<Navbar toggleTheme={toggleTheme} />
			<Box
				mt={`${isSmallScreen ? 58 : 72}px`}
				display={"flex"}
				flex={1}
				border={"0px solid green"}
				flexDirection={"column"}
				justifyContent={"flex-start"}
				alignItems={"center"}
			>
				<Outlet /> {/* This will render the matched child route */}
			</Box>
		</Box>
	);
};

export default MiniLayout;
