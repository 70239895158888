import {CardElement, useElements, useStripe, PaymentRequestButtonElement} from '@stripe/react-stripe-js'
import {useMemo, useState, useEffect} from 'react'
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Link,
	Typography,
	useTheme
}                      from "@mui/material"
import {Alert}         from "@mui/material"
import {Lock}          from "@mui/icons-material"
import LeftRight       from "../LeftRight"
import {fmt2Dec}       from "../../util/prepkit-utils"
import moment          from "moment"

const MiniCheckout = ({clientSecret, selectedPackage, selectedOption, onPurchaseComplete}) => {
	const theme = useTheme()
	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState(null)
	const [processing, setProcessing] = useState(false)
	const [termsAccepted, setTermsAccepted] = useState(false)
	const [paymentRequest, setPaymentRequest] = useState(null)

	const optionAmount = useMemo(() => {
		return `$${fmt2Dec(selectedOption.price_in_cents / 100)}`
	}, [selectedOption])

	const optionExpires = useMemo(() => {
		const today = moment()
		today.add(selectedOption.license_days, 'd')
		return today.format('MM-DD-YYYY h:mm a')
	}, [selectedOption])

	const cardElementOptions = {
		style: {
			base: {
				color: theme.palette.text.primary,
				fontFamily: 'Helvetica, Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: theme.palette.text.secondary,
				},
			},
			invalid: {
				color: theme.palette.secondary.main,
				iconColor: theme.palette.secondary.main,
			},
		},
	}

	// Payment Request Button setup
	useEffect(() => {
		if (stripe && clientSecret && onPurchaseComplete) {
			const pr = stripe.paymentRequest({
				country: 'US',
				currency: 'usd',
				total: {
					label: selectedPackage.name,
					amount: selectedOption.price_in_cents,
				},
				requestPayerName: true,
				requestPayerEmail: true,
				disableLink: true
			})

			// Check if Payment Request Button can be displayed (Apple Pay / Google Pay)
			pr.canMakePayment().then(result => {
				if (result) {
					setPaymentRequest(pr)
				}
			})

			pr.on('paymentmethod', async (ev) => {
				if (!clientSecret) {
					setError("Payment intent not ready")
					return ev.complete('fail')
				}

				setProcessing(true)
				const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
					payment_method: ev.paymentMethod.id
				})

				console.log(JSON.stringify({paymentIntent}))

				if (error) {
					ev.complete('fail')
					setError(error.message)
					setProcessing(false)
				} else {
					ev.complete('success')
					onPurchaseComplete(paymentIntent)
					setProcessing(false)
				}
			})
		}
	}, [stripe, selectedPackage, selectedOption, clientSecret, onPurchaseComplete])

	const handleSubmit = async (event) => {
		event.preventDefault()
		setProcessing(true)

		const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement)
			}
		})

		if (error) {
			setError(error.message)
			setProcessing(false)
		}
		else {
			onPurchaseComplete(paymentIntent)
			setProcessing(false)
		}
	}

	return (
		<Box>
			<form onSubmit={handleSubmit}>
				<Box
					m={2}
					mt={1}
					border={1}
					borderColor={theme.palette.divider}
					borderRadius={1}
					p={2}
				>
					<LeftRight
						left={<Box>{selectedPackage.name}</Box>}
						right={<Typography variant={"h7"}>
							{optionAmount}
						</Typography>}
					/>
					<Box>{selectedOption.name}</Box>
				</Box>

				<FormControl sx={{p: 2}} component="fieldset" required>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={termsAccepted}
									onChange={(event) => {
										setTermsAccepted(event.target.checked)
									}}
									name="terms"
									color="primary"
								/>
							}
							label={
								<Typography variant="body2">
									I agree to the <Link href="https://birdsy.ai/terms-and-conditions" target="_blank"
									                     rel="noopener noreferrer">Terms and
									Conditions</Link> and <Link href="https://birdsy.ai/privacy-policy" target="_blank"
									                            rel="noopener noreferrer">Privacy Policy</Link>
								</Typography>
							}
						/>
					</FormGroup>
				</FormControl>

				{/* ADD: "Securing payment connection" loading indicator if clientSecret is not yet available */}
				{!clientSecret ? (
					<Box pb={5} textAlign={"center"}>
						<CircularProgress size={18}/> Securing payment connection
					</Box>
				) : (
					<Box position="relative" mx={2} mb={2} sx={{ opacity: termsAccepted ? 1 : 0.5 }}>

						{/* Card Element for manual entry */}
						<Box p={1} my={1}sx={{
							border: `2px solid ${theme.palette.success.main}`,
							borderRadius: 2
						}}>
							<Box p={1}>
								<CardElement options={cardElementOptions}/>
							</Box>
							<Button
								sx={{mt: 2, textTransform: "none", boxShadow: "none"}}
								type="submit"
								size="large"
								variant={"contained"}
								color="success"
								fullWidth={true}
								disabled={!stripe || processing || !termsAccepted}
							>
								<Lock fontSize="small" sx={{mr: 1}}/>
								{processing ? 'Processing...' : 'Pay'}
							</Button>
						</Box>

						{/* Payment Request Button for Apple Pay/Google Pay */}
						{paymentRequest &&
							<Box px={1}>
								<Typography variant={"body2"} sx={{textAlign: "center", pb: 1}}>
									or, pay with
								</Typography>

								<PaymentRequestButtonElement
									options={{ paymentRequest }}
									style={{ paymentRequestButton: { type: 'default' } }}
								/>
							</Box>
						}

						{!termsAccepted && (
							<Box
								position="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
								display="flex"
								alignItems="center"
								justifyContent="center"
								bgcolor="rgba(255, 255, 255, 0.8)"
								sx={{ borderRadius: 1 }}
							>
							</Box>
						)}
					</Box>
				)}

				{error && (
					<Alert
						severity="error"
						sx={{m: 2}}
						onClose={() => setError(null)}
					>
						{error}
					</Alert>
				)}
			</form>
		</Box>
	)
}

export default MiniCheckout
