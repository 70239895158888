import '../stylesheets/App.css'
import {useEffect, useState} from "react"
import {useNavigate}         from "react-router-dom"
import {Button}              from "@mui/material"
import {doPost}              from "../util/do-fetch"
import {useUserStore}        from "../state"
import {logger}              from "../util/log-utils"
import getSupabase           from "../util/supabase-utils"

const log = logger("CheckAccess", 1)

function CheckAccess(props) {

	const [rows, setRows] = useState(null)
	const [tableOrView, setTableOrView] = useState('')
	const [working, setWorking] = useState(false)

	const checkAccess = () => {
		const supabase = getSupabase()
		supabase.auth.getSession()
			.then(async ({data: {session}}) => {
				const {data, error} = await supabase
					.from(tableOrView)
					.select('*')
					.limit(100)
				if (error) {
					console.error('Error fetching from ' + tableOrView, error)
					return null
				}
				setRows(data)
			})
	}


	return (

		<header>
			<div  style={{maxWidth: '1024px', margin: '0 auto', textAlign: 'center', paddingTop: '30px'}}>
				<input type={"text"} style={{width: "500px", padding: "10px"}} placeholder={'Enter table or view name'}
				       value={tableOrView}
				       onChange={(val) => setTableOrView(val.target.value)}></input>
				<br/>
				<Button
					onClick={checkAccess}
					disabled={working}
				>
					{working ? 'Checking Access...' : 'Check Access'}
				</Button>
				<hr/>
				{rows && rows.map((r, i)=> {
					return <div key={r.id} style={{textAlign: "left"}}>{i+1}) {JSON.stringify(r)}<hr/></div>
				})}
				{rows && rows.length > 99 && <div>*Rows are limited to 100</div>}
			</div>
		</header>
	)
}



export default CheckAccess