import React, {useEffect, useState, useRef}                                                     from 'react'
import {Box, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch,} from '@mui/material'
import {ChevronLeft, ChevronRight, Close, Fullscreen, FullscreenExit, PlayArrow}        from '@mui/icons-material'
import {fetchSignedMediaUrl}                                                            from "../../util/admin"

const AssetPreviewDialog = ({open, imageUrl, audioUrl, autoPlay, onToggleAutoPlay, onClose, onPrevious, onNext}) => {
	const [fullscreen, setFullscreen] = useState(false)
	const [signedImageUrl, setSignedImageUrl] = useState(null)
	const [signedAudioUrl, setSignedAudioUrl] = useState(null)
	const audioRef = useRef(null)
	const imgRef = useRef(null)

	useEffect(() => {
		// allow navigation of assets with keyboard
		const handleKeyDown = (event) => {
			if(imageUrl) {
				if (event.key === 'ArrowLeft') {
					onPrevious()
				}
				else if (event.key === 'ArrowRight') {
					onNext()
				}
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [onPrevious, onNext])

	useEffect(() => {
		fetchSignedMediaUrl(imageUrl)
			.then(url => {
				setSignedImageUrl(url)
			})
	}, [imageUrl])

	useEffect(() => {
		fetchSignedMediaUrl(audioUrl)
			.then(url => {
				setSignedAudioUrl(url)
			})
	}, [audioUrl])

	const toggleFullscreen = () => {
		if (imgRef.current) {
			if (imgRef.current.requestFullscreen) {
				imgRef.current.requestFullscreen()
			} else if (imgRef.current.mozRequestFullScreen) {
				// Firefox
				imgRef.current.mozRequestFullScreen()
			} else if (imgRef.current.webkitRequestFullscreen) {
				// Chrome, Safari, and Opera
				imgRef.current.webkitRequestFullscreen()
			} else if (imgRef.current.msRequestFullscreen) {
				// IE/Edge
				imgRef.current.msRequestFullscreen()
			}
		}
	}

	const playAudio = () => {
		if (audioRef.current) {
			audioRef.current.play()
		}
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="xl"
			fullWidth
			fullScreen={fullscreen}
		>
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<div>
						Preview
					</div>
					<div>
						<FormControlLabel
							control={<Switch color="secondary" checked={autoPlay}
							                 onChange={(e) => onToggleAutoPlay(e.target.checked)}/>}
							label="Auto Play"
							sx={{whiteSpace: 'nowrap', mr: 2}}
						/>
						<IconButton onClick={onPrevious}>
							<ChevronLeft/>
						</IconButton>
						<IconButton onClick={onNext}>
							<ChevronRight/>
						</IconButton>
						<IconButton onClick={playAudio}>
							<PlayArrow/>
						</IconButton>
						<IconButton onClick={toggleFullscreen}>
							{fullscreen ? <FullscreenExit/> : <Fullscreen/>}
						</IconButton>
						<IconButton onClick={onClose}>
							<Close color="error"/>
						</IconButton>
					</div>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box display="flex" justifyContent="center">
					<Box
						ref={imgRef}
						component="img"
						src={signedImageUrl}
						alt="Slide"
						sx={{
							maxWidth: '100%',
							width: '100%',
							height: 'auto',
						}}
					/>
				</Box>
				<audio ref={audioRef} src={signedAudioUrl} autoPlay={autoPlay}/>
			</DialogContent>
		</Dialog>
	)
}

export default AssetPreviewDialog
