import React         from 'react'
import EditableLabel from "./EditableLabel"

const EditableTree = ({ root, getChildren, getLabel }) => {
	const renderTree = (node, index, depth) => {
		const label = getLabel(node)
		const children = getChildren(node)
		return (
			<li key={label}>
				<EditableLabel
					label={label}
					style={{marginLeft: (depth * 25) + 'px'}}
					onSave={(newLabel) => console.log(`Save new label ${newLabel} for node`, node)}
					onCancel={() => console.log('Cancel editing', node)}
				/>
				{children && children.length > 0 && (
					<ul>{children.map((child, ci) => renderTree(child, ci, depth+1))}</ul>
				)}
			</li>
		)
	}
	return <ul>{root.map((o,i) => renderTree(o, i, 0))}</ul>
}

export default EditableTree
