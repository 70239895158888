import React from 'react'
import { Circle } from '@mui/icons-material'
import { keyframes } from '@emotion/react'
import { Box } from '@mui/material'

// Keyframes for pulsing opacity animation
const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`

const fontSizes = {
	inherit: "inherit",
	large: "large",
	medium: "medium",
	small: "small",
}

const PulsingCircle = ({fontSize, ...props}) => {

	return (
		<Box
			alignItems={"center"}
			justifyContent={"center"}
			sx={{
				...props.sx,
				display: 'inline',
				animation: `${pulseAnimation} .5s infinite ease-in-out`,
			}}
		>
			{
				fontSizes[fontSize]
					? <Circle fontSize={fontSize} />
					: <Circle sx={{fontSize: fontSize}} />
			}
		</Box>
	)
}

export default PulsingCircle
