
const STATUS_DRAFT = {label: "Draft", color: "secondary"}
const STATUS_PUBLISHED = {label: "Published", color: "success"}
const STATUS_ARCHIVED = {label: "Archived", color: "warning"}

const STATUSES = [
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_ARCHIVED
]

const getStatusByPackage = (packageData) => {
	return packageData.archived_at
		? STATUS_ARCHIVED
		: packageData.published_at
			? STATUS_PUBLISHED
			: STATUS_DRAFT
}

export {getStatusByPackage, STATUS_DRAFT, STATUS_PUBLISHED, STATUS_ARCHIVED, STATUSES}