import {Box, List, ListItemButton, Typography, useTheme} from "@mui/material"
import PackageListItem                                   from "./PackageListItem"
import LeftRight                                         from "../LeftRight"
import {ArrowForward}                                    from "@mui/icons-material"
import React                                             from "react"

const SelectPackageList = ({currentPackage, expiredPackages, relatedPackages, onSelectPackage, onBrowsePackages}) => {
	const theme = useTheme()

	return <List sx={{border: `0px solid ${theme.palette.divider}`, p: 0}}>
		{
			currentPackage && <PackageListItem
				packageData={currentPackage}
				onClick={onSelectPackage}
			/>
		}
		{
			expiredPackages &&
			expiredPackages.length > 0 &&
			expiredPackages.map(xp => <PackageListItem
				key={`xp-${xp.id}`}
				packageData={xp}
				onClick={onSelectPackage}/>)
		}
		{
			relatedPackages &&
			relatedPackages.length > 0 &&
			relatedPackages.map(rp => <PackageListItem
				key={`related-${rp.id}`}
				packageData={rp}
				onClick={onSelectPackage}/>)

		}
		{
			onBrowsePackages &&
			<ListItemButton onClick={onBrowsePackages}>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					flex={1}
				>
					<LeftRight
						left={
							<Box>
								<Typography fontWeight={500}>Shop All PrepKits</Typography>
							</Box>
						}
						right={<ArrowForward/>}
					/>
				</Box>
			</ListItemButton>
		}
	</List>
}

export default SelectPackageList