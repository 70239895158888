import React      from 'react'
import {useTheme} from "@mui/material"
import SvgIcon    from "@mui/material/SvgIcon"

function BirdsyLogo({width, height, color}) {
	const theme = useTheme()
	const originalWidth = 1173.6
	const originalHeight = 829.76
	const ratio = originalWidth / originalHeight

	const dim = width
		? {width: width, height: width / ratio }
		: height
			? {width: width, height: width / ratio }
			: {width: "100%", height: "100%" }

	return (
		<SvgIcon style={dim}>
			<svg
				viewBox={"0 0 " + originalWidth + " " + originalHeight}
				width={dim.width}
				height={dim.height}
			>
				<path style={{ fill: color || theme.palette.logo.primary }} d="m729.21,206.28c9.8,57.79-27.49,110.93-40.52,142.47,36.84,41.27,162.63,54.16,239.2,40.52,87.66-147.16,249.85-135.28,163.39-320.24-118.75,28.28-268.54,65.74-268.54,65.74,0,0-45.41-60.63-119.97-66.16-63.54-4.72-116.07,43.62-116.07,43.62,0,0,143.05,92.88,142.51,94.05Zm-55.33,160.52c.28-.3.57-.6.85-.9-1.03-.12-1.28.21-.85.9Zm234.4,73.02s-63.75,9.1-133.07-16.49c-42.08-16.33-97.63-50.52-101.33-56.54-66.72,70.59-185.43,116.96-271.44,134.88-157.62,32.85-358.14-14.2-358.14-14.2,0,0,18.48,22.86,58.15,44.68,43.18,23.75,107.85,46.63,107.85,46.63,0,0,30.49,59.68,33.98,73.2-123.86,106.81-189.43,165.74-189.53,166,34.42,3.92,103.26,11.76,103.26,11.76l150.1-64.07s243.68,175.68,490.38-91.48c71.88,8.28,215.67,24.83,215.67,24.83l113.7-52.8-209.12-104.05-10.46-102.38Zm34,77.37l146.28,72.14,85.05-46.27-231.13-55.28-.19,29.4Zm-752.9,138.73L0,717.05l65.21,43.43,124.17-104.57ZM875.86,106.46L906.68,0s-39.09,3.48-59.16,34.19c-19.45,29.75-12.19,72.5-12.19,72.5l40.52-.23Z"/>
			</svg>
		</SvgIcon>
	)
}

export default BirdsyLogo
