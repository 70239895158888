import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Container,
	Divider,
	Radio,
	TextField,
	Typography,
	useTheme
}                                            from "@mui/material"
import React, {useEffect, useMemo, useState} from "react"
import getSupabase                           from "../../util/supabase-utils"
import {useUserStore}                        from "../../state"
import {doGet, doPost}                       from "../../util/do-fetch"
import EditableLabel                         from "../EditableLabel"
import LeftRight                             from "../LeftRight"
import useMediaQuery                         from "@mui/material/useMediaQuery"

const UpdateUserProfile = ({switchView}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

	const {user} = useUserStore()
	const [userProfile, setUserProfile] = useState({})
	const [aiPersonalities, setAiPersonalities] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)

	const aiPersonalityId = useMemo(()=>{
		return userProfile && userProfile.ai_personality_id
	}, [userProfile])


	useEffect(() => {
		if (user) {
			const fetchUserProfile = async () => {
				try {
					const response = await doGet(`/mini/user/profile`)
					const profile = await response.json()
					if (profile) {
						setUserProfile(profile)
					}
					else {
						throw "No user profile exists."
					}
				}
				catch (err) {
					setUserProfile({})
				}
				finally {
					setLoading(false)
				}
			}

			const fetchAiPersonalities = async () => {
				try {
					const response = await doGet(`/mini/ais`)
					const data = await response.json()
					setAiPersonalities(data)
				}
				catch (err) {
					console.error(err)
				}
			}

			fetchUserProfile()
			fetchAiPersonalities()
		}
		else {
			setAiPersonalities(null)
			setUserProfile({})
		}
	}, [user])

	const handleSave = async () => {

		if(!userProfile.user_id) {
			setError("Cannot save empty user profile.")
		}
		else {
			setLoading(true)
			try {
				const options = {
					body: JSON.stringify(userProfile)
				}
				const response = await doPost(`/mini/user/profile`, options)

				if(response.ok) {
					setSuccess("Profile saved succesfully!")
				}
				else {
					setError("Error saving profile.")
				}

			}
			catch (err) {
				setUserProfile({})
			}
			finally {
				setLoading(false)
			}
		}
	}

	const handleCardClick = (id) => {
		setUserProfile(prev => ({ ...prev, ai_personality_id: id }))
	}


	if (loading) {
		return (
			<Container>
				<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
					<CircularProgress/>
				</Box>
			</Container>
		)
	}

	return (
		<Container>
			<Box sx={{
				pt: isSmallScreen ? 2 : 0
			}}>
				<LeftRight
					left={
						<Typography variant={isSmallScreen ? "h5" : "h4"}>
							Profile
						</Typography>
					}
					right={
						<Button size={isSmallScreen ? "sm" : "large"}
						        variant={"contained"}
						        onClick={handleSave}
						>
							Save
						</Button>
					}
				/>

				<Divider sx={{my: 2}}/>
			</Box>
			{
				error &&
				<Alert sx={{mb: 2}} severity={"error"} onClose={()=>setError(null)}>
					{error}
				</Alert>
			}
			{
				success &&
				<Alert sx={{mb: 2}} severity={"success"} onClose={()=>setSuccess(null)}>
					{success}
				</Alert>
			}
			<Box>
				<Typography variant={"h6"}>What's your name?</Typography>
				<Box
					display={"flex"}
					flexDirection={"row"}
					justifyContent={"space-evenly"}
					alignItems={"center"}
				>
					<TextField
						fullWidth
						label="First Name"
						variant="outlined"
						value={userProfile.first_name}
						onChange={(e) => setUserProfile(prev => ({ ...prev, first_name: e.target.value }))}
						margin="normal"
						sx={{mr: 0.5}}
					/>
					<TextField
						fullWidth
						label="Last Name"
						variant="outlined"
						value={userProfile.last_name}
						onChange={(e) => setUserProfile(prev => ({ ...prev, last_name: e.target.value }))}
						margin="normal"
						sx={{ml: 0.5}}
					/>
				</Box>


				{/* AI Personalities Selection */}
				<Typography variant={"h6"} sx={{mt: 4}}>
					What is your learning style?
				</Typography>
				{aiPersonalities &&
				aiPersonalities.map((personality) => (
					<Box
						key={`btn-${personality.id}`}
						onClick={() => handleCardClick(personality.id)}
						sx={{
							my: 1,
							cursor: 'pointer',
						}}
					>
						<Box display={"flex"}
						     flexDirection={"row"}
						     justifyContent={"flex-start"}
						     alignItems={"flex-start"}
						>
							<Radio
								value={personality.id}
								checked={aiPersonalityId === personality.id}
								sx={{
									'& .MuiSvgIcon-root': {
										fontSize: '22',
									},
								}}
							/>
							<Box>

								<Typography variant="h6" sx={{mt: 0.5}}>
									{personality.name}
								</Typography>
								<Typography variant="body">
									{personality.description}
								</Typography>
							</Box>

						</Box>
					</Box>
				))}
			</Box>
		</Container>
	)
}

export default UpdateUserProfile