import React, {useEffect, useRef, useState} from 'react'
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Container,
	Drawer, Fab,
	IconButton,
	ListItemButton,
	Step,
	StepIcon,
	StepLabel,
	Stepper, Typography,
	useTheme,
} from '@mui/material'
import Grid                         from '@mui/material/Unstable_Grid2' // Grid version 2
import {logger}                     from "../../util/log-utils"
import {
	useNavigate,
	useOutletContext,
	useParams
}                                   from "react-router-dom"
import {
	doGet,
	doPost,
	doPut
}                                   from "../../util/do-fetch"
import {
	Add,
	AutoMode, CheckCircle,
	ChevronLeft,
	ChevronRight, Circle, CircleOutlined, Close, Edit, Error, Flag, FlagCircle, FlagOutlined,
	Menu, MenuOpen,
	Pause,
	PlayArrow,
	Replay, SportsScore, SportsScoreOutlined, Undo
} from "@mui/icons-material"
import moment                       from "moment"
import MediaJobProcessing           from "./MediaJobProcessing"
import MediaJobDialog    from "./MediaJobDialog"
import {fmtCal, fmtDttm} from "../../util/prepkit-utils"
import {
	ASSET_REVIEW,
	COMPLETED,
	CONTENT_REVIEW,
	RENDERING_ASSETS,
	RENDERING_CLIPS,
	MediaJobStatus,
}                        from "../../util/media-job-status"
import {Alert, AlertTitle}          from "@mui/material"
import ContentReview                from "./ContentReview"
import {getSupabase}                from "../../util/supabase-utils"

const log = logger("ProductMediaJobs", 1)

function ProductMediaJobs() {
	const navigate = useNavigate()
	const {id} = useParams()
	const {product} = useOutletContext()
	const [mediaJobs, setMediaJobs] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showAddDialog, setShowAddDialog] = useState(false)

	useEffect(() => {
		if(product) {
			fetchMediaJobs(product.id)
		}
		else {
			setMediaJobs(null)
		}
	}, [product])


	const fetchMediaJobs = async (productId) => {
		setLoading(true)
		try {
			const response = await doGet(`/admin/products/${productId}/media-jobs`)
			setMediaJobs(await response.json())
		}
		catch (err) {
			log.error(err)
		}
		finally {
			setLoading(false)
		}
	}

	const handleClickMediaJob = (j) => {

		navigate(id ? `../media/${j.id}` : `./${j.id}`)
	}
	const handleAfterSaveMediaJob = async (mediaJob) => {

		setMediaJobs(prev => {
			const updated = [...prev]
			updated.push(mediaJob)
			return updated
		})
		setShowAddDialog(false)
	}

	return <>
		<Grid xs={12} container maxWidth="sm" sx={{mx: 'auto'}}>
			<Grid xs={12}>
				<Box>
					<Box display="flex"
					     justifyContent="space-between"
					     alignItems="center"
					     sx={{p: 2, width: '100%'}}
					>
						<h2>Media Jobs</h2>
						<div>
							<Button variant="outlined" onClick={() => setShowAddDialog(true)}>
								<Add color="primary"/> Add
							</Button>
						</div>
					</Box>
					<Box sx={{border: '1px solid #ddd', borderBottom: 'none'}}>
					{
						mediaJobs && mediaJobs.map(j => {
							return <ProductMediaJob
								key={j.id}
								product={product}
								mediaJob={j}
								onClick={handleClickMediaJob}
							/>
						})
					}
					</Box>
				</Box>
			</Grid>

			<MediaJobDialog open={showAddDialog}
			                product={product}
			                onCancel={() => setShowAddDialog(false)}
			                onAfterSave={handleAfterSaveMediaJob}/>

		</Grid>
	</>
}


function ProductMediaJob({product, mediaJob, selected, onClick}) {

	const theme = useTheme()
	// pending, review, processing, rendering, completed // failed?

	if(!mediaJob) return null

	return mediaJob && <ListItemButton
		onClick={() => {
			onClick(mediaJob)
		}}
		selected={selected}
		sx={{
			p: 3,
			borderBottom: '1px solid ' + theme.palette.divider,
		}}
	>
		<Box display="flex"
		     flex={1}
		     flexDirection="column"
		     justifyContent="flex-start"
		     alignItems="flex-start"
		>
			<Box display="flex"
			     flex={1}
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			     sx={{ width: '100%'}}
			>
					<Box sx={{mr: 6}} >
						<b>Model {mediaJob.tts_model} / {mediaJob.voice_id}</b>
					</Box>
					<small style={{color: theme.palette.info.main}}>
						{fmtCal(mediaJob.created_at)}
					</small>
			</Box>

			<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
				<small style={{color:mediaJob.category.color }}>
					{mediaJob.category.name}
				</small>
			</Box>

			<Box display="flex"
			     flex={1}
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			     sx={{ width: '100%'}}
			>
				<small style={{color: theme.palette.text.secondary, textTransform: 'capitalize'}}>
					{mediaJob.status.split('-').join(' ')}
				</small>
			</Box>
		</Box>

	</ListItemButton>
}

export default ProductMediaJobs
