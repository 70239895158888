import { useState, useEffect, useRef } from 'react';

export default function useNavBarHeight() {
	const navBarRef = useRef(null);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		// Function to update the height
		const updateHeight = () => {
			if (navBarRef.current) {
				setHeight(navBarRef.current.offsetHeight);
			}
		};

		// Call the function initially
		updateHeight();

		// Add the event listener
		window.addEventListener('resize', updateHeight);

		// Cleanup the event listener on unmount
		return () => window.removeEventListener('resize', updateHeight);

	}, []);

	return { navBarRef, height };
}