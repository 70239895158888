import React, { useState }                                                                           from 'react'
import {
	Card,
	CardContent,
	CardActions,
	Typography,
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Chip,
	TableCell
}                    from '@mui/material'
import MoreVertIcon  from '@mui/icons-material/MoreVert'
import LeftRight     from "../LeftRight"
import {useNavigate} from "react-router-dom"
import CategoryLabel
                     from "./CategoryLabel"
import HoverTableRow
                     from "../HoverTableRow"
import {fmt2Dec}     from "../../util/prepkit-utils"

const PackageTableRow = ({ packageData, publishStatus, onDuplicate, onUnpublish, onPublish, onUnarchive, onArchive }) => {
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null)

	const handleMenuOpen = (event) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<HoverTableRow
			key={packageData.id}
			onClick={() => navigate(`/admin/packages/${packageData.id}/edit`)}
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer'
			}}
		>
			<TableCell>
				<Typography style={{padding: 0, margin: 0}}>
					{packageData.name}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{
						packageData.options &&
						packageData.options.map((o,i)=>(
								<span
									key={`opt-${o.id}`}
								    style={{mr: 1, display: "inline-block"}}
								>
									{o.name} ${fmt2Dec(o.price_in_cents/100)}&nbsp;
									{
										i < packageData.options.length -1 && " · "
									}&nbsp;
								</span>
							))
					}
				</Typography>
			</TableCell>

			<TableCell align={"center"}>
				{publishStatus && <Chip size={"small"} variant={"outlined"} label={publishStatus.label} color={publishStatus.color} />}
			</TableCell>

			<TableCell>
				<CategoryLabel sx={{ml:1}} variant={"body2"} category={packageData.category}/>
			</TableCell>

			<TableCell>
				<Box>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleMenuOpen}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						onClick={(event) => event.stopPropagation()}
					>
						<MenuItem onClick={()=> {
							handleMenuClose()
							onDuplicate(packageData)
						}}>
							Duplicate
						</MenuItem>

						{
							packageData.published_at ? (
								<MenuItem onClick={() => { onUnpublish(packageData); handleMenuClose() }}>
									Unpublish
								</MenuItem>
							) : (
								<MenuItem onClick={() => { onPublish(packageData); handleMenuClose() }}>
									Publish
								</MenuItem>
							)
						}
						{
							packageData.archived_at ? (
								<MenuItem onClick={() => { onUnarchive(packageData); handleMenuClose() }}>
									Unarchive
								</MenuItem>
							) : (
								<MenuItem onClick={() => { onArchive(packageData); handleMenuClose() }}>
									Archive
								</MenuItem>
							)
						}
					</Menu>
				</Box>
			</TableCell>
		</HoverTableRow>
	)
}

export default PackageTableRow
