import React, {useEffect, useState}  from 'react'
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider, FormControl,
	FormControlLabel,
	FormGroup, InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme
}                                                     from '@mui/material'
import Grid                                           from "@mui/material/Unstable_Grid2"
import {fetchCategories, fetchTTSModels, fetchVoices} from "../../util/admin"
import {fmt2Dec}                                      from "../../util/prepkit-utils"
import {countTokens}                                  from "../../util/token-utils"
import {doPost}                                       from "../../util/do-fetch"
import {Alert, AlertTitle}                            from "@mui/material"
import {Circle, CircleOutlined}                       from "@mui/icons-material"
import SelectCategory                                 from "./SelectCategory"
import LabeledSelect                                  from "../LabeledSelect"

const MediaJobDialog = ({product, open, onAfterSave, onCancel}) => {

	const WORDS_PER_SECOND = 160 / 60
	const SECONDS_BETWEEN_SLIDES = 1

	const theme = useTheme()
	const [saving, setSaving] = useState(false)
	const [category, setCategory] = useState("")
	const [ttsModels, setTTSModels] = useState([])
	const [ttsModel, setTTSModel] = useState("")
	const [voices, setVoices] = useState([])
	const [voice, setVoice] = useState("")
	const [slides, setSlides] = useState([])
	const [wordCount, setWordCount] = useState(0)
	const [charCount, setCharCount] = useState(0)
	const [tokenCount, setTokenCount] = useState(0)
	const [ttsCost, setTTSCost] = useState(0)
	const [duration, setDuration] = useState(0)
	const [skipContentReview, setSkipContentReview] = useState(false);
	const [skipAssetReview, setskipAssetReview] = useState(false);
	const [errors, setErrors] = useState()

	useEffect(() => {
		fetchModels()
	}, [])

	useEffect(() => {
		console.log("useEffect[selectedQuestions, selectedTopics]")
		// count the words in all the topic names, questions, answers, and explanations
		// to estimate the duration of the video
		const slides = []
		let totalWordCount = 0
		let totalCharCount = 0
		let totalTokenCount = 0
		let totalDuration = 0
		const slide = (content) => {
			const wordCount = content.split(" ").length
			const duration = wordCount / WORDS_PER_SECOND
			totalCharCount += content.length
			totalWordCount += wordCount
			totalTokenCount += countTokens(content)
			totalDuration += (duration + SECONDS_BETWEEN_SLIDES)
			slides.push({content, wordCount, duration})
		}

		/*
		todo: replace this with calculating all topics and questions, since we don't select them anymore
		Object.values(selectedTopics).forEach(t => {
			t && slide(t.name)
		})

		Object.values(selectedQuestions).forEach(q => {
			slide(q.ques//tion)
			q.options.forEach(o => slide(o.label))
			slide(q.options[q.answer].label)
			slide(q.explanation)
		})
		*/

		setSlides(slides)
		setWordCount(totalWordCount)
		setCharCount(totalCharCount)
		setTokenCount(totalTokenCount)
		setDuration(totalDuration)

		if(ttsModel) {
			const model = ttsModels.find(m=>m.name===ttsModel)
			const cost = model.output_cost * totalCharCount
			console.log({amt: model.output_cost, cost, totalCharCount})
			setTTSCost(cost)
		}

	}, [ttsModel])


	const fetchModels = async () => {
		const tts = await fetchTTSModels()
		const voices = await fetchVoices()
		setTTSModels(tts)
		setVoices(voices)
	}

	const handleSave = async () => {
		setErrors(null)
		setSaving(true)
		try {
			const mediaJob = {
				categoryId: category,
				ttsModel,
				voiceId: voice,
				skipContentReview,
				skipAssetReview,
			}

			const options = {
				body: JSON.stringify(mediaJob)
			}

			const response = await doPost(`/admin/products/${product.id}/media-jobs`, options)

			if(response.status === 200) {
				const newMediaJob = await response.json()
				await onAfterSave(newMediaJob)
			}
			else if (response.status === 400) {
				const errs = await response.json()
				setErrors(errs)
			}
		}
		catch(exc) {
			setErrors([exc])
		}
		finally {
			setSaving(false)
		}
	}

	const th = {
		textAlign: 'center',
		fontWeight: 'bold'
	}
	const td = {
		textAlign: 'center'
	}


	return <Dialog
		open={open}
		maxWidth="lg"
	>
		<DialogTitle>
			Create Media Job
			<small style={{marginLeft: "15px", fontWeight: "normal", color: theme.palette.text.disabled}}>
				{product && product.name}
			</small>
		</DialogTitle>
		<Divider/>
		<DialogContent>
			{
				errors &&
				<Alert severity="error" onClose={()=>setErrors(null)} sx={{mb: 2}}>
					<AlertTitle>
						Error Saving Media Job
					</AlertTitle>
					{ errors.map(e=><div>{e}</div>) }
				</Alert>
			}
			<Grid container>
				<Grid xs={12}>
					<SelectCategory category={category} onChangeCategory={(c)=>setCategory(c.id)}/>

					<LabeledSelect
						sx={{mt: 2}}
						value={ttsModel}
						label={ttsModels ? `Select Text Model (TTS)` : `Loading Text Models (TTS)...`}
						onChange={e => setTTSModel(e.target.value)}
						displayEmpty
						fullWidth
					>
						<MenuItem value={''}>
							<Typography component="span" style={{opacity: 0.5}}>
								Select Text Model (TTS)
							</Typography>
						</MenuItem>
						{
							ttsModels.map((m,i) => {
								return <MenuItem key={i} value={m.name}>
									<Typography component="span">
										{m.vendor} : <b>{m.name}</b>
									</Typography>
									<Typography component="span" sx={{fontSize: 'small', ml: 2}}>
										{' '}
										Input: ${Number(m.input_cost * 1000)
										.toFixed(4)},
										Output: ${Number(m.output_cost * 1000)
										.toFixed(4)} per 1000 {m.unit}s
									</Typography>
								</MenuItem>
							})
						}
					</LabeledSelect>

					<Select
						value={voice}
						onChange={e => setVoice(e.target.value)}
						displayEmpty
						fullWidth
						placeholder={voices ? `Select Voice` : `Loading Voices...`}
						sx={{mt: 2}}
					>
						<MenuItem value={''}>
							<Typography component="span" style={{opacity: 0.5}}>
								Select Voice
							</Typography>
						</MenuItem>
						{
							voices.map((m,i) => {
								return <MenuItem key={i} value={m.voice_id}>
									<Typography component="span">
										{m.vendor} : {m.voice_id}
									</Typography>
								</MenuItem>
							})
						}
					</Select>
					<FormGroup sx={{ p: 1.5 }}>
						<FormControlLabel
							control={<Checkbox checked={skipContentReview} onChange={(e) => setSkipContentReview(e.target.checked)} />}
							label="Skip TTS Content Review"
						/>
						<FormControlLabel
							control={<Checkbox checked={skipAssetReview} onChange={(e) => setskipAssetReview(e.target.checked)} />}
							label="Skip Asset Review"
						/>
					</FormGroup>
				</Grid>
			</Grid>
		</DialogContent>
		<Divider/>
		<DialogActions sx={{p: 2, pt: 2}}>
			<Button variant="outlined" onClick={() => onCancel && onCancel()} disabled={saving}>
				Cancel
			</Button>
			<Button variant="contained" onClick={handleSave} disabled={saving}>
				{saving ? "Saving" : "Save"}
			</Button>
		</DialogActions>
	</Dialog>
}

export default MediaJobDialog
