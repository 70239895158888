import React, {useState, useRef, useEffect} from 'react'
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle, FormControlLabel,
	IconButton, Switch,
}                                                     from '@mui/material'
import {ChevronLeft, ChevronRight, Close, Fullscreen} from '@mui/icons-material';
import {fetchSignedMediaUrl}                          from "../../util/admin"

const VideoPreviewDialog = ({ open, videoUrl, autoPlay, onToggleAutoPlay, onClose, onPrevious, onNext }) => {

	const [signedVideoUrl, setSignedVideoUrl] = useState(null)
	const videoRef = useRef(null)


	useEffect(() => {
		// allow navigation of assets with keyboard
		const handleKeyDown = (event) => {
			if(videoUrl) {
				if (event.key === 'ArrowLeft') {
					onPrevious()
				}
				else if (event.key === 'ArrowRight') {
					onNext()
				}
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [onPrevious, onNext])

	useEffect(() => {
		fetchSignedMediaUrl(videoUrl)
			.then(url => {
				setSignedVideoUrl(url)
			})
	}, [videoUrl])

	const handleFullscreen = () => {
		if (videoRef.current) {
			if (videoRef.current.requestFullscreen) {
				videoRef.current.requestFullscreen()
			} else if (videoRef.current.mozRequestFullScreen) {
				// Firefox
				videoRef.current.mozRequestFullScreen()
			} else if (videoRef.current.webkitRequestFullscreen) {
				// Chrome, Safari and Opera
				videoRef.current.webkitRequestFullscreen()
			} else if (videoRef.current.msRequestFullscreen) {
				// IE/Edge
				videoRef.current.msRequestFullscreen()
			}
		}
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="xl"
			fullWidth
		>
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<div>Preview</div>
					<div>
						<FormControlLabel
							control={<Switch color="secondary" checked={autoPlay}
							                 onChange={(e) => onToggleAutoPlay(e.target.checked)}/>}
							label="Auto Play"
							sx={{whiteSpace: 'nowrap', mr: 2}}
						/>
						<IconButton onClick={onPrevious}>
							<ChevronLeft />
						</IconButton>
						<IconButton onClick={onNext}>
							<ChevronRight />
						</IconButton>
						<IconButton onClick={handleFullscreen}>
							<Fullscreen />
						</IconButton>
						<IconButton onClick={onClose}>
							<Close color="error" />
						</IconButton>
					</div>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: 'black' }}>
				<Box display="flex" justifyContent="center">
					<Box
						key={signedVideoUrl}
						sx={{
							maxWidth: '100%',
							width: '100%',
							height: 'auto',
							position: 'relative',
							backgroundColor: 'black',
							textAlign: 'center',
						}}
					>
						<video
							ref={videoRef}
							controls
							autoPlay={autoPlay}
							style={{ width: '96%'}}
						>
							<source src={signedVideoUrl} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default VideoPreviewDialog;
