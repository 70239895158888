import React, {useEffect, useState}     from 'react'
import {Box, List, Tooltip, useTheme}   from "@mui/material"
import {Circle, CircleOutlined, Square} from "@mui/icons-material"
import CircularProgressWithLabel        from "../CircularProgressWithLabel"

const ScoreAndProgress = ({percentComplete, percentCorrect}) => {

	const theme = useTheme()
	const [color, setColor] = useState()

	useEffect(()=>{

		const rgba = getPercentageColor(percentCorrect) + percentageToHex(percentComplete)
		console.log(rgba)
		setColor(rgba)

	}, [percentComplete, percentCorrect])

	const getPercentageColor = (percentageCorrect) => {
		if (percentageCorrect >= 100) return theme.palette.score.success
		if (percentageCorrect <= 0) return theme.palette.score.warning

		const successColor = theme.palette.score.success;
		const midColor = theme.palette.score.warning

		// Interpolate between midColor and successColor for 0% to 100%
		return interpolateColor(midColor, successColor, percentageCorrect / 100);
	}

	const interpolateColor = (color1, color2, factor) => {
		const result = color1.slice(1).match(/.{2}/g)
			.map((c, i) => Math.round(parseInt(c, 16) * (1 - factor) + parseInt(color2.slice(1).match(/.{2}/g)[i], 16) * factor))
			.map(c => c.toString(16).padStart(2, '0'))
			.join('');
		return `#${result}`;
	}

	const percentageToHex = (percentage) => {
		if (percentage < 0) percentage = 0;
		if (percentage > 100) percentage = 100;

		const alpha = Math.round((percentage / 100) * 255);
		return alpha.toString(16).padStart(2, '0').toUpperCase();
	}

	//return <CircularProgressWithLabel size={18} value={percentComplete} label={percentCorrect} bgColor={color}
	// thickness={4}  />

	const tt = percentComplete
		? <span>
			<b>Average Score: </b>{Math.round(percentCorrect)}%<br/>
			<b>Percent Complete:</b>{Math.round(percentComplete)}%
		  </span>
		: <span>Not Started</span>

	return <Tooltip title={tt} placement="right"><Box sx={{
			backgroundColor: color || 'transparent',
			border: '1px solid ' + theme.palette.divider,
			mr: 1,
			width: '14px',
			height: '14px',
		}}/></Tooltip>
}

export default ScoreAndProgress
