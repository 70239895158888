import React, {useEffect, useState} from 'react'
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Divider, IconButton,
	Link,
	Typography,
	useTheme,
}                    from '@mui/material'
import Grid          from '@mui/material/Unstable_Grid2' // Grid version 2
import moment        from 'moment'
import {Alert}       from "@mui/material"
import {useNavigate} from "react-router-dom"
import {
	doGet,
	doPost
}                    from "../../util/do-fetch"
import {logger}      from "../../util/log-utils"
import TopicJobCard  from "./TopicJobCard"
import {ArrowBack}   from "@mui/icons-material"

const log = logger("TopicJobs", 1)

function TopicJobs() {
	const navigate = useNavigate()
	const [jobs, setJobs] = useState([])
	const [processing, setProcessing] = useState([])
	const [failed, setFailed] = useState([])
	const [other, setOther] = useState([])
	const [products, setProducts] = useState(null)
	const [loadedAt, setLoadedAt] = useState(null)
	const [productNames, setProductNames] = useState({})

	useEffect(() => {
		fetchJobs()
		fetchProducts()
		const intervalId = setInterval(() => {
			fetchJobs()
		}, 10000) // 10 seconds

		return () => clearInterval(intervalId)
	}, [])

	useEffect(() => {
		if(!products) {
			setProductNames({})
		}
		else {
			const names = {}
			products.forEach(p => names[p.id] = p.name)
			setProductNames(names)
		}
	}, [products])

	useEffect(() => {
		setLoadedAt(moment())
		if(!jobs){
			setProcessing([])
			setFailed([])
			setOther([])
		}
		else {
			setProcessing(jobs.filter(j => j.status === 'processing'))
			setFailed(jobs.filter(j => j.status === 'failed'))
			setOther(jobs.filter(j => j.status !== 'processing' && j.status !== 'failed'))
		}
	}, [jobs])

	const fetchJobs = async () => {
		try {
			const response = await doGet(`/admin/topic-jobs`)
			const data = await response.json()
			setJobs(data || [])
		}
		catch (err) {
			log.error(err)
		}
	}

	const fetchProducts = async () => {
		try {
			const response = await doGet(`/admin/products-generating`)
			const data = await response.json()
			setProducts(data)
		}
		catch (err) {
			log.error(err)
		}
	}

	const getProductName = (productId) => {
		return productNames && productNames[productId]
	}

	const getTopicJobCards = (jobsAry) => {
		return <Box>
			{ jobsAry.map((j) => <TopicJobCard topicJob={j} productName={getProductName(j.topic.product_id)} onRetry={handleRetry} key={j.topic_id}  />) }
		</Box>
	}

	const handleRetry = async (job) => {
		try {
			log.debug("handleRetry")
			const response = await doPost(`/admin/topic-job/${job.topic_id}/delete`)
			const data = await response.json()
			log.debug({data})
			setJobs(data)
		}
		catch (err) {
			log.error(err)
		}
	}

	const fmt = (dt) => {
		return dt && moment(dt).format('M/d h:m')
	}

	const clr = (status) => {
		switch (status) {
			case ('processing'):
				return 'primary'
				break
			case('failed'):
				return 'error'
				break
			case('completed'):
				return 'success'
				break
			default:
				return 'warning'
		}
	}

	const productName = (job) => {
		return productNames[job.topic.product_id]
	}

	return (
		<Grid xs={12} container sx={{p: 2}}>
			<Grid xs={12} container>
				<Grid xs={12}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<IconButton onClick={() => navigate(`/admin`)}>
								<ArrowBack/>
							</IconButton>
							<Typography variant={"h5"}>
								Topic Job Queue
							</Typography>
						</Box>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<span>Updated At {loadedAt && loadedAt.format('h:mm:ss a')}</span>
							<CircularProgress size={16} sx={{ml: 1}}/>
						</Box>
					</Box>
					<Divider sx={{my: 2}}/>
				</Grid>
				<Grid xs={4} sx={{pr: 1}}>
					<div >
						<h3>Projects with Generating Enabled</h3>
						{products && products.map(p => {
							return <Card sx={{mb: 1, mr: 1}}
							             onClick={() => navigate(`/admin/products/${p.id}`)}>
								<CardContent style={{cursor: "pointer"}}>
									{p.name}
								</CardContent>
							</Card>
						})}
					</div>
				</Grid>
				<Grid xs={4} sx={{px: 1}}>
					<Box sx={{}}>
						<h3>Processing</h3>
						{getTopicJobCards(processing)}
					</Box>

				</Grid>
				<Grid xs={4} sx={{pl: 1}}>
					<div >
						<h3>Failed / Other</h3>
						{getTopicJobCards(failed)}
						{
							other && other.length > 0 &&
							<>
								<Divider/>
								{getTopicJobCards(other)}
							</>
						}
					</div>
				</Grid>

			</Grid>
		</Grid>
	)
}

export default TopicJobs
